import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Row, Col, Form } from 'react-bootstrap'
import ModalContainer from './ModalContainer'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import QuillToolbar, { modules, formats } from './QuillToolbar'

import { editStudyConsent } from '../actions/systemOwnerStudyActions'
import Loader from './Loader'

export const StudyConsent = () => {
  const studyDetails = useSelector((state) => state.studyDetails)
  const { study, success } = studyDetails

  const studyEditConsent = useSelector((state) => state.studyEditConsent)
  const { loading } = studyEditConsent

  const dispatch = useDispatch()

  // The following is needed because onChange event will convert everything into string
  const transformConsentNeeded = (consentNeeded) => {
    if (consentNeeded) {
      return '1'
    } else {
      return '0'
    }
  }

  const [show, setShow] = useState(false)
  const [html, setHtml] = useState(null)
  const [consentNeeded, setConsentNeeded] = useState(null)

  useEffect(() => {
    if (html === null && success) {
      setHtml(study.stage_list[0].consent_info.chin_content)
    }
    if (consentNeeded === null && success) {
      setConsentNeeded(
        transformConsentNeeded(study.stage_list[0].consent_info.consent_needed)
      )
    }
  }, [study, html, consentNeeded, success])

  const handleOpen = () => {
    setShow(true)
  }

  const handleChange = (html) => {
    setHtml(html)
  }

  const handleSave = (e) => {
    e.preventDefault()
    let outputConsentNeeded
    if (consentNeeded === '1') {
      outputConsentNeeded = true
    } else {
      outputConsentNeeded = false
    }
    dispatch(
      editStudyConsent(study._id, {
        consent: html,
        consentNeeded: outputConsentNeeded,
      })
    )
    setShow(false)
  }

  const handleClose = () => {
    setShow(false)
  }

  const headingStyle = {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
  }

  return (
    <>
      {/*<h1 style={headingStyle}>Consent Form</h1>*/}
      {loading ? (
        <Loader />
      ) : (
        <Button
          variant='primary'
          onClick={handleOpen}
          style={{ float: 'left', marginLeft: '10px', marginTop: '5px' }}
        >
          Click To Edit Consent Form
        </Button>
      )}

      <ModalContainer
        title='Consent Form (Scroll Down To Save)'
        show={show}
        handleSave={handleSave}
        handleClose={handleClose}
        showSave={true}
      >
        <Row>
          <Col>
            <Form.Group controlId={'consent_nedded'}>
              <Form.Label>Consent Needed</Form.Label>
              <Form.Control
                as='select'
                value={consentNeeded}
                onChange={(e) => {
                  setConsentNeeded(e.target.value)
                }}
              >
                <option value='0' key={'consent_nedded_0'}>
                  No
                </option>
                <option value='1' key={'consent_nedded_1'}>
                  Yes
                </option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        {consentNeeded === '1' && (
          <Row>
            <Col>
              <Form.Label>Consent Content</Form.Label>
              <div className={'text-editor'}>
                <QuillToolbar />
                <ReactQuill
                  value={html}
                  onChange={handleChange}
                  modules={modules}
                  formats={formats}
                ></ReactQuill>
              </div>
            </Col>
          </Row>
        )}
      </ModalContainer>
    </>
  )
}

export default StudyConsent
