import {
  EMA_TASK_LIST_FAIL,
  EMA_TASK_LIST_REQUEST,
  EMA_TASK_LIST_RESET,
  EMA_TASK_LIST_SUCCESS,
} from '../constants/emaConstants'

export const emaTaskListReducer = (state = { emaTasks: [] }, action) => {
  switch (action.type) {
    case EMA_TASK_LIST_REQUEST:
      return { loading: true, emaTasks: [] }
    case EMA_TASK_LIST_SUCCESS:
      return {
        loading: false,
        emaTasks: action.payload.emaTasks,
        success: true,
      }
    case EMA_TASK_LIST_FAIL:
      return { loading: false, error: action.payload }
    case EMA_TASK_LIST_RESET:
      return { emaTasks: [] }
    default:
      return state
  }
}
