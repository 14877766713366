import React from "react";
import { Modal, Button } from "react-bootstrap";

// In order for modalRef.current.scrollTop = 0 to work, scrollable has to be true
export const ModalContainer = ({
  title,
  children,
  show,
  saveAvailable,
  handleSave,
  handleClose,
  modalRef,
}) => {
  return (
    <Modal show={show} onHide={handleClose} scrollable={true} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body ref={modalRef}>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {saveAvailable && (
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalContainer;
