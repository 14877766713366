import React, { useState, useEffect } from 'react'
import { Card, Form, Row, Col, Button } from 'react-bootstrap'
import ResponseSettings from '../components/ResponseSettings'

//***********************************
//It is also a working code for directly updating fields of prop(s),
// while not affecting UI performance
//************************************

export const Response = ({
  qx,
  qIndex,
  rIndex,
  response,
  onResponseDelete,
  onTypeChange,
  deleteAvailable,
  mcList,
}) => {
  const [responseType, setResponseType] = useState('')
  const [responseRequired, setResponseRequired] = useState(false)

  useEffect(() => {
    setResponseType(response.type)
    setResponseRequired(response.validation.required)
    //console.log(response);
  }, [response])

  const responseSettingsAvailable = () => {
    if (
      response.type === 'text' ||
      response.type === 'duration' ||
      response.type === 'mc' ||
      response.type === 'time' ||
      response.type === 'media' ||
      response.type === 'slider'
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={6}>
            <Form.Group controlId={'rtype' + response._id}>
              <Form.Label>Instruction/Response Type</Form.Label>
              <Form.Control
                as='select'
                value={responseType}
                onChange={(e) => {
                  onTypeChange(response.type, e.target.value)
                  setResponseType(e.target.value)
                  response.type = e.target.value
                }}
              >
                <option value=''>Select Type...</option>
                <option value='instruction'>
                  Instruction (Presenting text only and no response is required)
                </option>
                <option value='media'>
                  Picture / Audio / Video (Presenting media component only and
                  no response is required)
                </option>
                <option value='mc'>Multiple Choice</option>
                <option value='time'>Time</option>
                <option value='duration'>Duration</option>
                <option value='slider'>Slider</option>
                <option value='text'>
                  Text Box (can limit to numbers only)
                </option>
                <option value='textarea'>Paragraph</option>
                <option value='photoUpload'>Photo Upload</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>{/* Dependencies */}</Col>
        </Row>
        <Row>
          <Col>
            {responseSettingsAvailable() && (
              <ResponseSettings
                qx={qx}
                qIndex={qIndex}
                rIndex={rIndex}
                response={response}
                mcList={mcList}
              ></ResponseSettings>
            )}
          </Col>
        </Row>
        <hr></hr>
        <Row style={{ marginBottom: 0 }}>
          <Col md={6}>
            {responseType !== 'instruction' && responseType !== 'media' && (
              <Form.Group controlId={'rrequire' + response._id}>
                <Form.Check
                  type='checkbox'
                  label='Mandatory when visible'
                  checked={responseRequired}
                  onChange={(e) => {
                    setResponseRequired(e.target.checked)
                    response.validation.required = e.target.checked
                  }}
                />
              </Form.Group>
            )}
          </Col>
          <Col md={6}>
            {deleteAvailable && (
              <Button
                className='mx-1 float-right btn-danger'
                onClick={onResponseDelete}
              >
                Delete Response
              </Button>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default Response
