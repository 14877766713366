import axios from 'axios'
import { USER_LOGOUT } from '../../admin_module/constants/userConstants'

import BACKEND_URL from '../../backendUrl'
import {
  NEXT_TX_STAGE_INFO_CREATE_FAIL,
  NEXT_TX_STAGE_INFO_CREATE_REQUEST,
  NEXT_TX_STAGE_INFO_CREATE_SUCCESS,
  NEXT_TX_STAGE_INFO_DELETE_FAIL,
  NEXT_TX_STAGE_INFO_DELETE_REQUEST,
  NEXT_TX_STAGE_INFO_DELETE_SUCCESS,
  NEXT_TX_STAGE_INFO_EDIT_FAIL,
  NEXT_TX_STAGE_INFO_EDIT_REQUEST,
  NEXT_TX_STAGE_INFO_EDIT_SUCCESS,
  NEXT_TX_STAGE_INFO_LIST_FAIL,
  NEXT_TX_STAGE_INFO_LIST_REQUEST,
  NEXT_TX_STAGE_INFO_LIST_SUCCESS,
} from '../constants/txProgressConstants'

const standardErrorHandling = (dispatch, error, errorConstant) => {
  if (error.response && error.response.status === 401) {
    // This ensure userInfo is clear
    dispatch({
      type: USER_LOGOUT,
    })
    localStorage.removeItem('adminModuleUserInfo')
  } else {
    // In the custom express error handler, there could be custom error message,
    //  which is stored in error.response as { message: ..., stack: ... }
    // If there is no such custom error message, output the generic error message (i.e. error.message)
    dispatch({
      type: errorConstant,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getNextTxStageInfoList =
  (studyId) => async (dispatch, getState) => {
    try {
      dispatch({ type: NEXT_TX_STAGE_INFO_LIST_REQUEST })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        BACKEND_URL + `/api/tx_assignment/${studyId}`,
        config
      )

      dispatch({
        type: NEXT_TX_STAGE_INFO_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      standardErrorHandling(dispatch, error, NEXT_TX_STAGE_INFO_LIST_FAIL)
    }
  }

export const createNextTxStageInfo =
  (studyId, newNextTxStageInfo) => async (dispatch, getState) => {
    try {
      dispatch({
        type: NEXT_TX_STAGE_INFO_CREATE_REQUEST,
      })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        BACKEND_URL + `/api/tx_assignment/${studyId}`,
        { newNextTxStageInfo },
        config
      )

      dispatch({
        type: NEXT_TX_STAGE_INFO_CREATE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      standardErrorHandling(dispatch, error, NEXT_TX_STAGE_INFO_CREATE_FAIL)
    }
  }

export const deleteNextTxStageInfo =
  (studyId, id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: NEXT_TX_STAGE_INFO_DELETE_REQUEST,
      })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      await axios.delete(
        BACKEND_URL + `/api/tx_assignment/${studyId}/${id}`,
        config
      )

      dispatch({
        type: NEXT_TX_STAGE_INFO_DELETE_SUCCESS,
      })
    } catch (error) {
      standardErrorHandling(dispatch, error, NEXT_TX_STAGE_INFO_DELETE_FAIL)
    }
  }

export const editNextTxStageInfo =
  (studyId, id, updatedNextTxStageInfo) => async (dispatch, getState) => {
    try {
      dispatch({
        type: NEXT_TX_STAGE_INFO_EDIT_REQUEST,
      })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      await axios.put(
        BACKEND_URL + `/api/tx_assignment/${studyId}/${id}/edit`,
        { updatedNextTxStageInfo },
        config
      )

      dispatch({
        type: NEXT_TX_STAGE_INFO_EDIT_SUCCESS,
      })
    } catch (error) {
      standardErrorHandling(dispatch, error, NEXT_TX_STAGE_INFO_EDIT_FAIL)
    }
  }
