import EMA_OR_EMI from '../ema_or_emi'
// This needs to be updated in the same file in backend

let Organizations

// --- EMA Sites ---
if (EMA_OR_EMI === 'ema') {
  // Cuhk is for department app
  Organizations = {
    Longitudinax: 1,
    Cuhk: 2,
  }
}

// --- EMI Sites ---
if (EMA_OR_EMI === 'emi') {
  Organizations = {
    Longitudinax: 1,
  }
}

export default Organizations
