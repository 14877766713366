import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import { QX_LIST_RESET } from '../qx_module/constants/qxConstants'
import { logout } from '../admin_module/actions/userActions'

import getSubdomain from '../getSubdomain'
import findSiteInfoBySubdomain from '../findSiteInfoBySubdomain'

const SystemOwnerHeader = () => {
  const dispatch = useDispatch()

  const systemOwnerLogin = useSelector((state) => state.systemOwnerLogin)
  const { systemOwnerInfo, logout } = systemOwnerLogin

  /*const goToQxList = () => {
    dispatch({ type: QX_LIST_RESET })
  }*/

  const logoutHandler = () => {
    dispatch(logout())
  }

  return (
    <header>
      <Navbar
        variant='light'
        expand='lg'
        collapseOnSelect
        style={{
          backgroundColor: findSiteInfoBySubdomain(getSubdomain(window))
            .primaryColor,
        }}
      >
        <Container>
          <Navbar.Brand>
            {findSiteInfoBySubdomain(getSubdomain(window)).siteName}
          </Navbar.Brand>

          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ml-auto'>
              <LinkContainer to='/systemowner/home'>
                <Nav.Link>
                  <span style={{ color: 'black' }}>Labs</span>
                </Nav.Link>
              </LinkContainer>

              <LinkContainer to='/systemowner/studies/1'>
                <Nav.Link>
                  <span style={{ color: 'black' }}>Studies</span>
                </Nav.Link>
              </LinkContainer>

              {/*<LinkContainer to='/qx/qx_list/1' onClick={() => goToQxList()}>
                <Nav.Link>
                  <span style={{ color: 'black' }}>Questionnaires</span>
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to='/media/files'>
                <Nav.Link>
                  <span style={{ color: 'black' }}>Uploaded Files</span>
                </Nav.Link>
               </LinkContainer>*/}
              <NavDropdown title='Account' id='basic-nav-dropdown'>
                {systemOwnerInfo && (
                  <NavDropdown.ItemText>
                    {systemOwnerInfo.email}
                  </NavDropdown.ItemText>
                )}
                <NavDropdown.Divider />
                <LinkContainer to='/login' onClick={() => logoutHandler()}>
                  <NavDropdown.Item>Logout</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default SystemOwnerHeader
