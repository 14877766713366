import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button, Card } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'

import { downloadRespTime } from '../actions/participantActions'
import { DOWNLOAD_RESP_TIME_RESET } from '../constants/participantConstants'

import hasValidLoginCredential from '../../admin_module/utils/hasValidLoginCredential'

import ParticipantFilters from '../components/ParticipantFilters'

const CheckProgressScreen = ({ history, match }) => {
  const studyId = match.params.studyId

  // For participant filters
  const [currentStage, setCurrentStage] = useState('')
  const [currentGroup, setCurrentGroup] = useState('')
  const [studyCode, setStudyCode] = useState('')
  const [studyName, setStudyName] = useState('')

  const dispatch = useDispatch()

  //It is needed to check if the logged in user is an admin
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const donwloadRespTimeState = useSelector((state) => state.downloadRespTime)
  const {
    error: errorDownloadRespTime,
    loading: loadingDownloadRespTime,
    success: successDownloadRespTime,
  } = donwloadRespTimeState

  const headingStyle = {
    fontSize: '2rem',
    fontWeight: 'bold',
  }

  useEffect(() => {
    if (!hasValidLoginCredential(userInfo, logout, window)) {
      history.push('/login')
    } else {
      if (successDownloadRespTime) {
        dispatch({ type: DOWNLOAD_RESP_TIME_RESET })
      }
    }
  }, [dispatch, history, userInfo, logout, successDownloadRespTime])

  const downloadRespTimeData = async () => {
    dispatch(downloadRespTime(studyId, currentStage, currentGroup))
  }

  //selectedGroupId could be equal to 'all', which represents all groups
  const handleFiltersUpdated = (selectedStageId, selectedGroupId) => {
    setCurrentStage(selectedStageId)
    setCurrentGroup(selectedGroupId)
  }

  const onStudyLoaded = (study) => {
    setStudyCode(study.study_code)
    setStudyName(study.chin_name)
  }

  return (
    <>
      {errorDownloadRespTime && (
        <Message variant='danger'>{errorDownloadRespTime}</Message>
      )}

      <Row className='align-items-center'>
        <Col>
          <h1 style={headingStyle}>Study Progress</h1>
        </Col>
        {studyCode.length > 0 && (
          <Col className='text-right'>
            <h5>
              Study: {studyCode} ({studyName})
            </h5>
          </Col>
        )}
      </Row>

      <Row>
        <Col>
          <Card className='p-2 my-2 rounded'>
            <ParticipantFilters
              studyId={studyId}
              onFilterUpdated={handleFiltersUpdated}
              onStudyLoaded={onStudyLoaded}
            />
            {currentStage.length > 0 && currentGroup.length > 0 && (
              <Row>
                <Col>
                  {loadingDownloadRespTime ? (
                    <Loader />
                  ) : (
                    <>
                      <Button
                        variant='primary'
                        className='mt-2 mr-2'
                        onClick={() =>
                          downloadRespTimeData(currentStage, currentGroup)
                        }
                      >
                        Download Questionnaire Response Time (Not Include EMA
                        Response)
                      </Button>
                      <p className='mt-2 mx-1'>
                        Note: EMA response time can be found in the text
                        response downloaded from the 'Particiapnt List' Page
                      </p>
                    </>
                  )}
                </Col>
              </Row>
            )}
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default CheckProgressScreen
