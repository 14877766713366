/* This screen for system owner to list all users in the lab */

import React, { useEffect } from 'react'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { labManagerGetUsers } from '../actions/userActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { LAB_MANAGER_REGISTER_USER_RESET } from '../constants/userConstants'

import hasValidLoginCredential from '../utils/hasValidLoginCredential'

const LabManagerListingUserScreen = ({ match, history }) => {
  const dispatch = useDispatch()

  //It is needed to check if the logged in user is an admin
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const labUserList = useSelector((state) => state.labManagerGetLabUsers)
  const { labUsers, loading, error } = labUserList

  useEffect(() => {
    // if the lab manager have not logged in or the token expires, the redirect to the login page
    if (
      !hasValidLoginCredential(userInfo, logout, window) ||
      !userInfo.isLabManager
    ) {
      history.push('/login')
    } else {
      dispatch(labManagerGetUsers())
    }
  }, [history, dispatch, userInfo, logout])

  const goToRegisterAdminHandler = () => {
    dispatch({ type: LAB_MANAGER_REGISTER_USER_RESET })
    history.push(`/lm/register`)
  }

  const headingStyle = {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginTop: '0.9rem',
  }

  return (
    <>
      <Row>
        <Col>
          <h1 style={headingStyle}>Lab Users</h1>
        </Col>
        <Col className='text-right'>
          <Button className='my-3' onClick={goToRegisterAdminHandler}>
            <i className='fas fa-plus'></i> Register a new user
          </Button>
        </Col>
      </Row>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Table
          striped
          bordered
          responsive
          hover
          className='table-sm text-center'
        >
          <thead>
            <tr>
              <td>ID</td>
              <td>EMAIL</td>
              <td>MANAGER</td>
              <td>CREATED AT</td>
            </tr>
          </thead>
          <tbody>
            {labUsers &&
              labUsers.map((labUser) => (
                <tr key={labUser._id}>
                  <td>{labUser._id}</td>
                  <td>{labUser.email}</td>
                  <td>
                    {' '}
                    {labUser.isLabManager ? (
                      <i
                        className='fas fa-check'
                        style={{ color: 'green' }}
                      ></i>
                    ) : (
                      <i className='fas fa-times' style={{ color: 'red' }}></i>
                    )}
                  </td>
                  <td>{labUser.createdAt.substring(0, 10)}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  )
}

export default LabManagerListingUserScreen
