import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { Link } from 'react-router-dom'
import { systemOwnerRegisterAdmin } from '../actions/userActions'

import Organizations from '../../enums/organizations'
import getSubdomain from '../../getSubdomain'
import findOrganizationBySubdomain from '../../findOrganizationBySubdomain'
import redirectDueToInvalidSubdomain from '../../redirectDueToInvalidSubdomain'

const SystemOwnerRegisterAdminScreen = ({ location, history, match }) => {
  // Make sure system owner is only accessible through longitudinax site
  const subdomain = getSubdomain(window)
  const siteOrganization = findOrganizationBySubdomain(subdomain)
  if (siteOrganization !== Organizations.Longitudinax) {
    // Keep the site subdomain by providing undefined to the second parameter
    redirectDueToInvalidSubdomain(window, undefined)
  }

  const labId = match.params.labId

  const [isLabManager, setIsLabManager] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()

  const systemOwnerLogin = useSelector((state) => state.systemOwnerLogin)
  const { systemOwnerInfo, logout } = systemOwnerLogin
  const systemOwnerRegisterAdminReducer = useSelector(
    (state) => state.systemOwnerRegisterAdmin
  )
  const { loading, registerResp, error } = systemOwnerRegisterAdminReducer

  useEffect(() => {
    // if system own have not logged in or the token expires, the redirect to the login page
    let now = new Date()
    if (!systemOwnerInfo || systemOwnerInfo.expires < now || logout) {
      history.push('/systemowner/login')
    } else {
      if (registerResp) {
        if (registerResp.msg === 'Registration Success!') {
          history.push(`/systemowner/lab/${labId}`)
        }
      }
    }
  }, [registerResp, history, labId])

  const submitHandler = (e) => {
    e.preventDefault()
    // dispatch register
    if (password !== confirmPassword) {
      setMessage('Passwords do not match')
    } else {
      dispatch(systemOwnerRegisterAdmin(email, password, isLabManager, labId))
    }
  }

  return (
    <FormContainer>
      <Link to={`/systemowner/lab/${labId}`} className='btn btn-light my-3'>
        Go Back
      </Link>
      <h1 className='py-3'>Register New Admin User</h1>
      {error && <Message variant='danger'>{error}</Message>}
      {message && <Message variant='danger'>{message}</Message>}
      {loading && <Loader />}

      <Form onSubmit={submitHandler}>
        <Form.Group controlId='email'>
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type='email'
            placeholder='Enter email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='isLabManager'>
          <Form.Check
            type='checkbox'
            label='Is Lab Manager'
            checked={isLabManager}
            // for checkbox it is e.target.checked
            onChange={(e) => setIsLabManager(e.target.checked)}
          ></Form.Check>
        </Form.Group>

        <Form.Group controlId='password'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Enter password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='confirmPassword'>
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Confirm Password '
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          ></Form.Control>
        </Form.Group>

        <Button type='submit' variant='primary'>
          Register
        </Button>
      </Form>
    </FormContainer>
  )
}

export default SystemOwnerRegisterAdminScreen
