import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Form } from 'react-bootstrap'

import { editStudyBasic } from '../actions/studyActions'

export const SystemOwnerStudyBasic = ({ study }) => {
  const dispatch = useDispatch()

  const [code, setCode] = useState('')
  const [name, setName] = useState('')
  const [status, setStatus] = useState('')
  const [contact, setContact] = useState('')
  const [email, setEmail] = useState('')

  useEffect(() => {
    setName(study.chin_name)
    setCode(study.study_code)
    setStatus(study.active_status)
    setContact(study.contact_person_name)
    setEmail(study.contact_email)
  }, [study])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      editStudyBasic(study._id, {
        chin_name: name,
        contact_person_name: contact,
        contact_email: email,
      })
    )
  }

  const headingStyle = {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
  }

  return (
    <>
      <h1 style={headingStyle}>Basic Information</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='code'>
          <Form.Label style={{ whiteSpace: 'normal' }}>Study Code</Form.Label>
          <Form.Control type='text' value={code} readOnly></Form.Control>
        </Form.Group>

        <Form.Group controlId='status'>
          <Form.Label style={{ whiteSpace: 'normal' }}>
            Active Status
          </Form.Label>
          <Form.Control type='text' value={status} readOnly></Form.Control>
        </Form.Group>

        <Form.Group controlId='name'>
          <Form.Label style={{ whiteSpace: 'normal' }}>Study Name</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter name'
            value={name}
            onChange={(e) => {
              study.chin_name = e.target.value
              setName(e.target.value)
            }}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='contact'>
          <Form.Label style={{ whiteSpace: 'normal' }}>
            Contact Person
          </Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter contact person'
            value={contact}
            onChange={(e) => {
              study.contact_person_name = e.target.value
              setContact(e.target.value)
            }}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='email'>
          <Form.Label style={{ whiteSpace: 'normal' }}>
            Contact Email
          </Form.Label>
          <Form.Control
            type='email'
            placeholder='Enter contact email'
            value={email}
            onChange={(e) => {
              study.contact_email = e.target.value
              setEmail(e.target.value)
            }}
          ></Form.Control>
        </Form.Group>
      </Form>
    </>
  )
}

export default SystemOwnerStudyBasic
