import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../actions/userActions'
import Message from '../components/Message'
import Loader from '../components/Loader'
import getSubdomain from '../../getSubdomain'
import findOrganizationBySubdomain from '../../findOrganizationBySubdomain'
import findSiteInfoBySubdomain from '../../findSiteInfoBySubdomain'

const AdminLoginScreen = ({ location, history }) => {
  const orgnanization = findOrganizationBySubdomain(getSubdomain(window))

  // There could be multiple states generated by 'useState'
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, attemptLogin, userInfo } = userLogin

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    // Remove auto login feature on login page to allow login with another user
    let today = new Date()
    if (attemptLogin && userInfo && userInfo.expires > today) {
      history.push(redirect)
    }
  }, [history, attemptLogin, userInfo, redirect])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password, orgnanization))
  }

  return (
    <Row className='justify-content-center'>
      <Col xs={10} sm={10} md={7} lg={5}>
        <Card
          className='mt-5 px-4 pt-4 pb-3'
          style={{ backgroundColor: 'white' }}
        >
          <Row className='mb-3 justify-content-center'>
            <h4 style={{ fontSize: '18px' }} className=''>
              Sign in to{' '}
              {findSiteInfoBySubdomain(getSubdomain(window)).siteName} Admin
              Portal
            </h4>
          </Row>
          {error && <Message variant='danger'>{error}</Message>}
          {loading && <Loader />}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='email'>
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter email'
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='password'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter password'
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
              ></Form.Control>
            </Form.Group>

            <Row>
              <Col>
                <Button
                  type='submit'
                  variant='primary'
                  className='btn'
                  style={{ width: '100%' }}
                >
                  Sign In
                </Button>
              </Col>
            </Row>
          </Form>

          <Row className='mt-2'>
            <Col className='text-right'>
              <a
                href={
                  findSiteInfoBySubdomain(getSubdomain(window)).termsOfUseUrl
                }
                target='_blank'
                rel='noreferrer'
                style={{ fontSize: '13px', color: 'black' }}
                className='pr-3'
              >
                Terms of Use
              </a>
              <a
                href={findSiteInfoBySubdomain(getSubdomain(window)).privacyUrl}
                target='_blank'
                rel='noreferrer'
                style={{ fontSize: '13px', color: 'black' }}
              >
                Privacy Policy
              </a>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  )
}

export default AdminLoginScreen
