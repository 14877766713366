import React, { useState, useEffect } from 'react'
import { Form, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getMediaFileByKeyword } from '../actions/mediaActions'

const SearchBox = ({ fileType, filePath, onSelectFile }) => {
  const [keyword, setKeyword] = useState('')
  const [selectedFilePath, setSelectedFilePath] = useState('')
  const [showList, setShowList] = useState(false)
  //   const [refreshSignal, setRefreshSignal] = useState(false);

  const dispatch = useDispatch()

  //It is needed to check if the logged in user is an admin
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const theTargetFiles = useSelector((state) => state.targetMediaFiles)

  const { targetFiles } = theTargetFiles

  useEffect(() => {
    setSelectedFilePath(filePath)
    if (userInfo && keyword.length >= 0) {
      dispatch(getMediaFileByKeyword(keyword, fileType))
    }
  }, [keyword, fileType, userInfo, filePath, dispatch])

  return (
    <>
      <Form className='mb-1' autoComplete='off'>
        {targetFiles && targetFiles.length > 0 && (
          <>
            <Form.Label>File</Form.Label>
            <Form.Control
              readOnly
              type='text'
              value={selectedFilePath}
              onFocus={(e) => setShowList(true)}
              onChange={(e) => {
                setKeyword(e.target.value)
              }}
              placeholder='Please click here and choose'
              className='searchForm'
              onBlur={(e) => {
                setTimeout(() => {
                  setKeyword('')
                  setShowList(false)
                }, 300)
              }}
            ></Form.Control>
          </>
        )}
        {targetFiles && targetFiles.length === 0 && (
          <Form.Label>Please upload files first</Form.Label>
        )}
      </Form>

      {showList && (
        <div id='match-list' style={matchListStyle()}>
          {targetFiles &&
            targetFiles.length > 0 &&
            targetFiles.map((file) => {
              return (
                <Card
                  key={file._id}
                  onClick={() => {
                    setSelectedFilePath(file.file)
                    onSelectFile(file)
                    setKeyword('')
                    setShowList(false)
                  }}
                  style={getCardStyle()}
                >
                  <Card.Body className='mb-1'>
                    {/*file.type === 'video' ? (
                      <video style={getThumbnailStyle()} controls autoPlay>
                        <source src={file.thumbnail} type='video/mp4' />
                      </video>
                    ) : (
                      <img
                        src={file.thumbnail}
                        alt={file.name}
                        style={getThumbnailStyle()}
                      ></img>
                    )*/}
                    <span style={getTargetNameStyle()}>
                      {file.name} (Created at: {file.createdAt.substring(0, 10)}
                      )
                    </span>
                  </Card.Body>
                </Card>
              )
            })}
        </div>
      )}
    </>
  )
}

const matchListStyle = () => {
  return {
    width: '96%',
    position: 'absolute',
    marginLeft: '0',
    zIndex: 99,
  }
}

const getThumbnailStyle = () => {
  return {
    width: '8rem',
    float: 'left',
    marginRight: '2rem',
  }
}

const getTargetNameStyle = () => {
  return {
    float: 'left',
    //paddingTop: '1.5rem',
    paddingTop: '1rem',
  }
}

const getCardStyle = () => {
  return {
    cursor: 'pointer',
  }
}

export default SearchBox
