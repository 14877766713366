import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Row, Col, Form } from 'react-bootstrap'
import Loader from './Loader'
import Message from './Message'
import { listStudyDetails } from '../../study_module/actions/studyActions'
import { STUDY_DETAILS_RESET } from '../../study_module/constants/studyConstants'

export const ParticipantFilters = ({
  studyId,
  onFilterUpdated,
  onStudyLoaded,
}) => {
  const studyDetails = useSelector((state) => state.studyDetails)
  const {
    loading: loadingInitiateStudy,
    error: errorInitiateStudy,
    study: loadedStudy,
    success: successInitiateStudy,
  } = studyDetails

  const dispatch = useDispatch()

  const [selectedStageId, setSelectedStageId] = useState('')
  const [selectedGroupId, setSelectedGroupId] = useState('all') //all stands for selecting all participant groups

  useEffect(() => {
    if (!loadingInitiateStudy && !errorInitiateStudy) {
      if (!successInitiateStudy) {
        dispatch({ type: STUDY_DETAILS_RESET })
        dispatch(listStudyDetails(studyId))
      } else {
        if (selectedStageId.length === 0) {
          // Initial setup of selected stage Id
          let initialStageId = loadedStudy.stage_list.find(
            (stage) => stage.stage_num === 1
          )._id
          onFilterUpdated(initialStageId, 'all')
          onStudyLoaded(loadedStudy)
          setSelectedStageId(initialStageId)
        }
      }
    }
  }, [
    dispatch,
    successInitiateStudy,
    loadingInitiateStudy,
    errorInitiateStudy,
    studyId,
    loadedStudy,
    selectedStageId,
    onFilterUpdated,
    onStudyLoaded,
  ])

  const getStage = (study, stageId) => {
    return study.stage_list.find((stage) => stage._id === String(stageId))
  }

  return (
    <>
      {errorInitiateStudy && (
        <Message variant='danger'>{errorInitiateStudy}</Message>
      )}
      {loadingInitiateStudy ? (
        <Loader />
      ) : (
        <Row>
          <Col md={6}>
            <Form.Group controlId={'participant_filters_stage'}>
              <Form.Label>Study Stage</Form.Label>
              <Form.Control
                as='select'
                value={selectedStageId}
                onChange={(e) => {
                  // The condition check is essential. Otherwise, it will end up an endless loop.
                  if (e.target.value !== selectedStageId) {
                    onFilterUpdated(e.target.value, 'all')
                    setSelectedStageId(e.target.value)
                    setSelectedGroupId('all')
                  }
                }}
              >
                {loadedStudy &&
                  loadedStudy.stage_list &&
                  loadedStudy.stage_list.map((stage) => (
                    <option
                      key={'participant_filters_stage_' + stage._id}
                      value={stage._id}
                    >
                      {stage.chin_name} ({stage.stage_num})
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId={'participant_filters_stage'}>
              <Form.Label>Participant Group</Form.Label>
              <Form.Control
                as='select'
                value={selectedGroupId}
                onChange={(e) => {
                  // The condition check is essential. Otherwise, it will end up an endless loop.
                  if (e.target.value !== selectedGroupId) {
                    onFilterUpdated(selectedStageId, e.target.value)
                    setSelectedGroupId(e.target.value)
                  }
                }}
              >
                <option key='participant_filters_group_all' value='all'>
                  All Groups
                </option>
                {loadedStudy &&
                  getStage(loadedStudy, selectedStageId) &&
                  getStage(
                    loadedStudy,
                    selectedStageId
                  ).participant_group_list.map((group) => (
                    <option
                      key={'participant_filters_group_' + group._id}
                      value={group._id}
                    >
                      {group.name} ({group.group_num})
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      )}
    </>
  )
}

export default ParticipantFilters
