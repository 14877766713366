import axios from 'axios'
import { USER_LOGOUT } from '../../admin_module/constants/userConstants'
import {
  CUSTOM_PAGE_COMPONENT_CREATE_FAIL,
  CUSTOM_PAGE_COMPONENT_CREATE_REQUEST,
  CUSTOM_PAGE_COMPONENT_CREATE_SUCCESS,
  CUSTOM_PAGE_COMPONENT_DELETE_FAIL,
  CUSTOM_PAGE_COMPONENT_DELETE_REQUEST,
  CUSTOM_PAGE_COMPONENT_DELETE_SUCCESS,
  CUSTOM_PAGE_COMPONENT_DETAILS_FAIL,
  CUSTOM_PAGE_COMPONENT_DETAILS_REQUEST,
  CUSTOM_PAGE_COMPONENT_DETAILS_SUCCESS,
  CUSTOM_PAGE_COMPONENT_EDIT_FAIL,
  CUSTOM_PAGE_COMPONENT_EDIT_REQUEST,
  CUSTOM_PAGE_COMPONENT_EDIT_SUCCESS,
  CUSTOM_PAGE_COMPONENT_LIST_FAIL,
  CUSTOM_PAGE_COMPONENT_LIST_REQUEST,
  CUSTOM_PAGE_COMPONENT_LIST_SUCCESS,
  TX_PAGE_CREATE_FAIL,
  TX_PAGE_CREATE_REQUEST,
  TX_PAGE_CREATE_SUCCESS,
  TX_PAGE_DELETE_FAIL,
  TX_PAGE_DELETE_REQUEST,
  TX_PAGE_DELETE_SUCCESS,
  TX_PAGE_DETAILS_FAIL,
  TX_PAGE_DETAILS_REQUEST,
  TX_PAGE_DETAILS_SUCCESS,
  TX_PAGE_EDIT_FAIL,
  TX_PAGE_EDIT_REQUEST,
  TX_PAGE_EDIT_SUCCESS,
  TX_PAGE_LIST_FAIL,
  TX_PAGE_LIST_REQUEST,
  TX_PAGE_LIST_SUCCESS,
} from '../constants/txMaterialsConstants'

import BACKEND_URL from '../../backendUrl'

const standardErrorHandling = (dispatch, error, errorConstant) => {
  if (error.response && error.response.status === 401) {
    // This ensure userInfo is clear
    dispatch({
      type: USER_LOGOUT,
    })
    localStorage.removeItem('adminModuleUserInfo')
  } else {
    // In the custom express error handler, there could be custom error message,
    //  which is stored in error.response as { message: ..., stack: ... }
    // If there is no such custom error message, output the generic error message (i.e. error.message)
    dispatch({
      type: errorConstant,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getTxPageList = (studyId) => async (dispatch, getState) => {
  try {
    dispatch({ type: TX_PAGE_LIST_REQUEST })

    // getState() will return the whole redux state.
    // Check store.js combineReducer to see the key of the target state.
    const {
      userLogin: { userInfo },
    } = getState()

    //Following demostrate how to create data with axios in front-end
    //Authorization does not need to be quoted
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      BACKEND_URL + `/api/tx_materials/${studyId}`,
      config
    )

    dispatch({
      type: TX_PAGE_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    standardErrorHandling(dispatch, error, TX_PAGE_LIST_FAIL)
  }
}

export const createTxPage =
  (studyId, identifier) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TX_PAGE_CREATE_REQUEST,
      })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        BACKEND_URL + `/api/tx_materials/${studyId}`,
        { identifier },
        config
      )

      dispatch({
        type: TX_PAGE_CREATE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      standardErrorHandling(dispatch, error, TX_PAGE_CREATE_FAIL)
    }
  }

export const deleteTxPage = (studyId, id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TX_PAGE_DELETE_REQUEST,
    })

    // getState() will return the whole redux state.
    // Check store.js combineReducer to see the key of the target state.
    const {
      userLogin: { userInfo },
    } = getState()

    //Following demostrate how to create data with axios in front-end
    //Authorization does not need to be quoted
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(
      BACKEND_URL + `/api/tx_materials/${studyId}/${id}`,
      config
    )

    dispatch({
      type: TX_PAGE_DELETE_SUCCESS,
    })
  } catch (error) {
    standardErrorHandling(dispatch, error, TX_PAGE_DELETE_FAIL)
  }
}

export const editTxPage =
  (studyId, id, updatedTxPage) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TX_PAGE_EDIT_REQUEST,
      })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        BACKEND_URL + `/api/tx_materials/${studyId}/${id}/edit`,
        updatedTxPage,
        config
      )

      dispatch({
        type: TX_PAGE_EDIT_SUCCESS,
      })

      //Trigger relaod of treatment page details
      dispatch({
        type: TX_PAGE_DETAILS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      standardErrorHandling(dispatch, error, TX_PAGE_EDIT_FAIL)
    }
  }

// If something is needed to pass into an action (e.g. id), specifies it as function argument
export const listTxPageComponents =
  (studyId, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: TX_PAGE_DETAILS_REQUEST })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        BACKEND_URL + `/api/tx_materials/${studyId}/${id}`,
        config
      )

      dispatch({
        type: TX_PAGE_DETAILS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      standardErrorHandling(dispatch, error, TX_PAGE_DETAILS_FAIL)
    }
  }

export const getCustomPageComponentList =
  (studyId) => async (dispatch, getState) => {
    try {
      dispatch({ type: CUSTOM_PAGE_COMPONENT_LIST_REQUEST })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        BACKEND_URL + `/api/tx_materials/${studyId}/custom_page_component`,
        config
      )

      dispatch({
        type: CUSTOM_PAGE_COMPONENT_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      standardErrorHandling(dispatch, error, CUSTOM_PAGE_COMPONENT_LIST_FAIL)
    }
  }

export const createCustomPageComponent =
  (studyId, newCustomPageComponent) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CUSTOM_PAGE_COMPONENT_CREATE_REQUEST,
      })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        BACKEND_URL + `/api/tx_materials/${studyId}/custom_page_component`,
        { ...newCustomPageComponent },
        config
      )

      dispatch({
        type: CUSTOM_PAGE_COMPONENT_CREATE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      standardErrorHandling(dispatch, error, CUSTOM_PAGE_COMPONENT_CREATE_FAIL)
    }
  }

export const deleteCustomPageComponent =
  (studyId, id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CUSTOM_PAGE_COMPONENT_DELETE_REQUEST,
      })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      await axios.delete(
        BACKEND_URL +
          `/api/tx_materials/${studyId}/custom_page_component/${id}`,
        config
      )

      dispatch({
        type: CUSTOM_PAGE_COMPONENT_DELETE_SUCCESS,
      })
    } catch (error) {
      standardErrorHandling(dispatch, error, CUSTOM_PAGE_COMPONENT_DELETE_FAIL)
    }
  }

export const editCustomPageComponent =
  (studyId, updatedTxPage) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CUSTOM_PAGE_COMPONENT_EDIT_REQUEST,
      })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        BACKEND_URL +
          `/api/tx_materials/${studyId}/custom_page_component/${updatedTxPage._id}/edit`,
        updatedTxPage,
        config
      )

      dispatch({
        type: CUSTOM_PAGE_COMPONENT_EDIT_SUCCESS,
      })

      //Trigger relaod of treatment page details
      dispatch({
        type: CUSTOM_PAGE_COMPONENT_DETAILS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      standardErrorHandling(dispatch, error, CUSTOM_PAGE_COMPONENT_EDIT_FAIL)
    }
  }

// If something is needed to pass into an action (e.g. id), specifies it as function argument
export const listCustomPageComponent =
  (studyId, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: CUSTOM_PAGE_COMPONENT_DETAILS_REQUEST })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        BACKEND_URL +
          `/api/tx_materials/${studyId}/custom_page_component/${id}`,
        config
      )

      dispatch({
        type: CUSTOM_PAGE_COMPONENT_DETAILS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      standardErrorHandling(dispatch, error, CUSTOM_PAGE_COMPONENT_DETAILS_FAIL)
    }
  }
