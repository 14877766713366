/**************** constants for users *****************/

// Get lab names for the register page
export const GET_LAB_NAMES_REQUEST = 'GET_LAB_NAMES_REQUEST'
export const GET_LAB_NAMES_SUCCESS = 'GET_LAB_NAMES_SUCCESS'
export const GET_LAB_NAMES_FAIL = 'GET_LAB_NAMES_FAIL'

// get lab info for display storage (admin)
export const GET_LAB_INFO_REQUEST = 'GET_LAB_INFO_REQUEST'
export const GET_LAB_INFO_SUCCESS = 'GET_LAB_INFO_SUCCESS'
export const GET_LAB_INFO_FAIL = 'GET_LAB_INFO_FAIL'
export const GET_LAB_INFO_RESET = 'GET_LAB_INFO_RESET'

/************ constants for system owner ***************/

// get labs (system owner)
export const SYSTEM_OWNER_GET_LABS_REQUEST = 'SYSTEM_OWNER_GET_LABS_REQUEST'
export const SYSTEM_OWNER_GET_LABS_SUCCESS = 'SYSTEM_OWNER_GET_LABS_SUCCESS'
export const SYSTEM_OWNER_GET_LABS_FAIL = 'SYSTEM_OWNER_GET_LABS_FAIL'
export const SYSTEM_OWNER_GET_LABS_RESET = 'SYSTEM_OWNER_GET_LABS_RESET'

// get users according to lab id ((system owner)
export const SYSTEM_OWNER_GET_LABS_USERS_REQUEST =
  'SYSTEM_OWNER_GET_LABS_USERS_REQUEST'
export const SYSTEM_OWNER_GET_LABS_USERS_SUCCESS =
  'SYSTEM_OWNER_GET_LABS_USERS_SUCCESS'
export const SYSTEM_OWNER_GET_LABS_USERS_FAIL =
  'SYSTEM_OWNER_GET_LABS_USERS_FAIL'
export const SYSTEM_OWNER_GET_LABS_USERS_RESET =
  'SYSTEM_OWNER_GET_LABS_USERS_RESET'

//  create new labs (system owner)
export const SYSTEM_OWNER_CREATE_LABS_REQUEST =
  'SYSTEM_OWNER_CREATE_LABS_REQUEST'
export const SYSTEM_OWNER_CREATE_LABS_SUCCESS =
  'SYSTEM_OWNER_CREATE_LABS_SUCCESS'
export const SYSTEM_OWNER_CREATE_LABS_FAIL = 'SYSTEM_OWNER_CREATE_LABS_FAIL'
export const SYSTEM_OWNER_CREATE_LABS_RESET = 'SYSTEM_OWNER_CREATE_LABS_RESET'
