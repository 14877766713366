////////////////////////////////////////////////////////
//The modalRef on this page demostrates how useRef
//   can be hooked to a child component
////////////////////////////////////////////////////////

import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Row, Col, Breadcrumb, Form, Card } from 'react-bootstrap'
import ModalContainer from './ModalContainer'
import SelectQuestionResponseAsVariableValue from './SelectQuestionResponseAsVariableValue'

import {
  createQxVariable,
  editQxVariable,
  listQxVariableDetails,
  getQxVariableList,
  deleteQxVariable,
} from '../actions/qxActions'
import Loader from './Loader'
import Message from './Message'
import {
  QX_VARIABLE_CREATE_RESET,
  QX_VARIABLE_DELETE_RESET,
  QX_VARIABLE_DETAILS_RESET,
  QX_VARIABLE_EDIT_RESET,
  QX_VARIABLE_LIST_RESET,
} from '../constants/qxConstants'

import hasValidLoginCredential from '../../admin_module/utils/hasValidLoginCredential'

export const ModifyQxVariables = ({ qx, goToLogin, onUpdate }) => {
  const modalRef = useRef()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const qxVariableList = useSelector((state) => state.qxVariableList)
  const {
    qx_variables: variableList,
    error: errorGetList,
    loading: loadingGetList,
    success: successGetList,
  } = qxVariableList

  const qxVariableCreate = useSelector((state) => state.qxVariableCreate)
  const {
    loading: loadingCreate,
    success: successCreate,
    error: errorCreate,
    qx_variable: createdVariable,
  } = qxVariableCreate

  const qxVariableEdit = useSelector((state) => state.qxVariableEdit)
  const {
    loading: loadingEdit,
    success: successEdit,
    error: errorEdit,
  } = qxVariableEdit

  const qxVariableDelete = useSelector((state) => state.qxVariableDelete)
  const {
    loading: loadingDelete,
    success: successDelete,
    error: errorDelete,
  } = qxVariableDelete

  const qxVariableDetails = useSelector((state) => state.qxVariableDetails)
  const {
    loading: loadingDetails,
    success: successDetails,
    qx_variable: initialVariable,
    error: errorDetails,
  } = qxVariableDetails

  const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [selectedVariable, setSelectedVariable] = useState(undefined)
  const [saveError, setSaveError] = useState('')

  useEffect(() => {
    if (!hasValidLoginCredential(userInfo, logout, window)) {
      goToLogin()
    } else {
      if (show) {
        if (!loadingGetList && !errorGetList) {
          if (!successGetList) {
            dispatch(getQxVariableList(qx._id))
          }
        }
        if (successDetails) {
          setSelectedVariable(initialVariable)
          dispatch({ type: QX_VARIABLE_DETAILS_RESET })
        }
        if (successEdit) {
          onUpdate()
          dispatch({ type: QX_VARIABLE_EDIT_RESET })
          dispatch({ type: QX_VARIABLE_LIST_RESET })
          setShow(false)
        }
        if (successCreate) {
          setSelectedVariable(createdVariable)
          dispatch({ type: QX_VARIABLE_CREATE_RESET })
          dispatch({ type: QX_VARIABLE_LIST_RESET })
        }
        if (successDelete) {
          setSelectedVariable(undefined)
          dispatch({ type: QX_VARIABLE_DELETE_RESET })
          dispatch({ type: QX_VARIABLE_LIST_RESET })
        }
      }
    }
  }, [
    userInfo,
    logout,
    loadingGetList,
    errorGetList,
    successGetList,
    successEdit,
    successCreate,
    successDelete,
    successDetails,
    initialVariable,
    createdVariable,
    qx,
    goToLogin,
    onUpdate,
    dispatch,
    show,
  ])

  const handleOpen = () => {
    dispatch({ type: QX_VARIABLE_LIST_RESET })
    dispatch({ type: QX_VARIABLE_DETAILS_RESET })
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  //////// Validators //////////

  const checkNameNonEmpty = () => {
    let error_msg = ''
    if (!selectedVariable.name?.trim()) {
      error_msg = 'Variable name cannot be empty'
      return error_msg
    }
    return error_msg
  }

  const checkCalculateMethodNonEmpty = () => {
    let error_msg = ''
    if (!selectedVariable.calculate_method?.trim()) {
      error_msg = 'Please select calculation method'
      return error_msg
    }
    return error_msg
  }

  const checkValueTypeNonEmpty = () => {
    let error_msg = ''
    for (let i = 0; i < selectedVariable.values.length; ++i) {
      if (!selectedVariable.values[i].type?.trim()) {
        error_msg = 'Please select value type for all the values'
        return error_msg
      }
    }
    return error_msg
  }

  // sum: sum of all the values. Does not allow value of time_hhmmA type.
  // average: sum/count(values). Does not allow value of time_hhmmA type.
  // subtract: value[0] - value[1]. Does not allow value of time_hhmmA type.
  // time_diff: value[0] - value[1], where value[0] is later time and value[1] is earlier time. Only allow value of time_hhmmA type.
  // percentage: (value[0]/value[1])*100, where value[0] is numerator and value[1] is denominator. Does not allow value of time_hhmmA type.
  const checkValuesType = () => {
    let error_msg = ''
    if (selectedVariable.calculate_method === 'sum') {
      if (selectedVariable.values.length === 0) {
        error_msg = 'Please provide at least one value'
        return error_msg
      }
      for (let i = 0; i < selectedVariable.values.length; ++i) {
        if (selectedVariable.values[i].type === 'time_hhmmA') {
          error_msg =
            'It is not allowed to include time value for this calculation method'
          return error_msg
        }
      }
    } else if (selectedVariable.calculate_method === 'average') {
      if (selectedVariable.values.length === 0) {
        error_msg = 'Please provide at least one value'
        return error_msg
      }
      for (let i = 0; i < selectedVariable.values.length; ++i) {
        if (selectedVariable.values[i].type === 'time_hhmmA') {
          error_msg =
            'It is not allowed to include time value for this calculation method'
          return error_msg
        }
      }
    } else if (selectedVariable.calculate_method === 'subtract') {
      if (selectedVariable.values.length !== 2) {
        error_msg =
          'You should provide only two values for this calculation method'
        return error_msg
      }
      for (let i = 0; i < selectedVariable.values.length; ++i) {
        if (selectedVariable.values[i].type === 'time_hhmmA') {
          error_msg =
            'It is not allowed to include time value for this calculation method'
          return error_msg
        }
      }
    } else if (selectedVariable.calculate_method === 'time_diff') {
      if (selectedVariable.values.length !== 2) {
        error_msg =
          'You should provide only two values for this calculation method'
        return error_msg
      }
      for (let i = 0; i < selectedVariable.values.length; ++i) {
        if (selectedVariable.values[i].type !== 'time_hhmmA') {
          error_msg =
            'It is not allowed to include numerical value for this calculation method'
          return error_msg
        }
      }
    } else if (selectedVariable.calculate_method === 'percentage') {
      if (selectedVariable.values.length !== 2) {
        error_msg =
          'You should provide only two values for this calculation method'
        return error_msg
      }
      for (let i = 0; i < selectedVariable.values.length; ++i) {
        if (selectedVariable.values[i].type === 'time_hhmmA') {
          error_msg =
            'It is not allowed to include time value for this calculation method'
          return error_msg
        }
      }
    } else {
      error_msg = 'Invalid calculation method'
      return error_msg
    }
    return error_msg
  }

  const checkValuesSelection = () => {
    let error_msg = ''
    for (let i = 0; i < selectedVariable.values.length; ++i) {
      if (selectedVariable.values[i].type === 'variable') {
        if (selectedVariable.variable) {
          error_msg = 'Please select variable for each variable type value'
          return error_msg
        }
      } else {
        //console.log(selectedVariable)
        if (
          !selectedVariable.values[i].qpos ||
          !selectedVariable.values[i].rpos
        ) {
          error_msg =
            'Please select question and response for each non-variable type value'
          return error_msg
        }
      }
    }
    return error_msg
  }

  //////////////////////////////

  const handleSelectVariable = (e, id) => {
    e.preventDefault()
    dispatch({ type: QX_VARIABLE_DETAILS_RESET })
    dispatch(listQxVariableDetails(qx._id, id))
  }

  const handleDeleteVariable = (e, id) => {
    e.preventDefault()
    if (window.confirm('Are you sure?')) {
      dispatch(deleteQxVariable(qx._id, id))
    }
  }

  // When clicking 'Create New'
  const handleAddNewVariable = () => {
    const newVariable = {
      _id: '',
      name: '',
      calculate_method: '',
      values: [],
    }
    setSelectedVariable(newVariable)
  }

  // When clicking add new value for sum or average calculate method
  const handleAddValue = () => {
    let tempSelectedVariable = { ...selectedVariable }
    const newValue = {
      type: '',
    }
    tempSelectedVariable.values.push(newValue)
    setSelectedVariable(tempSelectedVariable)
  }

  // When clicking delete value for sum or average calculate method
  const handleRemoveValue = (e, index) => {
    e.preventDefault()
    let tempSelectedVariable = { ...selectedVariable }
    tempSelectedVariable.values.splice(index, 1)
    setSelectedVariable(tempSelectedVariable)
  }

  const handleSave = () => {
    setSaveError('')
    let error = ''

    if (error.length === 0) error = checkNameNonEmpty()
    if (error.length === 0) error = checkCalculateMethodNonEmpty()
    if (error.length === 0) error = checkValueTypeNonEmpty()
    if (error.length === 0) error = checkValuesType()
    if (error.length === 0) error = checkValuesSelection()

    if (error.length === 0) {
      if (selectedVariable._id) {
        //Edit
        dispatch(editQxVariable(qx._id, selectedVariable))
      } else {
        //Create
        dispatch(createQxVariable(qx._id, selectedVariable))
      }
    } else {
      setSaveError(error)
      modalRef.current.scrollTop = 0
    }
  }

  const getValueLabel = (index) => {
    if (selectedVariable.calculate_method === 'percentage') {
      return index === 0 ? 'Numerator Value' : 'Denominator Value'
    }
    if (selectedVariable.calculate_method === 'time_diff') {
      return index === 0 ? 'Later Time' : 'Earlier Time'
    }
    if (selectedVariable.calculate_method === 'subtract') {
      return index === 0 ? 'Value 1' : 'Value 2'
    }
    return 'Value ' + (index + 1)
  }

  const getInitialValuesForCalculateMethod = (calculateType) => {
    if (calculateType === 'percentage' || calculateType === 'subtract') {
      return [{ type: '' }, { type: '' }]
    } else if (calculateType === 'time_diff') {
      return [{ type: 'time_hhmmA' }, { type: 'time_hhmmA' }]
    } else {
      return [{ type: '' }]
    }
  }

  return (
    <>
      {loadingGetList ||
      loadingCreate ||
      loadingEdit ||
      loadingDetails ||
      loadingDelete ? (
        <Loader />
      ) : (
        <Button variant='primary' className='btn-sm mx-1' onClick={handleOpen}>
          Compute Variables
        </Button>
      )}

      <ModalContainer
        title={`Compute Variables for ${qx.abbrev}`}
        show={show}
        saveAvailable={selectedVariable}
        handleSave={handleSave}
        handleClose={handleClose}
        modalRef={modalRef}
      >
        {saveError && <Message variant='danger'>{saveError}</Message>}
        {errorGetList && <Message variant='danger'>{errorGetList}</Message>}
        {errorEdit && <Message variant='danger'>{errorEdit}</Message>}
        {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
        {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
        {errorDetails && <Message variant='danger'>{errorDetails}</Message>}

        <Row>
          <Col md={12}>
            <Breadcrumb>
              {variableList &&
                variableList.map((variable) => (
                  <Breadcrumb.Item
                    key={variable._id}
                    onClick={(e) => {
                      handleSelectVariable(e, variable._id)
                    }}
                    active={
                      selectedVariable &&
                      selectedVariable._id &&
                      selectedVariable._id === variable._id
                    }
                  >
                    {variable.name}
                  </Breadcrumb.Item>
                ))}
              <Breadcrumb.Item
                onClick={handleAddNewVariable}
                active={selectedVariable && selectedVariable._id.length === 0}
              >
                Create New
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        {!selectedVariable ? (
          <Row>
            <Col>
              <p>Please select or create a new variable above</p>
            </Col>
          </Row>
        ) : (
          <>
            <Card className='p-3 rounded'>
              <Row>
                <Col>
                  <Form.Group
                    controlId={'qx_variable_name' + selectedVariable._id}
                  >
                    <Form.Label>Variable Name</Form.Label>
                    <Form.Control
                      type='text'
                      value={selectedVariable.name}
                      onChange={(e) => {
                        let tempSelectedVariable = { ...selectedVariable }
                        tempSelectedVariable.name = e.target.value
                        setSelectedVariable(tempSelectedVariable)
                      }}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group
                    controlId={
                      'qx_variable_calculate_method_' + selectedVariable._id
                    }
                  >
                    <Form.Label>Calculation Method</Form.Label>
                    <Form.Control
                      as='select'
                      value={selectedVariable.calculate_method}
                      onChange={(e) => {
                        let tempSelectedVariable = { ...selectedVariable }
                        tempSelectedVariable.calculate_method = e.target.value
                        tempSelectedVariable.values =
                          getInitialValuesForCalculateMethod(e.target.value)
                        setSelectedVariable(tempSelectedVariable)
                      }}
                    >
                      <option value=''>Please Select...</option>
                      <option value='sum'>
                        Summation (i.e. adding all the numerical values selected
                        below)
                      </option>
                      <option value='average'>
                        Average (i.e. taking average of all the numerical values
                        selected below)
                      </option>
                      <option value='subtract'>
                        Subtraction (i.e. Value 1 - Value 2)
                      </option>
                      <option value='time_diff'>
                        Time Difference (i.e. difference between two time values
                        selected below)
                      </option>
                      <option value='percentage'>
                        Percentage (i.e. (numerator/denominator)*100%)
                      </option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Form.Label>Variable Values</Form.Label>
              {selectedVariable &&
                selectedVariable.calculate_method &&
                selectedVariable.values.map((value, index) => (
                  <Card
                    className='p-3 my-0 pb-0 rounded'
                    key={
                      'qx_variable_value_' + selectedVariable._id + '_' + index
                    }
                  >
                    <Row>
                      <Col md={9}>
                        {selectedVariable.calculate_method !== 'time_diff' && (
                          <Row>
                            <Col md={12}>
                              <Form.Group
                                controlId={
                                  'qx_variable_value_type_' +
                                  selectedVariable._id +
                                  '_' +
                                  index
                                }
                              >
                                <Form.Label>
                                  {getValueLabel(index)} Type
                                </Form.Label>
                                <Form.Control
                                  as='select'
                                  value={selectedVariable.values[index].type}
                                  onChange={(e) => {
                                    let tempSelectedVariable = {
                                      ...selectedVariable,
                                    }
                                    tempSelectedVariable.values[index].type =
                                      e.target.value
                                    setSelectedVariable(tempSelectedVariable)
                                  }}
                                >
                                  <option value=''>Select Value Type...</option>
                                  <option value='variable'>Variable</option>
                                  <option value='mc'>
                                    Selected Multiple Choice Value
                                  </option>
                                  <option value='duration_total_minutes'>
                                    Duration Response in Minutes
                                  </option>
                                  <option value='text_numeric'>
                                    Number-Only Text Box Response
                                  </option>
                                </Form.Control>
                              </Form.Group>
                            </Col>
                          </Row>
                        )}
                        {selectedVariable.values[index].type && (
                          <Row>
                            <Col>
                              {selectedVariable.values[index].type ===
                              'variable' ? (
                                <Form.Group
                                  controlId={
                                    'qx_variable_variable_value_' +
                                    selectedVariable._id +
                                    '_' +
                                    index
                                  }
                                >
                                  <Form.Control
                                    as='select'
                                    value={
                                      selectedVariable.values[index].variable
                                    }
                                    onChange={(e) => {
                                      let tempSelectedVariable = {
                                        ...selectedVariable,
                                      }
                                      tempSelectedVariable.values[
                                        index
                                      ].variable = e.target.value
                                      setSelectedVariable(tempSelectedVariable)
                                    }}
                                  >
                                    <option value=''>Select Variable...</option>
                                    {variableList
                                      .filter(
                                        (x) => x._id !== selectedVariable._id
                                      )
                                      .map((variable, vIndex) => (
                                        <option
                                          key={
                                            'qx_variable_variable_value_option_' +
                                            vIndex
                                          }
                                          value={variable._id}
                                        >
                                          {variable.name}
                                        </option>
                                      ))}
                                  </Form.Control>
                                </Form.Group>
                              ) : (
                                <>
                                  <Form.Label>
                                    {getValueLabel(index)}
                                  </Form.Label>
                                  <Row>
                                    <SelectQuestionResponseAsVariableValue
                                      key={
                                        'qx_variable_question_value_' +
                                        selectedVariable._id +
                                        '_' +
                                        index
                                      }
                                      qx={qx}
                                      value={selectedVariable.values[index]}
                                      variableValueType={
                                        selectedVariable.values[index].type
                                      }
                                      onSelectedResponseChanged={(
                                        qpos,
                                        rpos
                                      ) => {
                                        console.log(rpos)

                                        let tempSelectedVariable = {
                                          ...selectedVariable,
                                        }
                                        tempSelectedVariable.values[
                                          index
                                        ].qpos = qpos
                                        tempSelectedVariable.values[
                                          index
                                        ].rpos = rpos
                                        setSelectedVariable(
                                          tempSelectedVariable
                                        )
                                      }}
                                    ></SelectQuestionResponseAsVariableValue>
                                  </Row>
                                </>
                              )}
                            </Col>
                          </Row>
                        )}
                      </Col>

                      <Col md={3}>
                        <Row>
                          <Col>
                            <Button
                              className='w-100'
                              variant='danger'
                              onClick={(e) => handleRemoveValue(e, index)}
                            >
                              Remove Value
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                ))}
              {selectedVariable.calculate_method !== 'time_diff' &&
                selectedVariable.calculate_method !== 'percentage' &&
                selectedVariable.calculate_method !== 'subtract' && (
                  <Card className='p-2 rounded'>
                    <Button onClick={handleAddValue}>Add Value</Button>
                  </Card>
                )}
            </Card>

            {selectedVariable._id && (
              <Card className='p-2 rounded mt-2'>
                <Button
                  variant='danger'
                  onClick={(e) => handleDeleteVariable(e, selectedVariable._id)}
                >
                  Remove Variable
                </Button>
              </Card>
            )}
          </>
        )}
      </ModalContainer>
    </>
  )
}

export default ModifyQxVariables
