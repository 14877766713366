import {
  CUSTOM_PAGE_COMPONENT_CREATE_FAIL,
  CUSTOM_PAGE_COMPONENT_CREATE_REQUEST,
  CUSTOM_PAGE_COMPONENT_CREATE_RESET,
  CUSTOM_PAGE_COMPONENT_CREATE_SUCCESS,
  CUSTOM_PAGE_COMPONENT_DELETE_FAIL,
  CUSTOM_PAGE_COMPONENT_DELETE_REQUEST,
  CUSTOM_PAGE_COMPONENT_DELETE_RESET,
  CUSTOM_PAGE_COMPONENT_DELETE_SUCCESS,
  CUSTOM_PAGE_COMPONENT_DETAILS_FAIL,
  CUSTOM_PAGE_COMPONENT_DETAILS_REQUEST,
  CUSTOM_PAGE_COMPONENT_DETAILS_RESET,
  CUSTOM_PAGE_COMPONENT_DETAILS_SUCCESS,
  CUSTOM_PAGE_COMPONENT_EDIT_FAIL,
  CUSTOM_PAGE_COMPONENT_EDIT_REQUEST,
  CUSTOM_PAGE_COMPONENT_EDIT_RESET,
  CUSTOM_PAGE_COMPONENT_EDIT_SUCCESS,
  CUSTOM_PAGE_COMPONENT_LIST_FAIL,
  CUSTOM_PAGE_COMPONENT_LIST_REQUEST,
  CUSTOM_PAGE_COMPONENT_LIST_RESET,
  CUSTOM_PAGE_COMPONENT_LIST_SUCCESS,
  TX_PAGE_CREATE_FAIL,
  TX_PAGE_CREATE_REQUEST,
  TX_PAGE_CREATE_RESET,
  TX_PAGE_CREATE_SUCCESS,
  TX_PAGE_DELETE_FAIL,
  TX_PAGE_DELETE_REQUEST,
  TX_PAGE_DELETE_RESET,
  TX_PAGE_DELETE_SUCCESS,
  TX_PAGE_DETAILS_FAIL,
  TX_PAGE_DETAILS_REQUEST,
  TX_PAGE_DETAILS_RESET,
  TX_PAGE_DETAILS_SUCCESS,
  TX_PAGE_EDIT_FAIL,
  TX_PAGE_EDIT_REQUEST,
  TX_PAGE_EDIT_RESET,
  TX_PAGE_EDIT_SUCCESS,
  TX_PAGE_LIST_FAIL,
  TX_PAGE_LIST_REQUEST,
  TX_PAGE_LIST_RESET,
  TX_PAGE_LIST_SUCCESS,
} from '../constants/txMaterialsConstants'

export const txPageListReducer = (state = { txPages: [] }, action) => {
  switch (action.type) {
    case TX_PAGE_LIST_REQUEST:
      return { loading: true, txPages: [] }
    case TX_PAGE_LIST_SUCCESS:
      return {
        loading: false,
        txPages: action.payload.txPages,
        success: true,
      }
    case TX_PAGE_LIST_FAIL:
      return { loading: false, error: action.payload, txPages: [] }
    case TX_PAGE_LIST_RESET:
      return { txPages: [] }
    default:
      return state
  }
}

export const txPageCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TX_PAGE_CREATE_REQUEST:
      return { loading: true }
    case TX_PAGE_CREATE_SUCCESS:
      return { loading: false, success: true, txPage: action.payload }
    case TX_PAGE_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case TX_PAGE_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const txPageDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TX_PAGE_DELETE_REQUEST:
      return { loading: true }
    case TX_PAGE_DELETE_SUCCESS:
      return { loading: false, success: true }
    case TX_PAGE_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case TX_PAGE_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const txPageDetailsReducer = (
  state = { txPage: { page_components: [] } },
  action
) => {
  switch (action.type) {
    case TX_PAGE_DETAILS_REQUEST:
      return { loading: true, ...state }
    case TX_PAGE_DETAILS_SUCCESS:
      return { loading: false, success: true, txPage: action.payload }
    case TX_PAGE_DETAILS_FAIL:
      return {
        loading: false,
        txPage: { page_components: [] },
        error: action.payload,
      }
    case TX_PAGE_DETAILS_RESET:
      return { txPage: { page_components: [] } }
    default:
      return state
  }
}

export const txPageEditReducer = (state = {}, action) => {
  switch (action.type) {
    case TX_PAGE_EDIT_REQUEST:
      return { loading: true }
    case TX_PAGE_EDIT_SUCCESS:
      return { loading: false, success: true }
    case TX_PAGE_EDIT_FAIL:
      return { loading: false, error: action.payload }
    case TX_PAGE_EDIT_RESET:
      return {}
    default:
      return state
  }
}

export const customPageComponentListReducer = (
  state = { customPageComponents: [] },
  action
) => {
  switch (action.type) {
    case CUSTOM_PAGE_COMPONENT_LIST_REQUEST:
      return { loading: true, customPageComponents: [] }
    case CUSTOM_PAGE_COMPONENT_LIST_SUCCESS:
      return {
        loading: false,
        customPageComponents: action.payload.customPageComponents,
        success: true,
      }
    case CUSTOM_PAGE_COMPONENT_LIST_FAIL:
      return { loading: false, error: action.payload }
    case CUSTOM_PAGE_COMPONENT_LIST_RESET:
      return { customPageComponents: [] }
    default:
      return state
  }
}

export const customPageComponentCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_PAGE_COMPONENT_CREATE_REQUEST:
      return { loading: true }
    case CUSTOM_PAGE_COMPONENT_CREATE_SUCCESS:
      return { loading: false, success: true, txPage: action.payload }
    case CUSTOM_PAGE_COMPONENT_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case CUSTOM_PAGE_COMPONENT_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const customPageComponentDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_PAGE_COMPONENT_DELETE_REQUEST:
      return { loading: true }
    case CUSTOM_PAGE_COMPONENT_DELETE_SUCCESS:
      return { loading: false, success: true }
    case CUSTOM_PAGE_COMPONENT_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case CUSTOM_PAGE_COMPONENT_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const customPageComponentDetailsReducer = (
  state = { customPageComponent: {} },
  action
) => {
  switch (action.type) {
    case CUSTOM_PAGE_COMPONENT_DETAILS_REQUEST:
      return { loading: true, ...state }
    case CUSTOM_PAGE_COMPONENT_DETAILS_SUCCESS:
      return {
        loading: false,
        success: true,
        customPageComponent: action.payload,
      }
    case CUSTOM_PAGE_COMPONENT_DETAILS_FAIL:
      return {
        loading: false,
        customPageComponent: {},
        error: action.payload,
      }
    case CUSTOM_PAGE_COMPONENT_DETAILS_RESET:
      return { customPageComponent: {} }
    default:
      return state
  }
}

export const customPageComponentEditReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_PAGE_COMPONENT_EDIT_REQUEST:
      return { loading: true }
    case CUSTOM_PAGE_COMPONENT_EDIT_SUCCESS:
      return { loading: false, success: true }
    case CUSTOM_PAGE_COMPONENT_EDIT_FAIL:
      return { loading: false, error: action.payload }
    case CUSTOM_PAGE_COMPONENT_EDIT_RESET:
      return {}
    default:
      return state
  }
}
