import {
  GET_ALL_ACCESS_REQUEST,
  GET_ALL_ACCESS_SUCCESS,
  GET_ALL_ACCESS_FAIL,
  GET_ALL_ACCESS_RESET,
} from '../constants/accessConstants'

export const allAccessReducer = (state = { allAccessList: [] }, action) => {
  switch (action.type) {
    case GET_ALL_ACCESS_REQUEST:
      return { loading: true, success: false, allAccessList: [] }
    case GET_ALL_ACCESS_SUCCESS:
      return {
        loading: false,
        success: true,
        allAccessList: action.payload.allAccess,
      }
    case GET_ALL_ACCESS_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
        allAccessList: [],
      }
    case GET_ALL_ACCESS_RESET:
      return { allAccessList: [] }
    default:
      return state
  }
}
