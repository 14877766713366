import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Row, Col, Form } from 'react-bootstrap'
import Loader from './Loader'
import Message from './Message'
import {
  systemOwnerListLabs,
  systemOwnerGetUserByLab,
} from '../../admin_module/actions/labActions'
import Organizations from '../../enums/organizations'

export const SystemOwnerStudyFilters = ({ onFilterUpdated }) => {
  const labList = useSelector((state) => state.systemOwnerLabList)
  const {
    loading: loadingLabs,
    error: errorGetLabs,
    labs: loadedLabs,
    success: successGetLabs,
  } = labList

  const labUserList = useSelector((state) => state.systemOwnerLabUserList)
  const {
    loading: loadingLabUsers,
    error: errorGetLabUsers,
    labUsers: loadedLabUsers, // Different from labedLabs, it could be undefined when there is no labUsers
  } = labUserList

  const dispatch = useDispatch()

  const [selectedLabId, setSelectedLabId] = useState('unselected')
  const [selectedManagedByUserId, setSelectedManagedByUserId] =
    useState('unselected')

  useEffect(() => {
    if (!loadingLabs && !errorGetLabs) {
      if (!successGetLabs) {
        dispatch(systemOwnerListLabs())
      } else {
        if (
          selectedLabId === 'unselected' &&
          selectedManagedByUserId === 'unselected'
        ) {
          onFilterUpdated('unselected', 'unselected')
        }
      }
    }
  }, [
    dispatch,
    loadingLabs,
    errorGetLabs,
    successGetLabs,
    onFilterUpdated,
    selectedLabId,
    selectedManagedByUserId,
  ])

  return (
    <>
      {errorGetLabs && <Message variant='danger'>{errorGetLabs}</Message>}
      {errorGetLabUsers && <Message variant='danger'>{errorGetLabs}</Message>}
      {loadingLabs || loadingLabUsers ? (
        <Loader />
      ) : (
        <Row>
          <Col md={6}>
            <Form.Group controlId={'system_owner_study_filters_lab'}>
              <Form.Label>Lab</Form.Label>
              <Form.Control
                as='select'
                value={selectedLabId}
                onChange={(e) => {
                  // The condition check is essential. Otherwise, it will end up an endless loop.
                  if (e.target.value !== selectedLabId) {
                    onFilterUpdated(selectedLabId, 'unselected')
                    setSelectedLabId(e.target.value)
                    setSelectedManagedByUserId('unselected')
                    if (e.target.value !== 'unselected')
                      dispatch(systemOwnerGetUserByLab(e.target.value))
                  }
                }}
              >
                <option
                  key={'system_owner_study_filters_lab_unselected'}
                  value={'unselected'}
                >
                  -- Please Select --
                </option>
                {loadedLabs.map((lab) => (
                  <option
                    key={'system_owner_study_filters_lab_' + lab._id}
                    value={lab._id}
                  >
                    {lab.name} (
                    {Object.keys(Organizations).find(
                      (key) => Organizations[key] === lab.organization
                    )}
                    )
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          {selectedLabId !== 'unselected' && (
            <Col md={6}>
              <Form.Group controlId={'system_owner_study_filters_managed_by'}>
                <Form.Label>Managed By</Form.Label>
                <Form.Control
                  as='select'
                  value={selectedManagedByUserId}
                  onChange={(e) => {
                    // The condition check is essential. Otherwise, it will end up an endless loop.
                    if (e.target.value !== selectedManagedByUserId) {
                      onFilterUpdated(selectedLabId, e.target.value)
                      setSelectedManagedByUserId(e.target.value)
                    }
                  }}
                >
                  <option
                    key={'system_owner_study_filters_managed_by_unselected'}
                    value={'unselected'}
                  >
                    -- Please Select --
                  </option>
                  {loadedLabUsers &&
                    loadedLabUsers.map((user) => (
                      <option
                        key={
                          'system_owner_study_filters_managed_by_' + user._id
                        }
                        value={user._id}
                      >
                        {user.email}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
          )}
        </Row>
      )}
    </>
  )
}

export default SystemOwnerStudyFilters
