const getSubdomain = (window) => {
  let host = window.location.host
  let parts = host.split('.')
  if (parts.length >= 3) {
    return parts[0]
  } else {
    return undefined
  }
}

export default getSubdomain
