import {
  TX_PAGE_CREATE_RESET,
  TX_PAGE_DELETE_RESET,
  TX_PAGE_DETAILS_RESET,
  TX_PAGE_LIST_RESET,
} from '../constants/txMaterialsConstants'

const resetErrorState = (dispatch) => {
  dispatch({ type: TX_PAGE_CREATE_RESET })
  dispatch({ type: TX_PAGE_DELETE_RESET })
  dispatch({ type: TX_PAGE_DETAILS_RESET })
  dispatch({ type: TX_PAGE_LIST_RESET })
}

export default resetErrorState
