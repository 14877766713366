import axios from 'axios'
import {
  GET_LAB_NAMES_FAIL,
  GET_LAB_NAMES_SUCCESS,
  GET_LAB_NAMES_REQUEST,
  SYSTEM_OWNER_CREATE_LABS_FAIL,
  SYSTEM_OWNER_CREATE_LABS_REQUEST,
  SYSTEM_OWNER_CREATE_LABS_SUCCESS,
  SYSTEM_OWNER_GET_LABS_FAIL,
  SYSTEM_OWNER_GET_LABS_REQUEST,
  SYSTEM_OWNER_GET_LABS_SUCCESS,
  SYSTEM_OWNER_GET_LABS_USERS_FAIL,
  SYSTEM_OWNER_GET_LABS_USERS_REQUEST,
  SYSTEM_OWNER_GET_LABS_USERS_SUCCESS,
  GET_LAB_INFO_REQUEST,
  GET_LAB_INFO_SUCCESS,
  GET_LAB_INFO_FAIL,
} from '../constants/labConstants'
import { USER_LOGOUT } from '../constants/userConstants'
import { SYSTEM_OWNER_LOGOUT } from '../constants/userConstants'

import BACKEND_URL from '../../backendUrl'

/******************* actions for users *******************/

// get lab names ( for user register page)
export const userListLabNames = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_LAB_NAMES_REQUEST,
    })

    const { data } = await axios.get(BACKEND_URL + '/api/labs/labnames')

    dispatch({
      type: GET_LAB_NAMES_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: USER_LOGOUT,
      })
      localStorage.removeItem('adminModuleUserInfo')
    } else {
      dispatch({
        type: GET_LAB_NAMES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
}

// get lab info (admin)
export const getLabInfo = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_LAB_INFO_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      BACKEND_URL + `/api/labs/labinfo/${id}`,
      config
    )

    let storageSize = data.storage
    let progressBarPercentage =
      storageSize === 0 ? 0 : (storageSize / 2147483648).toFixed(2) * 100 + '%'

    dispatch({
      type: GET_LAB_INFO_SUCCESS,
      payload: { ...data, progressBarPercentage },
    })
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: USER_LOGOUT,
      })
      localStorage.removeItem('adminModuleUserInfo')
    } else {
      dispatch({
        type: GET_LAB_INFO_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
}

/******************* actions for system owner ****************/
// list labs (system owner)
export const systemOwnerListLabs = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SYSTEM_OWNER_GET_LABS_REQUEST,
    })

    const {
      systemOwnerLogin: { systemOwnerInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${systemOwnerInfo.token}`,
      },
    }

    const { data } = await axios.get(BACKEND_URL + '/api/labs', config)

    dispatch({
      type: SYSTEM_OWNER_GET_LABS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: SYSTEM_OWNER_LOGOUT,
      })
      localStorage.removeItem('adminModuleSystemOwnerInfo')
    } else {
      dispatch({
        type: SYSTEM_OWNER_GET_LABS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
}

// get users by lab id (system owner)
export const systemOwnerGetUserByLab = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SYSTEM_OWNER_GET_LABS_USERS_REQUEST,
    })

    const {
      systemOwnerLogin: { systemOwnerInfo },
    } = getState()

    const config = {
      // !!!!!
      headers: {
        Authorization: `Bearer ${systemOwnerInfo.token}`,
      },
    }

    const { data } = await axios.get(
      BACKEND_URL + `/api/labs/systemowner/${id}`,
      config
    )

    dispatch({
      type: SYSTEM_OWNER_GET_LABS_USERS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: SYSTEM_OWNER_LOGOUT,
      })
      localStorage.removeItem('adminModuleSystemOwnerInfo')
    } else {
      dispatch({
        type: SYSTEM_OWNER_GET_LABS_USERS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
}

// create lab (system owner)
export const systemOwnerCreateLab = (lab) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SYSTEM_OWNER_CREATE_LABS_REQUEST,
    })

    const {
      systemOwnerLogin: { systemOwnerInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${systemOwnerInfo.token}`,
      },
    }

    await axios.post(
      BACKEND_URL + '/api/labs/systemowner/createlab',
      lab,
      config
    )

    dispatch({
      type: SYSTEM_OWNER_CREATE_LABS_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: SYSTEM_OWNER_CREATE_LABS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
