import React, { useState, useEffect, useRef } from 'react'
import { Button, Row, Col, Form, Card } from 'react-bootstrap'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import ModalContainer from './ModalContainer'
import Message from './Message'

export const TxPackage = ({
  group,
  index,
  handleDelete,
  txPages,
  updateGroupAx,
  readOnly,
}) => {
  const modalRef = useRef()

  const [show, setShow] = useState(false)

  const [title, setTitle] = useState('')
  const [previewImageFilename, setPreviewImageFilename] = useState('')
  const [previewText, setPreviewText] = useState('')
  const [presentMethod, setPresentMethod] = useState('')
  const [pageList, setPageList] = useState([])

  const [saveError, setSaveError] = useState('')

  useEffect(() => {
    setTitle(group.tx_package_list[index].title)
    setPreviewImageFilename(group.tx_package_list[index].preview_image_filename)
    setPreviewText(group.tx_package_list[index].preview_text)
    setPresentMethod(group.tx_package_list[index].present_method)
    setPageList(group.tx_package_list[index].pages)
  }, [group, index])

  const handleOpen = () => {
    setShow(true)
  }

  const handlePageDelete = (i) => {
    let updatedPageList = [...pageList]
    updatedPageList.splice(i, 1)
    setPageList(updatedPageList)
  }

  const handlePageAdd = () => {
    let updatedPageList = [...pageList]
    updatedPageList.push({
      name: '(Please Edit)',
      require_to_read: true,
      page: '',
    })
    setPageList(updatedPageList)
  }

  // Dependency: react-beautiful-dnd
  const handleDrop = (droppedItem) => {
    //Ignore drop outside droppable container
    if (!droppedItem.destination) return
    let updatedList = [...pageList]
    // Remove dragged item
    let [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem)
    //console.log(updatedList)
    // Update State
    setPageList(updatedList)
  }

  const availablePages = (selectedPageId) => {
    return txPages
      ? txPages.filter(
          (x) =>
            x._id === selectedPageId ||
            group.tx_package_list[index].pages.find((y) => y.page === x._id) ===
              undefined
        )
      : []
  }

  ////////// Validator ///////////

  const checkChapterTitleNonEmpty = () => {
    if (!title?.trim()) {
      return 'Chapter title cannot be empty'
    }
    return ''
  }

  const checkPagesTitleNonEmpty = () => {
    for (let i = 0; i < pageList.length; ++i) {
      if (!pageList[i].name?.trim()) {
        return 'Title of every page cannot be empty'
      }
    }
    return ''
  }

  const checkPagesSelected = () => {
    for (let i = 0; i < pageList.length; ++i) {
      if (!pageList[i].page) {
        return 'Please select treatment page or remove the one without selected page'
      }
    }
    return ''
  }

  /////////////////////////////////

  const handleClose = () => {
    setSaveError('')
    let error = ''

    if (error.length === 0) error = checkChapterTitleNonEmpty()
    if (error.length === 0) error = checkPagesTitleNonEmpty()
    if (error.length === 0) error = checkPagesSelected()

    if (error.length === 0) {
      group.tx_package_list[index].title = title
      group.tx_package_list[index].preview_image_filename = previewImageFilename
      group.tx_package_list[index].preview_text = previewText
      group.tx_package_list[index].present_method = presentMethod
      group.tx_package_list[index].pages = pageList
      setShow(false)
      updateGroupAx()
    } else {
      setSaveError(error)
      modalRef.current.scrollTop = 0
    }
  }

  return (
    <>
      {txPages && (
        <Card className='p-2 my-1 rounded'>
          <Row style={{ marginLeft: '-5px' }}>
            <Col
              md={6}
              style={{ whiteSpace: 'normal' }}
              className='align-self-center'
            >
              {group.tx_package_list[index].title}
            </Col>
            <Col md={3}>
              <Button
                style={{ width: '100%' }}
                variant='primary'
                className='btn-sm m-1'
                onClick={handleOpen}
              >
                {readOnly ? <>View</> : <>Edit</>}
              </Button>
            </Col>
            {!readOnly && (
              <Col md={3}>
                <Button
                  style={{ width: '100%', marginLeft: '-12px' }}
                  variant='danger'
                  className='btn-sm my-1'
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </Col>
            )}
          </Row>
        </Card>
      )}

      <ModalContainer
        title={readOnly ? 'Treatment Chapter' : 'Edit Treatment Chapter'}
        show={show}
        handleClose={handleClose}
        modalRef={modalRef}
      >
        {saveError && <Message variant='danger'>{saveError}</Message>}

        <Row>
          <Col md={12}>
            <Form.Group controlId={'tx_package_title' + group._id + index}>
              <Form.Label style={{ whiteSpace: 'normal' }}>
                Chapter Title
              </Form.Label>
              <Form.Control
                type='text'
                value={title}
                placeholder='Chapter Title'
                onChange={(e) => {
                  setTitle(e.target.value)
                }}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Form.Group
              controlId={'tx_package_preview_img_filename' + group._id + index}
            >
              <Form.Label style={{ whiteSpace: 'normal' }}>
                Preview Image Filename
              </Form.Label>
              <Form.Control
                type='text'
                value={previewImageFilename}
                onChange={(e) => {
                  setPreviewImageFilename(e.target.value)
                }}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Form.Group
              controlId={'tx_package_preview_img_filename' + group._id + index}
            >
              <Form.Label style={{ whiteSpace: 'normal' }}>
                Preview Text
              </Form.Label>
              <Form.Control
                as='textarea'
                value={previewText}
                onChange={(e) => {
                  setPreviewText(e.target.value)
                }}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Form.Label>Treatment Pages</Form.Label>

        {pageList.length > 0 && (
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId='group-tx-package-pagelist-container'>
              {(provided) => (
                <Card
                  className='p-3 rounded'
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {pageList.map((page, i) => (
                    <Draggable
                      key={
                        'group_tx_package_pageList_draggable_' +
                        group._id +
                        '_' +
                        index +
                        '_' +
                        i
                      }
                      draggableId={
                        'group_tx_package_pageList_draggable_' +
                        group._id +
                        '_' +
                        index +
                        '_' +
                        i
                      }
                      index={i}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                        >
                          <Card
                            key={
                              'tx_package_pageList_page' +
                              group._id +
                              '_' +
                              index +
                              '_' +
                              i
                            }
                            className='pt-2 px-2 my-1'
                          >
                            <Row>
                              <Col md={10}>
                                <Form.Group>
                                  <Row>
                                    <Col md={6}>
                                      <Form.Label>Page Name</Form.Label>
                                      <Form.Control
                                        type='text'
                                        placeholder='Enter Page Name'
                                        value={pageList[i].name}
                                        onChange={(e) => {
                                          let temp = [...pageList]
                                          temp[i].name = e.target.value
                                          setPageList(temp)
                                        }}
                                      ></Form.Control>
                                    </Col>
                                    <Col md={6}>
                                      <Form.Label>Page</Form.Label>
                                      <Form.Control
                                        as='select'
                                        value={pageList[i].page}
                                        onChange={(e) => {
                                          let temp = [...pageList]
                                          temp[i].page = e.target.value
                                          setPageList(temp)
                                        }}
                                      >
                                        <option value=''>Please Select</option>
                                        {availablePages(pageList[i].page).map(
                                          (page) => (
                                            <option
                                              value={page._id}
                                              key={page._id}
                                            >
                                              {page.identifier}
                                            </option>
                                          )
                                        )}
                                      </Form.Control>
                                    </Col>
                                  </Row>
                                  {presentMethod === 'one_by_one' && (
                                    <Row className='mt-3'>
                                      <Col>
                                        <Form.Check
                                          type='checkbox'
                                          label='Page is unlocked all the time'
                                          checked={!pageList[i].require_to_read}
                                          // for checkbox it is e.target.checked
                                          onChange={(e) => {
                                            let temp = [...pageList]
                                            temp[i].require_to_read =
                                              !e.target.checked
                                            setPageList(temp)
                                          }}
                                        ></Form.Check>
                                      </Col>
                                    </Row>
                                  )}
                                </Form.Group>
                              </Col>
                              {!readOnly && (
                                <Col md={2} className='align-self-center'>
                                  <Button
                                    style={{
                                      width: '100%',
                                      marginLeft: '-6px',
                                    }}
                                    variant='danger'
                                    className='btn-sm align-middle'
                                    onClick={() => handlePageDelete(i)}
                                  >
                                    Delete
                                  </Button>
                                </Col>
                              )}
                            </Row>
                          </Card>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Card>
              )}
            </Droppable>
          </DragDropContext>
        )}

        {!readOnly ? (
          <Row>
            <Col md={12}>
              <Button
                style={{
                  width: '100%',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
                variant='primary'
                onClick={handlePageAdd}
              >
                Add Treatment Page
              </Button>
            </Col>
          </Row>
        ) : (
          <br></br>
        )}

        <Row>
          <Col md={12}>
            <Form.Group
              controlId={'tx_package_present_method_' + group._id + index}
            >
              <Form.Label>How the pages should be made avaialbe?</Form.Label>
              <Form.Control
                as='select'
                value={presentMethod}
                onChange={(e) => {
                  setPresentMethod(e.target.value)
                  if (e.target.value === 'all') {
                    let temp = [...pageList]
                    for (let i = 0; i < temp.length; ++i) {
                      temp[i].require_to_read = false
                    }
                    setPageList(temp)
                  }
                }}
              >
                <option value='one_by_one'>
                  One by one (i.e. a page is unlocked only after the previous
                  page has been read)
                </option>
                <option value='all'>
                  All available (i.e. all pages are unlocked)
                </option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </ModalContainer>
    </>
  )
}

export default TxPackage
