////////////////////////////////////////////////////////
//The modalRef on this page demostrates how useRef
//   can be hooked to a child component
////////////////////////////////////////////////////////

import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Row, Col, Breadcrumb, Form, Card } from 'react-bootstrap'
import ModalContainer from '../components/ModalContainer'

import { createMc, deleteMc, editMc, listMcDetails } from '../actions/qxActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import {
  MC_CREATE_RESET,
  MC_DELETE_RESET,
  MC_DETAILS_RESET,
  MC_EDIT_RESET,
} from '../constants/qxConstants'

export const ModifyMultipleChoice = ({
  qId,
  mcList,
  onUpdate,
  onCreate,
  onDelete,
}) => {
  const modalRef = useRef()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const mcCreate = useSelector((state) => state.mcCreate)
  const {
    loading: loadingCreate,
    success: successCreate,
    error: errorCreate,
  } = mcCreate

  const mcEdit = useSelector((state) => state.mcEdit)
  const {
    loading: loadingEdit,
    success: successEdit,
    error: errorEdit,
  } = mcEdit

  const mcDelete = useSelector((state) => state.mcDelete)
  const {
    loading: loadingDelete,
    success: successDelete,
    error: errorDelete,
  } = mcDelete

  const mcDetails = useSelector((state) => state.mcDetails)
  const {
    loading: loadingMcDetails,
    success: successMcDetails,
    mc: initialMcDetails,
    error: errorMcDetails,
  } = mcDetails

  const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [loadedMcList, setLoadedMcList] = useState([])
  const [selectedMc, setSelectedMc] = useState(undefined)
  const [saveError, setSaveError] = useState('')

  useEffect(() => {
    setLoadedMcList(mcList)

    let now = new Date()
    if (userInfo && userInfo.expires > now && !logout) {
      if (successMcDetails) {
        setSelectedMc(initialMcDetails)
      }
      if (successEdit) {
        setSelectedMc(undefined)
        setShow(false)
        onUpdate()
        dispatch({ type: MC_EDIT_RESET })
      }
      if (successCreate) {
        setSelectedMc(undefined)
        setShow(false)
        onCreate()
        dispatch({ type: MC_CREATE_RESET })
      }
      if (successDelete) {
        setSelectedMc(undefined)
        setShow(false)
        onDelete()
        dispatch({ type: MC_DELETE_RESET })
      }
    }
  }, [
    mcList,
    userInfo,
    logout,
    successMcDetails,
    successEdit,
    successCreate,
    successDelete,
    initialMcDetails,
    onUpdate,
    onCreate,
    onDelete,
    dispatch,
  ])

  const handleOpen = () => {
    setShow(true)
  }

  const handleClose = () => {
    setSelectedMc(undefined)
    setShow(false)
  }

  //////// Validators //////////

  const checkNameNonEmpty = () => {
    let error_msg = ''
    if (selectedMc.name.length === 0) {
      error_msg = 'Choice name and value cannot be empty'
      return error_msg
    }
    return error_msg
  }

  const checkChoiceNonEmpty = () => {
    let error_msg = ''
    if (selectedMc.set.length < 2) {
      error_msg = 'There must be at least 2 choices'
      return error_msg
    }
    return error_msg
  }

  const checkChoiceNameValueNonEmpty = () => {
    let error_msg = ''
    for (let i = 0; i < selectedMc.set.length; ++i) {
      if (
        selectedMc.set[i].name.length === 0 ||
        selectedMc.set[i].value.length === 0
      ) {
        error_msg = 'Choice name and value cannot be empty'
        return error_msg
      }
    }
    return error_msg
  }

  //////////////////////////////

  const handleSelectMcSet = (e, id) => {
    e.preventDefault()
    dispatch({ type: MC_DETAILS_RESET })
    dispatch(listMcDetails(id))
  }

  const handleDeleteMcset = (e, id) => {
    e.preventDefault()
    dispatch(deleteMc(id))
  }

  // When clicking create new
  const handleAddNewMcSet = () => {
    const newMcSet = {
      _id: '',
      name: '',
      set: [],
    }

    setSelectedMc(newMcSet)
  }

  // When clicking add new choice
  const handleAddChoice = () => {
    let tempSelectedMc = { ...selectedMc }
    const newChoice = {
      name: '',
      value: '',
      position: tempSelectedMc.set.length + 1,
    }
    tempSelectedMc.set.push(newChoice)
    setSelectedMc(tempSelectedMc)
  }

  const handleRemoveChoice = (e, index) => {
    e.preventDefault()
    let tempSelectedMc = { ...selectedMc }
    tempSelectedMc.set.splice(index, 1)
    setSelectedMc(tempSelectedMc)
  }

  const handleMoveChoiceDown = (e, index) => {
    e.preventDefault()
    let tempSelectedMc = { ...selectedMc }
    let targetChoiceCopy = JSON.parse(JSON.stringify(tempSelectedMc.set[index]))
    tempSelectedMc.set.splice(index, 1)
    tempSelectedMc.set.splice(index + 1, 0, targetChoiceCopy)
    for (let i = 0; i < tempSelectedMc.set.length; ++i) {
      tempSelectedMc.set[i].position = i + 1
    }
    setSelectedMc(tempSelectedMc)
  }

  const handleSave = () => {
    setSaveError('')
    let error = ''

    if (error.length === 0) error = checkNameNonEmpty()
    if (error.length === 0) error = checkChoiceNonEmpty()
    if (error.length === 0) error = checkChoiceNameValueNonEmpty()

    if (error.length === 0) {
      if (selectedMc._id) {
        //Edit
        dispatch(editMc(selectedMc))
      } else {
        //Create
        dispatch(createMc(qId, selectedMc))
      }
    } else {
      setSaveError(error)
      modalRef.current.scrollTop = 0
    }
  }

  return (
    <>
      {loadingCreate || loadingEdit || loadingMcDetails || loadingDelete ? (
        <Loader />
      ) : (
        <Button
          variant='primary'
          onClick={handleOpen}
          style={{ marginTop: '3px' }}
        >
          Create or Modify Multiple Choices
        </Button>
      )}
      <ModalContainer
        title='Multiple Choices Set'
        show={show}
        saveAvailable={selectedMc}
        handleSave={handleSave}
        handleClose={handleClose}
        modalRef={modalRef}
      >
        {saveError && <Message variant='danger'>{saveError}</Message>}
        {errorEdit && <Message variant='danger'>{errorEdit}</Message>}
        {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
        {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
        {errorMcDetails && <Message variant='danger'>{errorMcDetails}</Message>}

        <Row>
          <Col>
            <Breadcrumb>
              {loadedMcList &&
                loadedMcList.map((mc) => (
                  <Breadcrumb.Item
                    key={mc._id}
                    onClick={(e) => {
                      handleSelectMcSet(e, mc._id)
                    }}
                    active={
                      selectedMc && selectedMc._id && selectedMc._id === mc._id
                    }
                  >
                    {mc.name}
                  </Breadcrumb.Item>
                ))}
              <Breadcrumb.Item
                onClick={handleAddNewMcSet}
                active={selectedMc && selectedMc._id.length === 0}
              >
                Create New
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        {!selectedMc ? (
          <Row>
            <Col>
              <p>Please select or create a new multiple choices set above</p>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col>
                {selectedMc._id && (
                  <Button
                    className='mb-2'
                    variant='danger'
                    onClick={(e) => handleDeleteMcset(e, selectedMc._id)}
                  >
                    Remove {selectedMc.name}
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId={'mc_name' + selectedMc._id}>
                  <Form.Label>
                    Identifier (which will be appeared as selection choice)
                  </Form.Label>
                  <Form.Control
                    type='text'
                    value={selectedMc.name}
                    onChange={(e) => {
                      let tempSelectedMc = { ...selectedMc }
                      tempSelectedMc.name = e.target.value
                      setSelectedMc(tempSelectedMc)
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>

            {selectedMc &&
              selectedMc.set.map((mc, index) => (
                <Card
                  className='p-2 my-1 rounded'
                  key={'mc_choice_' + selectedMc._id + '_' + index}
                >
                  <Row>
                    <Col md={5}>
                      <Form.Group
                        controlId={
                          'mc_choice_name_' + selectedMc._id + '_' + index
                        }
                      >
                        <Form.Label>
                          Choice text (which will be presented to users)
                        </Form.Label>
                        <Form.Control
                          type='text'
                          value={selectedMc.set[index].name}
                          onChange={(e) => {
                            let tempSelectedMc = { ...selectedMc }
                            tempSelectedMc.set[index].name = e.target.value
                            setSelectedMc(tempSelectedMc)
                          }}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={5}>
                      <Form.Group
                        controlId={
                          'mc_choice_val_' + selectedMc._id + '_' + index
                        }
                      >
                        <Form.Label>
                          Choice value (which will be exported in dataset)
                        </Form.Label>
                        <Form.Control
                          type='text'
                          value={selectedMc.set[index].value}
                          onChange={(e) => {
                            let tempSelectedMc = { ...selectedMc }
                            tempSelectedMc.set[index].value = e.target.value
                            setSelectedMc(tempSelectedMc)
                          }}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Row>
                        <Col>
                          <Button
                            variant='danger'
                            onClick={(e) => handleRemoveChoice(e, index)}
                          >
                            X
                          </Button>
                        </Col>
                      </Row>
                      {index !== selectedMc.set.length - 1 && (
                        <Row className='my-2'>
                          <Col>
                            <Button
                              onClick={(e) => handleMoveChoiceDown(e, index)}
                            >
                              ↓
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Card>
              ))}

            <Card className='p-2 rounded'>
              <Button onClick={handleAddChoice}>Add Choice</Button>
            </Card>
          </>
        )}
      </ModalContainer>
    </>
  )
}

export default ModifyMultipleChoice
