import React, { useState, useEffect } from 'react'
import { Card, Form, Row, Col, Button } from 'react-bootstrap'

export const TxStageRequiredTask = ({
  nextTxStageInfo,
  index,
  onRequiredTaskDelete,
  group,
  qxList,
  pageList,
}) => {
  const [taskType, setTaskType] = useState('') // can be 'ax','custom_develop', 'tx_page_read'

  // For custom_develop type
  const [txPackage, setTxPackage] = useState('')
  const [customTxPage, setCustomTxPage] = useState('')
  const [customTask, setCustomTask] = useState('')
  // For ax type
  const [axType, setAxType] = useState('') // can be 'no_submission_limit', 'questionnaire', 'diary'
  const [questionnaire, setQuestionnaire] = useState('')
  const [respTimes, setRespTimes] = useState(1) // For no_submission_limit
  const [diaryCheckForDaysBefore, setDiaryCheckForDaysBefore] = useState('') // For diary
  const [diaryTimes, setDiaryTimes] = useState('') // For diary

  useEffect(() => {
    setTaskType(nextTxStageInfo.required_tasks[index].task_type)
    // For custom_develop type
    if (nextTxStageInfo.required_tasks[index].tx_package)
      setTxPackage(nextTxStageInfo.required_tasks[index].tx_package)
    if (nextTxStageInfo.required_tasks[index].custom_tx_page)
      setCustomTxPage(nextTxStageInfo.required_tasks[index].custom_tx_page)
    if (nextTxStageInfo.required_tasks[index].custom_task)
      setCustomTask(nextTxStageInfo.required_tasks[index].custom_task)
    // For ax type
    if (nextTxStageInfo.required_tasks[index].ax_type)
      setAxType(nextTxStageInfo.required_tasks[index].ax_type)
    if (nextTxStageInfo.required_tasks[index].questionnaire)
      setQuestionnaire(nextTxStageInfo.required_tasks[index].questionnaire)
    if (nextTxStageInfo.required_tasks[index].resp_times)
      setRespTimes(nextTxStageInfo.required_tasks[index].resp_times)
    if (nextTxStageInfo.required_tasks[index].diary_check_for_days_before)
      setDiaryCheckForDaysBefore(
        nextTxStageInfo.required_tasks[index].diary_check_for_days_before
      )
    if (nextTxStageInfo.required_tasks[index].diary_times)
      setDiaryTimes(nextTxStageInfo.required_tasks[index].diary_times)
  }, [nextTxStageInfo, index])

  const getQuestionnaireList = () => {
    if (group?.questionnaire_list) {
      return group.questionnaire_list
    } else {
      return []
    }
  }

  const getNoSubmissionLimitList = () => {
    if (group?.no_submission_limit_list) {
      return group.no_submission_limit_list
    } else {
      return []
    }
  }

  const getDiaryQxList = () => {
    // TBD: Instead of hard coding the diary assessment.
    //      It should be allowed to be set up as a separate tab
    return qxList.filter(
      (qx) => String(qx._id) === String('63e9cf4929f8ba001fe4624e')
    )
  }

  const getPagesWithCustomTask = (txPackageId) => {
    let txPackagesPagesIds = []
    const foundTxPackage = group.tx_package_list.find(
      (x) => String(x._id) === String(txPackageId)
    )
    for (let i = 0; i < foundTxPackage.pages.length; ++i) {
      txPackagesPagesIds.push(foundTxPackage.pages[i].page)
    }

    if (pageList) {
      return pageList
        .filter((page) =>
          txPackagesPagesIds.some((x) => String(x) === String(page._id))
        )
        .filter((page) => {
          return page.page_components.some((comp) => comp.is_task_to_check)
        })
    } else {
      return []
    }
  }

  const getCustomTaskList = (pageId) => {
    const foundPage = pageList.find(
      (page) => String(page._id) === String(pageId)
    )
    return foundPage.page_components.filter((comp) => comp.is_task_to_check)
  }

  return (
    <Card style={{ marginLeft: '0' }}>
      <Card.Body>
        <Row>
          <Col md={12}>
            <Form.Group
              controlId={
                'txStageRequiredTask_' +
                nextTxStageInfo.required_tasks[index]._id +
                '_' +
                index
              }
            >
              <Form.Label>Required Task Type</Form.Label>
              <Form.Control
                as='select'
                value={taskType}
                onChange={(e) => {
                  setTaskType(e.target.value)
                  nextTxStageInfo.required_tasks[index].task_type =
                    e.target.value
                }}
              >
                <option value=''>Select Type...</option>
                <option value='ax'>Submit Assessment</option>
                <option value='custom_develop'>
                  Complete Task in Custom Developed Component
                </option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        {taskType === 'custom_develop' && (
          <Row>
            <Col md={12}>
              <Form.Group
                controlId={
                  'txStageRequiredTask_txPackage_' +
                  nextTxStageInfo.required_tasks[index]._id +
                  '_' +
                  index
                }
              >
                <Form.Label>Treatment Chapter</Form.Label>
                <Form.Control
                  as='select'
                  value={txPackage}
                  onChange={(e) => {
                    setTxPackage(e.target.value)
                    nextTxStageInfo.required_tasks[index].tx_package =
                      e.target.value
                    setCustomTxPage('')
                    nextTxStageInfo.required_tasks[index].custom_tx_page = ''
                    setCustomTask('')
                    nextTxStageInfo.required_tasks[index].custom_task = ''
                  }}
                >
                  <option value=''>Select Chapter...</option>
                  {group.tx_package_list.map((tx_package, tIndex) => (
                    <option
                      key={
                        'txStageRequiredTask_txPackage_choices_' +
                        nextTxStageInfo.required_tasks[index]._id +
                        '_' +
                        index +
                        '_' +
                        tIndex
                      }
                      value={tx_package._id}
                    >
                      {tx_package.title}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            {txPackage.length > 0 && (
              <Col md={12}>
                <Form.Group
                  controlId={
                    'txStageRequiredTask_customTxPage_' +
                    nextTxStageInfo.required_tasks[index]._id +
                    '_' +
                    index
                  }
                >
                  <Form.Label>Page with Custom Developed Task</Form.Label>
                  <Form.Control
                    as='select'
                    value={customTxPage}
                    onChange={(e) => {
                      setCustomTxPage(e.target.value)
                      nextTxStageInfo.required_tasks[index].custom_tx_page =
                        e.target.value
                      setCustomTask('')
                      nextTxStageInfo.required_tasks[index].custom_task = ''
                    }}
                  >
                    <option value=''>Select Page...</option>
                    {getPagesWithCustomTask(txPackage).map((page, pIndex) => (
                      <option
                        key={
                          'txStageRequiredTask_customTxPage_choices_' +
                          nextTxStageInfo.required_tasks[index]._id +
                          '_' +
                          index +
                          '_' +
                          pIndex
                        }
                        value={page._id}
                      >
                        {page.identifier}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            )}
            {customTxPage.length > 0 && (
              <Col md={12}>
                <Form.Group
                  controlId={
                    'txPageComponent_customTask_' +
                    nextTxStageInfo.required_tasks[index]._id +
                    '_' +
                    index
                  }
                >
                  <Form.Label>Task</Form.Label>
                  <Form.Control
                    as='select'
                    value={customTask}
                    onChange={(e) => {
                      setCustomTask(e.target.value)
                      nextTxStageInfo.required_tasks[index].custom_task =
                        e.target.value
                    }}
                  >
                    <option value=''>Select Task...</option>
                    {getCustomTaskList(customTxPage).map((comp, cIndex) => (
                      <option
                        key={
                          'txStageRequiredTask_customTask_choices_' +
                          nextTxStageInfo.required_tasks[index]._id +
                          '_' +
                          index +
                          '_' +
                          cIndex
                        }
                        value={comp._id}
                      >
                        {comp.custom_task_identifier}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            )}
          </Row>
        )}

        {taskType === 'ax' && (
          <Row>
            <Col md={12}>
              <Form.Group
                controlId={
                  'txStageRequiredTask_axType_' +
                  nextTxStageInfo.required_tasks[index]._id +
                  '_' +
                  index
                }
              >
                <Form.Label>Assessment Type</Form.Label>
                <Form.Control
                  as='select'
                  value={axType}
                  onChange={(e) => {
                    setAxType(e.target.value)
                    nextTxStageInfo.required_tasks[index].ax_type =
                      e.target.value
                  }}
                >
                  <option value=''>Select Type...</option>
                  <option value='questionnaire'>
                    Assessment that requires one-time response
                  </option>
                  <option value='no_submission_limit'>
                    Assessment that allows anytime and unlimited responses
                  </option>
                  <option value='diary'>
                    Diary assessment (i.e. assessment that expects to be
                    completed everyday
                  </option>
                </Form.Control>
              </Form.Group>
            </Col>

            {axType === 'questionnaire' && (
              <>
                <Col md={6}>
                  <Form.Group
                    controlId={
                      'txStageRequiredTask_questionnaire_' +
                      nextTxStageInfo.required_tasks[index]._id +
                      '_' +
                      index
                    }
                  >
                    <Form.Label>Questionnaire</Form.Label>
                    <Form.Control
                      as='select'
                      value={questionnaire}
                      onChange={(e) => {
                        setQuestionnaire(e.target.value)
                        nextTxStageInfo.required_tasks[index].questionnaire =
                          e.target.value
                      }}
                    >
                      <option value=''>Select Questionnaire...</option>
                      {qxList &&
                        qxList
                          .filter((qx) =>
                            getQuestionnaireList().find(
                              (x) => String(qx._id) === String(x)
                            )
                          )
                          .map((qx, qIndex) => (
                            <option
                              key={
                                'txStageRequiredTask_questionnaire_choices_' +
                                nextTxStageInfo.required_tasks[index]._id +
                                '_' +
                                index +
                                '_' +
                                qIndex
                              }
                              value={qx._id}
                            >
                              {qx.chin_name} ({qx.abbrev})
                            </option>
                          ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </>
            )}
            {axType === 'no_submission_limit' && (
              <>
                <Col md={6}>
                  <Form.Group
                    controlId={
                      'txStageRequiredTask_questionnaire_' +
                      nextTxStageInfo.required_tasks[index]._id +
                      '_' +
                      index
                    }
                  >
                    <Form.Label>Questionnaire</Form.Label>
                    <Form.Control
                      as='select'
                      value={questionnaire}
                      onChange={(e) => {
                        setQuestionnaire(e.target.value)
                        nextTxStageInfo.required_tasks[index].questionnaire =
                          e.target.value
                      }}
                    >
                      <option value=''>Select Questionnaire...</option>
                      {qxList &&
                        qxList
                          .filter((qx) =>
                            getNoSubmissionLimitList().find(
                              (x) => String(qx._id) === String(x)
                            )
                          )
                          .map((qx, qIndex) => (
                            <option
                              key={
                                'txStageRequiredTask_noSubmissionLimit_choices_' +
                                nextTxStageInfo.required_tasks[index]._id +
                                '_' +
                                index +
                                '_' +
                                qIndex
                              }
                              value={qx._id}
                            >
                              {qx.chin_name} ({qx.abbrev})
                            </option>
                          ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Label style={{ whiteSpace: 'normal' }}>
                    Required Number of Submissions
                  </Form.Label>
                  <Form.Control
                    type='number'
                    value={respTimes}
                    onChange={(e) => {
                      setRespTimes(e.target.value)
                      nextTxStageInfo.required_tasks[index].resp_times =
                        e.target.value
                    }}
                  ></Form.Control>
                </Col>
              </>
            )}
            {axType === 'diary' && (
              <>
                <Col md={12}>
                  <Form.Group
                    controlId={
                      'txStageRequiredTask_diaryAx_' +
                      nextTxStageInfo.required_tasks[index]._id +
                      '_' +
                      index
                    }
                  >
                    <Form.Label>Questionnaire</Form.Label>
                    <Form.Control
                      as='select'
                      value={questionnaire}
                      onChange={(e) => {
                        setQuestionnaire(e.target.value)
                        nextTxStageInfo.required_tasks[index].questionnaire =
                          e.target.value
                      }}
                    >
                      <option value=''>Select Questionnaire...</option>
                      {getDiaryQxList().map((diaryQx, dIndex) => (
                        <option
                          key={
                            'txStageRequiredTask_diaryAx_choices_' +
                            nextTxStageInfo.required_tasks[index]._id +
                            '_' +
                            index +
                            '_' +
                            dIndex
                          }
                          value={diaryQx._id}
                        >
                          {diaryQx.chin_name} ({diaryQx.abbrev})
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Label style={{ whiteSpace: 'normal' }}>
                    With Responses From Days Before
                  </Form.Label>
                  <Form.Control
                    type='number'
                    value={diaryCheckForDaysBefore}
                    onChange={(e) => {
                      setDiaryCheckForDaysBefore(e.target.value)
                      nextTxStageInfo.required_tasks[
                        index
                      ].diary_check_for_days_before = e.target.value
                    }}
                  ></Form.Control>
                </Col>
                <Col md={6}>
                  <Form.Label style={{ whiteSpace: 'normal' }}>
                    Required Number of Diary Submission
                  </Form.Label>
                  <Form.Control
                    type='number'
                    value={diaryTimes}
                    onChange={(e) => {
                      setDiaryTimes(e.target.value)
                      nextTxStageInfo.required_tasks[index].diary_times =
                        e.target.value
                    }}
                  ></Form.Control>
                </Col>
              </>
            )}
          </Row>
        )}

        <hr></hr>
        <Row>
          <Col>
            <Button
              className='mx-1 float-right btn-danger'
              onClick={onRequiredTaskDelete}
            >
              Delete
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default TxStageRequiredTask
