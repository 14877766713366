import {
  ASSIGN_PARTI_FAIL,
  ASSIGN_PARTI_REQUEST,
  ASSIGN_PARTI_RESET,
  ASSIGN_PARTI_SUCCESS,
  PARTI_LIST_FAIL,
  PARTI_LIST_REQUEST,
  PARTI_LIST_RESET,
  PARTI_LIST_SUCCESS,
  DOWNLOAD_TEXT_RESP_FAIL,
  DOWNLOAD_TEXT_RESP_REQUEST,
  DOWNLOAD_TEXT_RESP_RESET,
  DOWNLOAD_TEXT_RESP_SUCCESS,
  DOWNLOAD_RESP_TIME_REQUEST,
  DOWNLOAD_RESP_TIME_SUCCESS,
  DOWNLOAD_RESP_TIME_FAIL,
  DOWNLOAD_RESP_TIME_RESET,
  CREATE_PARTI_REQUEST,
  CREATE_PARTI_SUCCESS,
  CREATE_PARTI_FAIL,
  CREATE_PARTI_RESET,
  UPDATE_FITBIT_TOKEN_REQUEST,
  UPDATE_FITBIT_TOKEN_SUCCESS,
  UPDATE_FITBIT_TOKEN_FAIL,
  UPDATE_FITBIT_TOKEN_RESET,
  GET_FITBIT_SLEEP_LOG_REQUEST,
  GET_FITBIT_SLEEP_LOG_SUCCESS,
  GET_FITBIT_SLEEP_LOG_FAIL,
  GET_FITBIT_SLEEP_LOG_RESET,
} from '../constants/participantConstants'

// participantsData => { participants:[...], study:{...} }
export const partiListReducer = (state = { participantsData: {} }, action) => {
  switch (action.type) {
    case PARTI_LIST_REQUEST:
      return { loading: true, qx_list: [] }
    case PARTI_LIST_SUCCESS:
      return {
        loading: false,
        participantsData: action.payload,
        //pages: action.payload.pages,
        //page: action.payload.page,
        success: true,
      }
    case PARTI_LIST_FAIL:
      return { loading: false, error: action.payload }
    case PARTI_LIST_RESET:
      return { participantsData: {} }
    default:
      return state
  }
}

export const assignPartiReducer = (state = {}, action) => {
  switch (action.type) {
    case ASSIGN_PARTI_REQUEST:
      return { loading: true }
    case ASSIGN_PARTI_SUCCESS:
      return { loading: false, success: true, message: action.payload.message }
    case ASSIGN_PARTI_FAIL:
      return { loading: false, success: false, error: action.payload }
    case ASSIGN_PARTI_RESET:
      return {}
    default:
      return state
  }
}

export const downloadTextRespReducer = (state = {}, action) => {
  switch (action.type) {
    case DOWNLOAD_TEXT_RESP_REQUEST:
      return { loading: true }
    case DOWNLOAD_TEXT_RESP_SUCCESS:
      return { loading: false, success: true, message: action.payload.message }
    case DOWNLOAD_TEXT_RESP_FAIL:
      return { loading: false, success: false, error: action.payload }
    case DOWNLOAD_TEXT_RESP_RESET:
      return {}
    default:
      return state
  }
}

export const downloadRespTimeReducer = (state = {}, action) => {
  switch (action.type) {
    case DOWNLOAD_RESP_TIME_REQUEST:
      return { loading: true }
    case DOWNLOAD_RESP_TIME_SUCCESS:
      return { loading: false, success: true, message: action.payload.message }
    case DOWNLOAD_RESP_TIME_FAIL:
      return { loading: false, success: false, error: action.payload }
    case DOWNLOAD_RESP_TIME_RESET:
      return {}
    default:
      return state
  }
}

export const createPartiReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PARTI_REQUEST:
      return { loading: true }
    case CREATE_PARTI_SUCCESS:
      return { loading: false, success: true, message: action.payload.message }
    case CREATE_PARTI_FAIL:
      return { loading: false, success: false, error: action.payload }
    case CREATE_PARTI_RESET:
      return {}
    default:
      return state
  }
}

export const updateFitbitTokenReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_FITBIT_TOKEN_REQUEST:
      return { loading: true }
    case UPDATE_FITBIT_TOKEN_SUCCESS:
      return { loading: false, success: true, message: action.payload.message }
    case UPDATE_FITBIT_TOKEN_FAIL:
      return { loading: false, success: false, error: action.payload }
    case UPDATE_FITBIT_TOKEN_RESET:
      return {}
    default:
      return state
  }
}

export const getFitbitSleepLogReducer = (state = { sleepLog: {} }, action) => {
  switch (action.type) {
    case GET_FITBIT_SLEEP_LOG_REQUEST:
      return { loading: true }
    case GET_FITBIT_SLEEP_LOG_SUCCESS:
      let sleepLog
      if (
        action.payload.data ===
        'No Fitbit account is connected to this participant'
      ) {
        sleepLog = action.payload.data
      } else {
        const removeLevelsFieldSleepLog = action.payload.sleepLog.map(
          ({ levels, ...o }) => o
        )
        sleepLog = JSON.stringify(removeLevelsFieldSleepLog, null, 2)
      }
      return {
        loading: false,
        sleepLog,
        success: true,
      }
    case GET_FITBIT_SLEEP_LOG_FAIL:
      return { loading: false, success: false, error: action.payload }
    case GET_FITBIT_SLEEP_LOG_RESET:
      return {}
    default:
      return state
  }
}
