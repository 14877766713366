////////////////////////////////////////////////////////
//The onResponseTypeChange handler on this page demostrates how
//   child component's function can be flexibly pass to its parent
////////////////////////////////////////////////////////

import React, { useState, useEffect } from 'react'
import { Card, Form, Row, Col, Button } from 'react-bootstrap'
import Response from '../components/Response'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import QuillToolbar, {
  modules,
  formats,
  generateModules,
} from '../components/QuillToolbar'
import { set } from 'mongoose'
import Dependency from './Dependency'

//***********************************
//It is also a working code for directly updating fields of prop(s),
// while not affecting UI performance
//************************************

export const Question = ({
  qx,
  question,
  index,
  i,
  onAddPrev,
  onAddNext,
  onAddTextResponse,
  onQuestionDelete,
  onResponseDelete,
  onResponseTypeChange,
  onQuestionNumChange,
  deleteAvailable,
  mcList,
}) => {
  const [questionNum, setQuestionNum] = useState('')
  const [questionText, setQuestionText] = useState('')
  const [guideText, setGuideText] = useState('')
  const [isInstruction, setIsInstruction] = useState(false)

  const responseTypeChangeHandler = (originalType, updatedType, rIndex) => {
    if (
      (updatedType === 'instruction' || updatedType === 'media') &&
      rIndex === 0
    ) {
      setQuestionNum('')
      question.question_num = ''
      //Remove other response if chosen as instruction
      question.other_info = [question.other_info[0]]

      //It is required for re-rendering after type is updated
      setIsInstruction(true)
    } else {
      setIsInstruction(false)
    }

    setQuestionNum(question.question_num)
    onResponseTypeChange(originalType, updatedType, rIndex)
  }

  useEffect(() => {
    setQuestionNum(question.question_num)
    setQuestionText(question.html_question)
    setGuideText(question.guide)
    if (
      question.other_info[0].type === 'instruction' ||
      question.other_info[0].type === 'media'
    ) {
      setIsInstruction(true)
    }
    //console.log(questions[0])
  }, [question, questionText, guideText, isInstruction])

  return (
    <Card className='rounded my-3'>
      <Card.Body>
        <Form>
          <Row>
            <Col md={6}>
              {question.other_info[0].type !== 'instruction' &&
                question.other_info[0].type !== 'media' && (
                  <Form.Group controlId={'qnum_' + question._id}>
                    <Form.Label style={{ whiteSpace: 'normal' }}>
                      Question Number
                    </Form.Label>
                    <Form.Control
                      type='text'
                      value={questionNum}
                      onChange={(e) => {
                        setQuestionNum(e.target.value)
                        question.question_num = e.target.value
                        onQuestionNumChange()
                      }}
                      readOnly={question.other_info[0].type === 'instruction'}
                    ></Form.Control>
                  </Form.Group>
                )}
            </Col>
            <Col md={6}>
              <Dependency
                i={i}
                onDependencyUpdate={(dependency) => {
                  question.dependency = dependency
                }}
                qx={qx}
              />
            </Col>
          </Row>
          {question.other_info[0].type !== 'media' && (
            <Row>
              <Col>
                <Form.Group controlId={'qtext_' + question._id}>
                  <Form.Label>
                    {question.other_info[0].type !== 'instruction'
                      ? 'Question Text'
                      : 'Instruction Text'}
                  </Form.Label>
                  <div className={'text-editor'}>
                    <QuillToolbar toolbarID={'toolbar' + index} />
                    <ReactQuill
                      value={questionText}
                      onChange={(html) => {
                        setQuestionText(html)
                        question.html_question = html
                      }}
                      modules={generateModules('toolbar' + index)}
                      formats={formats}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
          )}

          {question.other_info[0].type !== 'instruction' &&
            question.other_info[0].type !== 'media' && (
              <Row>
                <Col>
                  <Form.Group controlId={'gtext_' + question._id}>
                    <Form.Label>Guide (Optional)</Form.Label>
                    <Form.Control
                      as='textarea'
                      value={guideText}
                      onChange={(e) => {
                        question.guide = e.target.value
                        setGuideText(question.guide)
                      }}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            )}
          <Row>
            <Col>
              <Form>
                <Form.Group controlId={'qresp_' + question._id}>
                  <Form.Label style={{ whiteSpace: 'normal' }}>
                    Instruction/Response Settings
                  </Form.Label>
                  {question.other_info.map((response, index) => {
                    return (
                      <Response
                        qx={qx}
                        qIndex={question.position - 1}
                        rIndex={index}
                        response={response}
                        key={response._id}
                        onResponseDelete={() => onResponseDelete(index)}
                        onTypeChange={(originalType, updatedType) =>
                          responseTypeChangeHandler(
                            originalType,
                            updatedType,
                            index
                          )
                        }
                        deleteAvailable={index !== 0}
                        mcList={mcList}
                      ></Response>
                    )
                  })}
                  {question.other_info[0].type !== 'instruction' && (
                    <Card className='p-2 rounded'>
                      <Button onClick={onAddTextResponse}>
                        Add Text Response
                      </Button>
                    </Card>
                  )}
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button className='mx-1' onClick={onAddPrev}>
                Add Question/Instruction Before
              </Button>
              <Button className='mx-1' onClick={onAddNext}>
                Add Question/Instruction After
              </Button>
              {deleteAvailable && (
                <Button
                  className='mx-1 float-right btn-danger'
                  onClick={onQuestionDelete}
                >
                  Delete{' '}
                  {question.other_info[0].type !== 'instruction'
                    ? 'Question'
                    : 'Instruction'}
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  )
}

export default Question
