import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import getSubdomain from '../getSubdomain'
import findSiteInfoBySubdomain from '../findSiteInfoBySubdomain'

const Footer = () => {
  const theme = findSiteInfoBySubdomain(getSubdomain(window)).theme

  return (
    <Container
      fluid
      className='no-gutters mx-0'
      style={{
        backgroundColor: findSiteInfoBySubdomain(getSubdomain(window))
          .primaryColor,
      }}
    >
      <footer>
        <Row>
          <Col md={6}></Col>
          <Col md={6} className='text-right p-4'>
            <a
              href={findSiteInfoBySubdomain(getSubdomain(window)).termsOfUseUrl}
              target='_blank'
              rel='noreferrer'
              style={{
                fontSize: '13px',
                color: theme === 'dark' ? 'white' : 'black',
              }}
              className='pr-3'
            >
              Terms of Use
            </a>

            <a
              href={findSiteInfoBySubdomain(getSubdomain(window)).privacyUrl}
              target='_blank'
              rel='noreferrer'
              style={{
                fontSize: '13px',
                color: theme === 'dark' ? 'white' : 'black',
              }}
              className='pr-5'
            >
              Privacy Policy
            </a>
            <span
              style={{
                fontSize: '13px',
                color: theme === 'dark' ? 'white' : 'black',
              }}
              className='pr-3'
            >
              Powered by Longitudinax
            </span>
          </Col>
        </Row>
      </footer>
    </Container>
  )
}

export default Footer
