import axios from 'axios'
import { USER_LOGOUT } from '../../admin_module/constants/userConstants'
import {
  GET_ALL_ACCESS_REQUEST,
  GET_ALL_ACCESS_SUCCESS,
  GET_ALL_ACCESS_FAIL,
} from '../constants/accessConstants'

import BACKEND_URL from '../../backendUrl'

// get all access of current user
export const getAllAccess = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ALL_ACCESS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      BACKEND_URL + `/api/access/get_all_access`,
      config
    )

    dispatch({
      type: GET_ALL_ACCESS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: USER_LOGOUT,
      })
      localStorage.removeItem('adminModuleUserInfo')
    } else {
      dispatch({
        type: GET_ALL_ACCESS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
}
