import React, { useState, useEffect } from 'react'
import { Card, Form, Row, Col, Button } from 'react-bootstrap'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import QuillToolbar, { formats, generateModules } from './QuillToolbar'

export const TxPageComponent = ({
  qxList,
  txPageList,
  customPageComponentList,
  txPage,
  index,
  onComponentDelete,
  deleteAvailable,
  onAddPrev,
  onAddNext,
  studyLock,
}) => {
  const [pageComponentType, setPageComponentType] = useState('')

  // For custom_develop type
  const [customPageComponent, setCustomPageComponent] = useState('')
  const [isTaskToCheck, setIsTaskToCheck] = useState(false)
  const [customTaskIdentifier, setCustomTaskIdentifier] = useState('')
  // For text type
  const [textHtmlContent, setTextHtmlContent] = useState('')
  const [textInsideBlock, setTextInsideBlock] = useState(false)
  const [textBlockColorHex, setTextBlockColorHex] = useState('#')
  // For button type
  const [buttonName, setButtonName] = useState('')
  const [buttonLinkType, setButtonLinkType] = useState('')
  const [buttonLinkPage, setButtonLinkPage] = useState('')
  const [buttonLinkQxType, setButtonLinkQxType] = useState('')
  const [buttonLinkSelectedQuestionnaire, setButtonLinkSelectedQuestionnaire] =
    useState('')

  // For video type
  const [videoPreviewFilenameToBeSent, setVideoPreviewFilenameToBeSent] =
    useState('')
  //const [videoPreviewLocalFilename, setVideoPreviewLocalFilename] = useState('') <-- for system owner edit only
  //const [videoPreviewS3Path, setVideoPreviewS3Path] = useState('') <-- for system owner edit only
  const [videoFilenameToBeSent, setVideoFilenameToBeSent] = useState('')
  //const [videoLocalFilename, setVideoLocalFilename] = useState('') <-- for system owner edit only
  //const [videoS3Path, setVideoS3Path] = useState('') <-- for system owner edit only
  // For image type
  const [imageFilenameToBeSent, setImageFilenameToBeSent] = useState('')
  const [imageLeftAndRightPadding, setImageLeftAndRightPadding] =
    useState(false)
  //const [imageLocalFilename, setImageLocalFilename] = useState('') <-- for system owner edit only
  //const [imageS3Path, setImageS3Path] = useState('') <-- for system owner edit only
  // For audio type
  const [audioFilenameToBeSent, setAudioFilenameToBeSent] = useState('')
  //const [audioLocalFilename, setAudioLocalFilename] = useState('') <-- for system owner edit only
  //const [audioS3Path, setAudioS3Path] = useState('') <-- for system owner edit only

  useEffect(() => {
    setPageComponentType(txPage.page_components[index].type)
    // For custom_develop type
    if (txPage.page_components[index].custom_page_component)
      setCustomPageComponent(
        txPage.page_components[index].custom_page_component
      )
    if (txPage.page_components[index].is_task_to_check)
      setIsTaskToCheck(txPage.page_components[index].is_task_to_check)
    if (txPage.page_components[index].custom_task_identifier)
      setCustomTaskIdentifier(
        txPage.page_components[index].custom_task_identifier
      )
    // For text type
    if (txPage.page_components[index].text_html_content)
      setTextHtmlContent(txPage.page_components[index].text_html_content)
    if (txPage.page_components[index].text_inside_block) {
      setTextInsideBlock(txPage.page_components[index].text_inside_block)
      if (txPage.page_components[index].text_inside_block)
        setTextBlockColorHex(txPage.page_components[index].text_block_color_hex)
    }
    // For button type
    if (txPage.page_components[index].button_name)
      setButtonName(txPage.page_components[index].button_name)
    if (txPage.page_components[index].button_link_type)
      setButtonLinkType(txPage.page_components[index].button_link_type)
    if (txPage.page_components[index].button_link_page)
      setButtonLinkPage(txPage.page_components[index].button_link_page)
    if (txPage.page_components[index].button_link_qx_type) {
      setButtonLinkQxType(txPage.page_components[index].button_link_qx_type)
      if (txPage.page_components[index].button_link_qx_type === 'questionnaire')
        setButtonLinkSelectedQuestionnaire(
          txPage.page_components[index].button_link_questionnaire
        )
      if (
        txPage.page_components[index].button_link_qx_type ===
        'no_submission_limit'
      )
        setButtonLinkSelectedQuestionnaire(
          txPage.page_components[index].button_link_no_submission_limit
        )
      if (txPage.page_components[index].button_link_qx_type === 'diary')
        setButtonLinkSelectedQuestionnaire(
          txPage.page_components[index].button_link_diary
        )
    }
    // For video type
    if (txPage.page_components[index].video_preview_filename_to_be_sent)
      setVideoPreviewFilenameToBeSent(
        txPage.page_components[index].video_preview_filename_to_be_sent
      )
    /*
    if (txPage.page_components[index].video_preview_local_filename)
      setVideoPreviewLocalFilename(txPage.page_components[index].video_preview_local_filename)
    if (txPage.page_components[index].video_preview_s3_path)
      setVideoPreviewS3Path(txPage.page_components[index].video_preview_s3_path)
    */
    if (txPage.page_components[index].video_filename_to_be_sent)
      setVideoFilenameToBeSent(
        txPage.page_components[index].video_filename_to_be_sent
      )
    /*
    if (txPage.page_components[index].video_local_filename)
      setVideoLocalFilename(txPage.page_components[index].video_local_filename)
    if (txPage.page_components[index].video_s3_path)
      setVideoS3Path(txPage.page_components[index].video_s3_path)
    */
    // For image type
    if (txPage.page_components[index].img_filename_to_be_sent)
      setImageFilenameToBeSent(
        txPage.page_components[index].img_filename_to_be_sent
      )
    if (txPage.page_components[index].img_left_and_right_padding)
      setImageLeftAndRightPadding(
        txPage.page_components[index].img_left_and_right_padding
      )
    /*
    if (txPage.page_components[index].image_local_filename)
      setImageLocalFilename(txPage.page_components[index].image_local_filename)
    if (txPage.page_components[index].image_s3_path)
      setImageS3Path(txPage.page_components[index].image_s3_path)
    */
    // For audio type
    if (txPage.page_components[index].audio_filename_to_be_sent)
      setAudioFilenameToBeSent(
        txPage.page_components[index].audio_filename_to_be_sent
      )
    /*
    if (txPage.page_components[index].audio_local_filename)
      setAudioLocalFilename(txPage.page_components[index].audio_local_filename)
    if (txPage.page_components[index].audio_s3_path)
      setAudioS3Path(txPage.page_components[index].audio_s3_path)
    */
  }, [txPage, index])

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={12}>
            <Form.Group
              controlId={
                'txPageComponent_' +
                txPage.page_components[index]._id +
                '_' +
                index
              }
            >
              <Form.Label>Page Component Type</Form.Label>
              <Form.Control
                disabled={studyLock}
                as='select'
                value={pageComponentType}
                onChange={(e) => {
                  setPageComponentType(e.target.value)
                  txPage.page_components[index].type = e.target.value
                }}
              >
                <option value=''>Select Type...</option>
                <option value='text'>Text Paragraph(s)</option>
                <option value='button'>
                  Button (for linking to another page or an assessment)
                </option>
                <option value='video'>Video</option>
                <option value='image'>Image</option>
                <option value='audio'>Audio</option>
                <option value='custom_develop'>
                  Custom Development (i.e. component which requires tailor-made
                  design and development)
                </option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              {txPage.page_components[index].type === 'text' && (
                <Col>
                  <Form.Group
                    controlId={
                      'txPageComponent_text_' +
                      txPage.page_components[index]._id +
                      '_' +
                      index
                    }
                  >
                    <Form.Label>Text Content</Form.Label>
                    <div className={'text-editor'}>
                      <QuillToolbar
                        toolbarID={'txPageComponent_text_toolbar_' + index}
                      />
                      <ReactQuill
                        value={textHtmlContent}
                        onChange={(html) => {
                          setTextHtmlContent(html)
                          txPage.page_components[index].text_html_content = html
                        }}
                        modules={generateModules(
                          'txPageComponent_text_toolbar_' + index
                        )}
                        formats={formats}
                      />
                    </div>
                  </Form.Group>
                  <Row>
                    <Col md={6}>
                      <Form.Group
                        controlId={
                          'txPageComponent_text_inside_block_' +
                          txPage.page_components[index]._id +
                          '_' +
                          index
                        }
                      >
                        <Form.Check
                          type='checkbox'
                          label='Wrap by block'
                          checked={textInsideBlock}
                          onChange={(e) => {
                            setTextInsideBlock(e.target.checked)
                            txPage.page_components[index].text_inside_block =
                              e.target.checked
                          }}
                        />
                      </Form.Group>
                    </Col>
                    {txPage.page_components[index].text_inside_block && (
                      <Col md={6}>
                        <Form.Group
                          controlId={
                            'txPageComponent_text_inside_block_' +
                            txPage.page_components[index]._id +
                            '_' +
                            index
                          }
                        >
                          <Form.Label>Block Color</Form.Label>
                          <Form.Control
                            type='text'
                            value={textBlockColorHex}
                            onChange={(e) => {
                              setTextBlockColorHex(e.target.value)
                              txPage.page_components[
                                index
                              ].text_block_color_hex = e.target.value
                            }}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    )}
                  </Row>
                </Col>
              )}
              {txPage.page_components[index].type === 'button' && (
                <>
                  <Col md={6}>
                    <Form.Group
                      controlId={
                        'txPageComponent_button_text_' +
                        txPage.page_components[index]._id +
                        '_' +
                        index
                      }
                    >
                      <Form.Label>Button Text</Form.Label>
                      <Form.Control
                        type='text'
                        value={buttonName}
                        onChange={(e) => {
                          setButtonName(e.target.value)
                          txPage.page_components[index].button_name =
                            e.target.value
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group
                      controlId={
                        'txPageComponent_button_type_' +
                        txPage.page_components[index]._id +
                        '_' +
                        index
                      }
                    >
                      <Form.Label>Button Type</Form.Label>
                      <Form.Control
                        disabled={studyLock}
                        as='select'
                        value={buttonLinkType}
                        onChange={(e) => {
                          setButtonLinkType(e.target.value)
                          txPage.page_components[index].button_link_type =
                            e.target.value
                        }}
                      >
                        <option value=''>Select Type...</option>
                        <option value='page'>Linking to a page</option>
                        <option value='qx'>Linking to an assessment</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  {txPage.page_components[index].button_link_type ===
                    'page' && (
                    <>
                      <Col md={12}>
                        <p style={{ color: 'red' }}>
                          Note: This button will only be appeared when the
                          linked page is also included in the same study group
                          treatment package
                        </p>
                      </Col>
                      <Col md={12}>
                        <Form.Group
                          controlId={
                            'txPageComponent_button_link_page_' +
                            txPage.page_components[index]._id +
                            '_' +
                            index
                          }
                        >
                          <Form.Label>Linking To</Form.Label>
                          <Form.Control
                            disabled={studyLock}
                            as='select'
                            value={buttonLinkPage}
                            onChange={(e) => {
                              setButtonLinkPage(e.target.value)
                              txPage.page_components[index].button_link_page =
                                e.target.value
                            }}
                          >
                            <option value=''>Select Page...</option>
                            {txPageList
                              .filter((x) => x._id !== txPage._id)
                              .map((page, pIndex) => (
                                <option
                                  key={
                                    'txPageComponent_button_link_page_choices_' +
                                    txPage.page_components[index]._id +
                                    '_' +
                                    index +
                                    '_' +
                                    pIndex
                                  }
                                  value={page._id}
                                >
                                  {page.identifier}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </>
                  )}
                  {txPage.page_components[index].button_link_type === 'qx' && (
                    <>
                      <Col md={12}>
                        <Form.Group
                          controlId={
                            'txPageComponent_button_link_qx_type_' +
                            txPage.page_components[index]._id +
                            '_' +
                            index
                          }
                        >
                          <Form.Label>Linked assessment Type</Form.Label>
                          <Form.Control
                            disabled={studyLock}
                            as='select'
                            value={buttonLinkQxType}
                            onChange={(e) => {
                              setButtonLinkQxType(e.target.value)
                              txPage.page_components[
                                index
                              ].button_link_qx_type = e.target.value
                              setButtonLinkSelectedQuestionnaire('')
                              txPage.page_components[
                                index
                              ].button_link_questionnaire = undefined
                              txPage.page_components[
                                index
                              ].button_link_no_submission_limit = undefined
                              txPage.page_components[index].button_link_diary =
                                undefined
                            }}
                          >
                            <option value=''>Select Assessment Type...</option>
                            <option value='questionnaire'>
                              Assessment that requires one-time response
                            </option>
                            <option value='no_submission_limit'>
                              Assessment that allows anytime and unlimited
                              responses
                            </option>
                            <option value='diary'>
                              Diary assessment (i.e. assessment that expects to
                              be completed everyday
                            </option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      {buttonLinkQxType.length > 0 && (
                        <Col md={12}>
                          <Form.Group
                            controlId={
                              'txPageComponent_button_link_questionnaire_' +
                              txPage.page_components[index]._id +
                              '_' +
                              index
                            }
                          >
                            <Form.Label>Linked Questionnaire</Form.Label>
                            <Form.Control
                              disabled={studyLock}
                              as='select'
                              value={buttonLinkSelectedQuestionnaire}
                              onChange={(e) => {
                                setButtonLinkSelectedQuestionnaire(
                                  e.target.value
                                )
                                txPage.page_components[
                                  index
                                ].button_link_questionnaire =
                                  buttonLinkQxType === 'questionnaire'
                                    ? e.target.value
                                    : undefined
                                txPage.page_components[
                                  index
                                ].button_link_no_submission_limit =
                                  buttonLinkQxType === 'no_submission_limit'
                                    ? e.target.value
                                    : undefined
                                txPage.page_components[
                                  index
                                ].button_link_diary =
                                  buttonLinkQxType === 'diary'
                                    ? e.target.value
                                    : undefined
                              }}
                            >
                              <option value=''>Select Questionnaire...</option>
                              {qxList.map((qx, qIndex) => (
                                <option
                                  key={
                                    'txPageComponent_button_link_questionnaire_choices_' +
                                    txPage.page_components[index]._id +
                                    '_' +
                                    index +
                                    '_' +
                                    qIndex
                                  }
                                  value={qx._id}
                                >
                                  {qx.chin_name} ({qx.abbrev})
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      )}
                    </>
                  )}
                </>
              )}
              {txPage.page_components[index].type === 'video' && (
                <>
                  <Col md={6}>
                    <Form.Group
                      controlId={
                        'txPageComponent_video_preview_filename_tbs_' +
                        txPage.page_components[index]._id +
                        '_' +
                        index
                      }
                    >
                      <Form.Label>
                        File name of preview image (to be sent to the developer)
                      </Form.Label>
                      <Form.Control
                        type='text'
                        value={videoPreviewFilenameToBeSent}
                        onChange={(e) => {
                          setVideoPreviewFilenameToBeSent(e.target.value)
                          txPage.page_components[
                            index
                          ].video_preview_filename_to_be_sent = e.target.value
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group
                      controlId={
                        'txPageComponent_video_filename_tbs_' +
                        txPage.page_components[index]._id +
                        '_' +
                        index
                      }
                    >
                      <Form.Label>
                        File name of video (to be sent to the developer)
                      </Form.Label>
                      <Form.Control
                        type='text'
                        value={videoFilenameToBeSent}
                        onChange={(e) => {
                          setVideoFilenameToBeSent(e.target.value)
                          txPage.page_components[
                            index
                          ].video_filename_to_be_sent = e.target.value
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <p style={{ fontSize: '12px', color: 'blue' }}>
                      Note: This is for developer to know which file to be
                      mapped with this component only. Changing the filename
                      here without informing the developer about the change will
                      not get the page updated.
                    </p>
                  </Col>
                </>
              )}
              {txPage.page_components[index].type === 'image' && (
                <>
                  <Col md={12}>
                    <Form.Group
                      controlId={
                        'txPageComponent_image_padding_' +
                        txPage.page_components[index]._id +
                        '_' +
                        index
                      }
                    >
                      <Form.Check
                        type='checkbox'
                        label='Left and right padding'
                        checked={imageLeftAndRightPadding}
                        onChange={(e) => {
                          setImageLeftAndRightPadding(e.target.checked)
                          txPage.page_components[
                            index
                          ].img_left_and_right_padding = e.target.checked
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group
                      controlId={
                        'txPageComponent_image_filename_tbs_' +
                        txPage.page_components[index]._id +
                        '_' +
                        index
                      }
                    >
                      <Form.Label>
                        Name of file (to be sent to the developer)
                      </Form.Label>
                      <Form.Control
                        type='text'
                        value={imageFilenameToBeSent}
                        onChange={(e) => {
                          setImageFilenameToBeSent(e.target.value)
                          txPage.page_components[
                            index
                          ].img_filename_to_be_sent = e.target.value
                        }}
                      ></Form.Control>
                    </Form.Group>
                    <p style={{ fontSize: '12px', color: 'blue' }}>
                      Note: This is for developer to know which file to be
                      mapped with this component only. Changing the filename
                      here without informing the developer about the change will
                      not get the page updated.
                    </p>
                  </Col>
                </>
              )}
              {txPage.page_components[index].type === 'audio' && (
                <>
                  <Col md={12}>
                    <Form.Group
                      controlId={
                        'txPageComponent_audio_filename_tbs_' +
                        txPage.page_components[index]._id +
                        '_' +
                        index
                      }
                    >
                      <Form.Label>
                        Name of file (to be sent to the developer)
                      </Form.Label>
                      <Form.Control
                        type='text'
                        value={audioFilenameToBeSent}
                        onChange={(e) => {
                          setAudioFilenameToBeSent(e.target.value)
                          txPage.page_components[
                            index
                          ].audio_filename_to_be_sent = e.target.value
                        }}
                      ></Form.Control>
                    </Form.Group>
                    <p style={{ fontSize: '12px', color: 'blue' }}>
                      Note: This is for developer to know which file to be
                      mapped with this component only. Changing the filename
                      here without informing the developer about the change will
                      not get the page updated.
                    </p>
                  </Col>
                </>
              )}
              {txPage.page_components[index].type === 'custom_develop' && (
                <>
                  <Col md={12}>
                    <Form.Group
                      controlId={
                        'txPageComponent_custom_page_component_' +
                        txPage.page_components[index]._id +
                        '_' +
                        index
                      }
                    >
                      <Form.Label>Custom Developed Page Component</Form.Label>
                      <Form.Control
                        disabled={studyLock}
                        as='select'
                        value={customPageComponent}
                        onChange={(e) => {
                          setCustomPageComponent(e.target.value)
                          txPage.page_components[index].custom_page_component =
                            e.target.value
                        }}
                      >
                        <option value=''>Select Custom Component...</option>

                        {customPageComponentList.map((component, cIndex) => (
                          <option
                            key={
                              'txPageComponent_custom_page_component_choices_' +
                              txPage.page_components[index]._id +
                              '_' +
                              index +
                              '_' +
                              cIndex
                            }
                            value={component._id}
                          >
                            {component.custom_develop_identifier}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <p style={{ fontSize: '12px', color: 'blue' }}>
                      Note: If you do not find the page component from the
                      dropdown list, please add a new one by clicking 'Create or
                      Modify Description for Custom Page Components' below for
                      developers to follow-up and implement the new componenet.
                    </p>
                  </Col>
                  <Col md={12}>
                    <Form.Group
                      controlId={
                        'txPageComponent_is_task_to_check_' +
                        txPage.page_components[index]._id +
                        '_' +
                        index
                      }
                    >
                      <Form.Check
                        disabled={studyLock}
                        type='checkbox'
                        label='is a required task for checking treatment completion before assigning to the next treatmnet'
                        checked={isTaskToCheck}
                        onChange={(e) => {
                          setIsTaskToCheck(e.target.checked)
                          txPage.page_components[index].is_task_to_check =
                            e.target.checked
                          if (!e.target.checked) {
                            setCustomTaskIdentifier('')
                            txPage.page_components[
                              index
                            ].custom_task_identifier = ''
                          }
                        }}
                      />
                    </Form.Group>
                  </Col>
                  {isTaskToCheck && (
                    <Col md={12}>
                      <Form.Group
                        controlId={
                          'txPageComponent_custom_task_identifier_' +
                          txPage.page_components[index]._id +
                          '_' +
                          index
                        }
                      >
                        <Form.Label>
                          Task Identifier (which will be appeared as a selection
                          choice when setting up treatment assginment)
                        </Form.Label>
                        <Form.Control
                          disabled={studyLock}
                          type='text'
                          value={customTaskIdentifier}
                          onChange={(e) => {
                            setCustomTaskIdentifier(e.target.value)
                            txPage.page_components[
                              index
                            ].custom_task_identifier = e.target.value
                          }}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  )}
                </>
              )}
            </Row>
          </Col>
        </Row>
        {!studyLock && (
          <>
            <hr></hr>
            <Row>
              <Col>
                <Button className='mx-1' onClick={onAddPrev}>
                  Add Component Before
                </Button>
                <Button className='mx-1' onClick={onAddNext}>
                  Add Component After
                </Button>
                {deleteAvailable && (
                  <Button
                    className='mx-1 float-right btn-danger'
                    onClick={onComponentDelete}
                  >
                    Delete Component
                  </Button>
                )}
              </Col>
            </Row>
          </>
        )}
      </Card.Body>
    </Card>
  )
}

export default TxPageComponent
