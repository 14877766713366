import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { labManagerRegisterUserAction } from '../actions/userActions'

import hasValidLoginCredential from '../utils/hasValidLoginCredential'

// This is used for lab manager to create admin user for his/her lab
// For creation of lab manager, please register with an admin
const LabManagerRegisterAdminScreen = ({ location, history, match }) => {
  const labId = match.params.labId

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const labManagerRegisterUser = useSelector(
    (state) => state.labManagerRegisterUser
  )
  const { loading, error, registerResp } = labManagerRegisterUser

  useEffect(() => {
    // if the lab manager have not logged in or the token expires, the redirect to the login page
    if (
      !hasValidLoginCredential(userInfo, logout, window) ||
      !userInfo.isLabManager
    ) {
      history.push('/login')
    } else {
      if (registerResp) {
        if (registerResp.msg === 'Registration Success!') {
          history.push(`/lm/get_users`)
        }
      }
    }
  }, [history, userInfo, registerResp, logout])

  const submitHandler = (e) => {
    e.preventDefault()
    // dispatch register
    if (password !== confirmPassword) {
      setMessage('Passwords do not match')
    } else {
      dispatch(labManagerRegisterUserAction(email, password))
    }
  }

  return (
    <FormContainer>
      <h1 className='py-3'>Sign Up</h1>
      {error && <Message variant='danger'>{error}</Message>}
      {message && <Message variant='danger'>{message}</Message>}
      {loading && <Loader />}

      <Form onSubmit={submitHandler}>
        <Form.Group controlId='email'>
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type='email'
            placeholder='Enter email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='password'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Enter password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='confirmPassword'>
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Confirm Password '
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          ></Form.Control>
        </Form.Group>

        <Button type='submit' variant='primary'>
          Register
        </Button>
      </Form>
    </FormContainer>
  )
}

export default LabManagerRegisterAdminScreen
