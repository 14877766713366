import axios from 'axios'
import {
  SYSTEM_OWNER_DELETE_USER_FAIL,
  SYSTEM_OWNER_DELETE_USER_REQUEST,
  SYSTEM_OWNER_DELETE_USER_SUCCESS,
  SYSTEM_OWNER_GET_USER_DETAIL_FAIL,
  SYSTEM_OWNER_GET_USER_DETAIL_REQUEST,
  SYSTEM_OWNER_GET_USER_DETAIL_SUCCESS,
  SYSTEM_OWNER_LOGIN_FAIL,
  SYSTEM_OWNER_LOGIN_REQUEST,
  SYSTEM_OWNER_LOGIN_SUCCESS,
  SYSTEM_OWNER_LOGOUT,
  SYSTEM_OWNER_UPDATE_USER_DETAIL_FAIL,
  SYSTEM_OWNER_UPDATE_USER_DETAIL_REQUEST,
  SYSTEM_OWNER_UPDATE_USER_DETAIL_SUCCESS,
  SYSTEM_OWNER_REGISTER_ADMIN_FAIL,
  SYSTEM_OWNER_REGISTER_ADMIN_REQUEST,
  SYSTEM_OWNER_REGISTER_ADMIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  LAB_MANAGER_GET_LABS_USERS_REQUEST,
  LAB_MANAGER_GET_LABS_USERS_SUCCESS,
  LAB_MANAGER_GET_LABS_USERS_FAIL,
  LAB_MANAGER_REGISTER_USER_REQUEST,
  LAB_MANAGER_REGISTER_USER_SUCCESS,
  LAB_MANAGER_REGISTER_USER_FAIL,
} from '../constants/userConstants'

// For removing cache status after logout
import { STUDY_LIST_RESET } from '../../study_module/constants/studyConstants'

import BACKEND_URL from '../../backendUrl'

/******************* actions for users ********************/

export const login = (email, password, organization) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    })

    //Following demonstrate how to create data with axios in front-end
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await axios.post(
      BACKEND_URL + '/api/users/login',
      {
        email,
        password,
        organization,
      },
      config
    )

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    })

    localStorage.setItem('adminModuleUserInfo', JSON.stringify(data))
  } catch (error) {
    // In the custom express error handler, there could be custom error message,
    //  which is stored in error.response as { message: ..., stack: ... }
    // If there is no such custom error message, output the generic error message (i.e. error.message)
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// user register (add "Action" to prevent variable name repetition in the store.js )
export const labManagerRegisterUserAction =
  (email, password, lab) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LAB_MANAGER_REGISTER_USER_REQUEST,
      })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        BACKEND_URL + '/api/users/lm/register',
        {
          email,
          password,
          lab,
        },
        config
      )

      dispatch({
        type: LAB_MANAGER_REGISTER_USER_SUCCESS,
        payload: data,
      })

      localStorage.setItem('adminModuleUserInfo', JSON.stringify(data))
    } catch (error) {
      dispatch({
        type: LAB_MANAGER_REGISTER_USER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const labManagerGetUsers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: LAB_MANAGER_GET_LABS_USERS_REQUEST,
    })

    // getState() will return the whole redux state.
    // Check store.js combineReducer to see the key of the target state.
    const {
      userLogin: { userInfo },
    } = getState()

    //Following demostrate how to create data with axios in front-end
    //Authorization does not need to be quoted
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      BACKEND_URL + `/api/users/lm/getUsers`,
      config
    )

    dispatch({
      type: LAB_MANAGER_GET_LABS_USERS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: USER_LOGOUT,
      })
      localStorage.removeItem('adminModuleUserInfo')
    } else {
      dispatch({
        type: LAB_MANAGER_GET_LABS_USERS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
}

/******************* actions for system owner ****************/

// log in (add "Action" to prevent variable name repetition in the store.js ) (system owner)
export const systemOwnerLoginAction = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: SYSTEM_OWNER_LOGIN_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await axios.post(
      BACKEND_URL + '/api/users/systemowner/login',
      {
        email,
        password,
      },
      config
    )

    dispatch({
      type: SYSTEM_OWNER_LOGIN_SUCCESS,
      payload: data,
    })

    localStorage.setItem('adminModuleSystemOwnerInfo', JSON.stringify(data))
  } catch (error) {
    // In the custom express error handler, there could be custom error message,
    //  which is stored in error.response as { message: ..., stack: ... }
    // If there is no such custom error message, output the generic error message (i.e. error.message)
    dispatch({
      type: SYSTEM_OWNER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// delete users (system owner)
export const systemOwnerDeleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SYSTEM_OWNER_DELETE_USER_REQUEST,
    })

    const {
      systemOwnerLogin: { systemOwnerInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${systemOwnerInfo.token}`,
      },
    }

    await axios.delete(
      BACKEND_URL + `/api/users/systemowner/users/${id}`,
      config
    )

    dispatch({
      type: SYSTEM_OWNER_DELETE_USER_SUCCESS,
    })
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: SYSTEM_OWNER_LOGOUT,
      })
      localStorage.removeItem('adminModuleSystemOwnerInfo')
    } else {
      dispatch({
        type: SYSTEM_OWNER_DELETE_USER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
}

// get user details (system owner)
export const systemOwnerGetUserDetail = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SYSTEM_OWNER_GET_USER_DETAIL_REQUEST,
    })

    const {
      systemOwnerLogin: { systemOwnerInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${systemOwnerInfo.token}`,
      },
    }

    const { data } = await axios.get(
      BACKEND_URL + `/api/users/systemowner/users/${id}`,
      config
    )

    dispatch({
      type: SYSTEM_OWNER_GET_USER_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: SYSTEM_OWNER_LOGOUT,
      })
      localStorage.removeItem('adminModuleSystemOwnerInfo')
    } else {
      dispatch({
        type: SYSTEM_OWNER_GET_USER_DETAIL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
}

// update user details (system owner)
export const systemOwnerUpdateUserDetail =
  (user) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SYSTEM_OWNER_UPDATE_USER_DETAIL_REQUEST,
      })

      const {
        systemOwnerLogin: { systemOwnerInfo },
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${systemOwnerInfo.token}`,
        },
      }

      await axios.put(
        BACKEND_URL + `/api/users/systemowner/users/${user._id}`,
        user,
        config
      )

      dispatch({
        type: SYSTEM_OWNER_UPDATE_USER_DETAIL_SUCCESS,
      })
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({
          type: SYSTEM_OWNER_LOGOUT,
        })
        localStorage.removeItem('adminModuleSystemOwnerInfo')
      } else {
        dispatch({
          type: SYSTEM_OWNER_UPDATE_USER_DETAIL_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      }
    }
  }

// create admin (system owner)
export const systemOwnerRegisterAdmin =
  (email, password, isLabManager, labId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SYSTEM_OWNER_REGISTER_ADMIN_REQUEST,
      })

      const {
        systemOwnerLogin: { systemOwnerInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${systemOwnerInfo.token}`,
        },
      }

      const { data } = await axios.post(
        BACKEND_URL + `/api/users/systemOwner/register/${labId}`,
        {
          email,
          password,
          isLabManager,
        },
        config
      )

      dispatch({
        type: SYSTEM_OWNER_REGISTER_ADMIN_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: SYSTEM_OWNER_REGISTER_ADMIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const logout = () => (dispatch) => {
  dispatch({ type: STUDY_LIST_RESET })

  dispatch({
    type: USER_LOGOUT,
  })

  dispatch({
    type: SYSTEM_OWNER_LOGOUT,
  })

  localStorage.clear()
}
