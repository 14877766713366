// This page is for lab component

import React from 'react'
import Organizations from '../../enums/organizations'
import { Card, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value)
}

// define the height of each card
const getStyle = {
  height: '10rem',
}

const Lab = ({ lab }) => {
  return (
    <Card className='my-3 mx-2 rounded' style={getStyle}>
      <Card.Body>
        <Card.Title>{lab.name}</Card.Title>
        <Card.Subtitle>
          {getKeyByValue(Organizations, lab.organization)}
        </Card.Subtitle>
      </Card.Body>

      <Card.Footer>
        <LinkContainer to={`/systemowner/lab/${lab._id}`}>
          <Button
            variant='light'
            className=' btn bg-primary text-white float-right'
          >
            Details
          </Button>
        </LinkContainer>
      </Card.Footer>
    </Card>
  )
}

export default Lab
