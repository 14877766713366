import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button, Table, Card } from 'react-bootstrap'
import Message from '../components/Message'
import ToastMessage from '../components/ToastMessage'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import SystemOwnerStudyFilters from '../components/SystemOwnerStudyFilters'

import {
  getStudyList,
  createStudy,
  deleteStudy,
  activateStudy,
  deactivateStudy,
} from '../actions/systemOwnerStudyActions'
import {
  STUDY_ACTIVATE_RESET,
  STUDY_CREATE_RESET,
  STUDY_DEACTIVATE_RESET,
  STUDY_DELETE_RESET,
  STUDY_DETAILS_RESET,
  STUDY_LIST_RESET,
} from '../constants/studyConstants'
import {
  DOWNLOAD_TEXT_RESP_RESET,
  PARTI_LIST_RESET,
} from '../../participant_module/constants/participantConstants'
import resetErrorState from '../utils/resetErrorState'

import Organizations from '../../enums/organizations'
import getSubdomain from '../../getSubdomain'
import findOrganizationBySubdomain from '../../findOrganizationBySubdomain'
import redirectDueToInvalidSubdomain from '../../redirectDueToInvalidSubdomain'

const SystemOwnerStudyListScreen = ({ history, match }) => {
  // Make sure system owner is only accessible through longitudinax site
  const subdomain = getSubdomain(window)
  const siteOrganization = findOrganizationBySubdomain(subdomain)
  if (siteOrganization !== Organizations.Longitudinax) {
    // Keep the site subdomain by providing undefined to the second parameter
    redirectDueToInvalidSubdomain(window, undefined)
  }

  /////////////////   For pagination ///////////////////
  const pageNumber = match.params.pageNumber || 1
  const [page, setPage] = useState(pageNumber)
  /////////////////////////////////////////////////////

  // For toast message
  const [toastMessages, setToastMessages] = useState([])

  const toastOnCloseHandler = async (uid) => {
    setToastMessages((toastMessages) =>
      toastMessages.filter((message) => message.uid !== uid)
    )
  }

  const [labId, setLabId] = useState('')
  const [managedByUserId, setManagedByUserId] = useState('')

  const dispatch = useDispatch()

  //It is needed to check if the logged in user is a system owner
  const systemOwnerLogin = useSelector((state) => state.systemOwnerLogin)
  const { systemOwnerInfo, logout } = systemOwnerLogin

  const studyList = useSelector((state) => state.studyList)
  const {
    studies,
    error: errorGetList,
    loading: loadingGetList,
    success: successGetList,
    pages,
  } = studyList

  const studyCreate = useSelector((state) => state.studyCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    study: createdStudy,
  } = studyCreate

  const studyDelete = useSelector((state) => state.studyDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = studyDelete

  const studyActivate = useSelector((state) => state.studyActivate)
  const {
    loading: loadingActivate,
    error: errorActivate,
    success: successActivate,
    message: messageActivate,
  } = studyActivate

  const studyDeactivate = useSelector((state) => state.studyDeactivate)
  const {
    loading: loadingDeactivate,
    error: errorDeactivate,
    success: successDeactivate,
    message: messageDeactivate,
  } = studyDeactivate

  useEffect(() => {
    const now = new Date()
    if (!systemOwnerInfo || systemOwnerInfo.expires < now || logout) {
      dispatch({ type: STUDY_LIST_RESET })
      history.push('/systemowner/login')
    } else {
      if (
        !loadingGetList &&
        !errorGetList &&
        !successGetList &&
        labId.length !== 0 &&
        managedByUserId.length !== 0
      ) {
        dispatch(getStudyList(labId, managedByUserId, pageNumber))
      }

      if (successCreate) {
        dispatch({ type: STUDY_CREATE_RESET })
        dispatch({ type: STUDY_LIST_RESET })
        //history.push(`/studies/${createdStudy._id}/edit`)
      }

      if (successDelete) {
        dispatch({ type: STUDY_DELETE_RESET })
        dispatch(getStudyList(labId, managedByUserId, pageNumber))
      }

      if (successActivate) {
        // Demo for displaying toast message
        setToastMessages((toastMessages) => [
          ...toastMessages,
          {
            uid: new Date().valueOf(),
            variant: 'info',
            message: messageActivate,
          },
        ])
        dispatch({ type: STUDY_ACTIVATE_RESET })
        dispatch(getStudyList(labId, managedByUserId, pageNumber))
      }

      if (successDeactivate) {
        // Demo for displaying toast message
        setToastMessages((toastMessages) => [
          ...toastMessages,
          {
            uid: new Date().valueOf(),
            variant: 'info',
            message: messageDeactivate,
          },
        ])
        dispatch({ type: STUDY_DEACTIVATE_RESET })
        dispatch(getStudyList(labId, managedByUserId, pageNumber))
      }
    }

    /////////////////   For pagination ///////////////////
    //pages > 0 is essential, otherwise there is an error when the list is empty
    if (pages > 0 && page > pages) {
      history.push(`/studies/${pages}`)
      dispatch({ type: STUDY_LIST_RESET })
    }
    if (pageNumber !== page) {
      dispatch({ type: STUDY_LIST_RESET })
      setPage(pageNumber)
    }
    /////////////////////////////////////////////////////
  }, [
    dispatch,
    history,
    systemOwnerInfo,
    page,
    pages,
    pageNumber,
    logout,
    //createdStudy,
    loadingGetList,
    errorGetList,
    successCreate,
    successDelete,
    successGetList,
    successActivate,
    messageActivate,
    successDeactivate,
    messageDeactivate,
    labId,
    managedByUserId,
  ])

  const goToEditHandler = async (study) => {
    dispatch({ type: STUDY_LIST_RESET })
    dispatch({ type: STUDY_DETAILS_RESET })
    history.push(`/systemowner/studies/${study._id}/${managedByUserId}/edit`)
  }

  // System owner is not allowed to downlaod participant data and check their progress for now
  /*
  const goToParticipantList = async (study) => {
    dispatch({ type: STUDY_LIST_RESET })
    dispatch({ type: PARTI_LIST_RESET })
    dispatch({ type: STUDY_DETAILS_RESET })
    dispatch({ type: DOWNLOAD_TEXT_RESP_RESET })
    //history.push(`/participants/${study._id}`)
  }

  const goToCheckProgress = async (study) => {
    dispatch({ type: STUDY_LIST_RESET })
    //history.push(`/participants/check_progress/${study._id}`)
  }
  */

  const createStudyHandler = async () => {
    dispatch(createStudy(managedByUserId))
  }

  const deleteStudyHandler = async (study) => {
    if (window.confirm('Are you sure?')) {
      resetErrorState(dispatch)
      dispatch(deleteStudy(study._id))
    }
  }

  const activateStudyHandler = async (study) => {
    dispatch(activateStudy(study._id))
  }

  const deactivateStudyHandler = async (study) => {
    dispatch(deactivateStudy(study._id))
  }

  const handleFiltersUpdated = (selectedLabId, selectedManagedByUserId) => {
    if (selectedLabId === 'unselected') {
      setLabId('')
    } else {
      setLabId(selectedLabId)
    }
    if (selectedManagedByUserId === 'unselected') {
      setManagedByUserId('')
    } else {
      if (managedByUserId !== selectedManagedByUserId) {
        dispatch({ type: STUDY_LIST_RESET })
      }
      setManagedByUserId(selectedManagedByUserId)
    }
  }

  const headingStyle = {
    fontSize: '2rem',
    fontWeight: 'bold',
  }

  return (
    <>
      {/* Display toast messages */}
      <div
        style={{
          position: 'absolute',
          backgroundColor: 'transparent',
          pointerEvents: 'none',
          height: '80vh',
          width: '80vw',
          zIndex: 1,
        }}
      >
        {toastMessages.map((toastMessage) => (
          <ToastMessage
            key={toastMessage.uid}
            variant={toastMessage.variant}
            onClose={() => toastOnCloseHandler(toastMessage.uid)}
          >
            {toastMessage.message}
          </ToastMessage>
        ))}
      </div>

      {managedByUserId && (
        <Row className='align-items-center'>
          <Col>
            <h1 style={headingStyle}>Study List</h1>
          </Col>
          <Col className='text-right'>
            <Button className='my-3' onClick={createStudyHandler}>
              <i className='fas fa-plus'></i> Create Study
            </Button>
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <Card className='p-2 my-2 rounded'>
            <SystemOwnerStudyFilters onFilterUpdated={handleFiltersUpdated} />
          </Card>
        </Col>
      </Row>

      {managedByUserId.length > 0 && (
        <>
          {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
          {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
          {errorActivate && <Message variant='danger'>{errorActivate}</Message>}
          {errorDeactivate && (
            <Message variant='danger'>{errorDeactivate}</Message>
          )}
          {loadingGetList ||
          loadingCreate ||
          loadingDelete ||
          loadingActivate ||
          loadingDeactivate ? (
            <Loader />
          ) : errorGetList ? (
            <Message variant='danger'>{errorGetList}</Message>
          ) : (
            <>
              <Table striped bordered hover responsive className='table-sm'>
                <thead>
                  <tr>
                    <th>NAME</th>
                    <th>STUDY CODE</th>
                    <th>STATUS</th>
                    <th>CREATED AT</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!logout &&
                    studies &&
                    studies.map((study) => (
                      <tr key={study._id}>
                        <td className='align-middle'>{study.chin_name}</td>
                        <td className='align-middle'>{study.study_code}</td>
                        <td className='align-middle'>{study.active_status}</td>
                        <td className='align-middle'>
                          {study.createdAt.substring(0, 10)}
                        </td>
                        <td className='align-middle'>
                          <Button
                            variant='primary'
                            className='btn-sm mx-1'
                            onClick={() => goToEditHandler(study)}
                          >
                            Edit
                          </Button>

                          {study.active_status === 'inactive' ? (
                            <>
                              <Button
                                variant='primary'
                                className='btn-sm mx-1'
                                onClick={() => activateStudyHandler(study)}
                              >
                                Activate
                              </Button>
                              <Button
                                variant='danger'
                                className='btn-sm mx-1'
                                onClick={() => deleteStudyHandler(study)}
                              >
                                Delete
                              </Button>
                            </>
                          ) : (
                            <Button
                              variant='primary'
                              className='btn-sm mx-1'
                              onClick={() => deactivateStudyHandler(study)}
                            >
                              Deactivate
                            </Button>
                          )}
                          {/* System owner is not allowed to downlaod participant data and check their progress for now
                          {study.active_status !== 'inactive' && (
                            <Button
                              variant='primary'
                              className='btn-sm mx-1'
                              onClick={() => goToParticipantList(study)}
                            >
                              Participants
                            </Button>
                          )}
                          {study.active_status !== 'inactive' && (
                            <Button
                              variant='primary'
                              className='btn-sm m-1'
                              onClick={() => goToCheckProgress(study)}
                            >
                              Progress
                            </Button>
                          )}
                          */}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <Paginate
                pages={pages}
                page={page}
                forSystemOwner={true}
              ></Paginate>
            </>
          )}
        </>
      )}
    </>
  )
}

export default SystemOwnerStudyListScreen
