import React from 'react'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button, Row, Col, Form, Card } from 'react-bootstrap'
import Message from '../components/Message'
import ModalContainer from '../components/ModalContainer'
import DependencyAdd from '../components/DependencyAdd'
import DependencyEntry from '../components/DependencyEntry'

const Dependency = ({ i, onDependencyUpdate, qx }) => {
  const [show, setShow] = useState(false)

  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [isDependencyLoaded, setIsDependencyLoaded] = useState(false)

  const [dependency, setDependency] = useState([])

  const qxMcList = useSelector((state) => state.mcList)
  const { mcList: loadedMcList, success: mcListLoaded } = qxMcList
  const [mcList, setMcList] = useState([])

  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (!isDataLoaded) {
      if (mcListLoaded) {
        setMcList(loadedMcList)
      }
      setIsDataLoaded(true)
    }
    if (isDataLoaded && !isDependencyLoaded) {
      setDependency(qx.chin_content[i].dependency)
      setIsDependencyLoaded(true)
    }
  }, [qx, isDependencyLoaded, i, isDataLoaded, loadedMcList, mcListLoaded])

  const handleOpen = () => {
    setShow(true)
  }

  const handleClose = () => {
    onDependencyUpdate(dependency)
    setShow(false)
  }

  const requriesNewAndDependency = (andIndex) => {
    if (andIndex >= dependency.length) {
      return true
    } else {
      return false
    }
  }

  const removeTag = (question) => {
    return question.replace(/<[^>]+>/g, '')
  }

  const handleAddingAndDependencyError = (error) => {
    setErrorMessage(error)
  }

  // Append the newDependency to the end of the "AND" dependency list at orIndex
  const handleAddingAndDependency = (newAndDependency, andIndex) => {
    setErrorMessage('')
    let error = ''
    if (error.length === 0) error = checkDependencyDuplicated(newAndDependency)
    if (error.length === 0) {
      let dependencyCopy = [...dependency]
      if (!requriesNewAndDependency(andIndex)) {
        dependencyCopy[andIndex].or.push(newAndDependency)
        setDependency(dependencyCopy)
      } else {
        let tempAndDependency = {}
        tempAndDependency.or = []
        tempAndDependency.or.push(newAndDependency)
        dependencyCopy.push(tempAndDependency)
        setDependency(dependencyCopy)
      }
    } else {
      setErrorMessage(error)
    }
  }

  const removeHandle = (andIndex, orIndex) => {
    let dependencyCopy = [...dependency]
    dependencyCopy[andIndex].or.splice(orIndex, 1)
    if (dependencyCopy[andIndex].or.length === 0) {
      dependencyCopy.splice(andIndex, 1)
    }
    setDependency(dependencyCopy)
  }

  //////// Validators //////////

  const checkDependencyDuplicated = (tempOrDependency) => {
    for (let i = 0; i < dependency.length; ++i) {
      for (let j = 0; j < dependency[i].or.length; ++j) {
        if (
          JSON.stringify(dependency[i].or[j]) ===
          JSON.stringify(tempOrDependency)
        ) {
          return 'The new dependency is already existed.'
        }
      }
    }
    return '' // Returns empty string when passing validation
  }

  //////////////////////////////

  return (
    <>
      {
        <Button
          variant='primary'
          onClick={handleOpen}
          style={{ marginTop: '3px' }}
          className='mx-1 float-right'
        >
          This Component visible when...
        </Button>
      }
      {isDataLoaded && isDependencyLoaded && show && (
        <ModalContainer
          title='Please specify the condition when the chosen component shall be visible'
          show={show}
          handleClose={handleClose}
        >
          {errorMessage && <Message variant='danger'>{errorMessage}</Message>}
          <Form.Group>
            <Form.Label>Chosen Component</Form.Label>
            <Form.Control
              type='text'
              readOnly
              value={
                qx.chin_content[i].question_num +
                ' ' +
                removeTag(qx.chin_content[i].html_question)
              }
            />
          </Form.Group>
          <Form.Label>
            Chosen Component visible when... (If no condition is specified
            below, the question is by default visible)
          </Form.Label>
          {isDependencyLoaded &&
            dependency.map((orList, andIndex) => (
              <>
                <Card className='p-1 pt-2 my-1 rounded'>
                  {orList.or.map((d, orIndex) => (
                    <>
                      <DependencyEntry
                        andIndex={andIndex}
                        orIndex={orIndex}
                        dependency={d}
                        qxContent={qx.chin_content}
                        mcList={mcList}
                        onDependencyDelete={removeHandle}
                      ></DependencyEntry>
                      {orIndex !== orList.or.length - 1 && (
                        <Row className='mb-2 text-center'>
                          <Col md={12} className='mx-auto'>
                            -- OR --
                          </Col>
                        </Row>
                      )}
                    </>
                  ))}

                  <DependencyAdd
                    andIndex={andIndex}
                    onDependencyAddError={handleAddingAndDependencyError}
                    onDependencyAdd={handleAddingAndDependency}
                    qxContent={qx.chin_content}
                    mcList={mcList}
                    targetingQuestionIndex={i}
                  ></DependencyAdd>
                </Card>
                <Card className='p-1 pt-2 my-1 rounded'>
                  <Row className='text-center'>
                    <Col md={12} className='mx-auto'>
                      -- AND --
                    </Col>
                  </Row>
                </Card>
              </>
            ))}
          <Card className='p-1 pt-2 my-1 rounded'>
            <DependencyAdd
              andIndex={dependency.length}
              onDependencyAddError={handleAddingAndDependencyError}
              onDependencyAdd={handleAddingAndDependency}
              qxContent={qx.chin_content}
              mcList={mcList}
              targetingQuestionIndex={i}
            ></DependencyAdd>
          </Card>
        </ModalContainer>
      )}
    </>
  )
}

export default Dependency
