import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Form, Card } from 'react-bootstrap'
import ModalContainer from './ModalContainer'
import Message from './Message'
import EMA_OR_EMI from '../../ema_or_emi'

export const DiaryAx = ({ group, index, qx_list, handleDelete, readOnly }) => {
  const [show, setShow] = useState(false)

  const [questionnaireList, setQuestionnaireList] = useState([]) // For diaryAx, not for selection choices
  const [fixStartDate, setFixStartDate] = useState(false)
  const [startDate, setStartDate] = useState(undefined) //only be saved when fixStartDate is true
  const [numberOfDays, setNumberOfDays] = useState(undefined)
  const [timezone, setTimezone] = useState('')

  const [saveError, setSaveError] = useState('')

  useEffect(() => {
    setQuestionnaireList(group.diary_list[index].questionnaire_list)
    setFixStartDate(group.diary_list[index].fix_start_date)
    setStartDate(group.diary_list[index].start_date)
    setNumberOfDays(group.diary_list[index].number_of_days)
    setTimezone(group.diary_list[index].timezone)
  }, [group, index])

  const handleOpen = () => {
    setShow(true)
  }

  ////////// Validator ///////////

  const checkQuestionnaireUsedInAnotherSetting = () => {
    for (let i = 0; i < group.diary_list.length; ++i) {
      if (i !== index) {
        const found_questionnaire = group.diary_list[i].questionnaire_list.find(
          (otherQid) => {
            const is_found = questionnaireList.find((thisQid) => {
              return thisQid === otherQid
            })
            if (is_found !== undefined) {
              return true
            } else {
              return false
            }
          }
        )
        if (found_questionnaire !== undefined) {
          return 'Questionnaire has been selected in another Diary Assessment setting'
        }
      }
    }
    return ''
  }

  const checkNumberOfDays = () => {
    if (!numberOfDays || numberOfDays <= 0) {
      return "'For number of days' field must be larger than 0"
    }
    return ''
  }

  const checkStartDate = () => {
    if (fixStartDate && !startDate) {
      return 'Please specify the start date'
    }
    return ''
  }

  /////////////////////////////////

  const updateQuestionnaireList = async (e) => {
    let tempList = [...questionnaireList]

    let removeOption = false
    for (let i = tempList.length - 1; i >= 0; --i) {
      if (tempList[i] === e.target.value) {
        tempList.splice(i, 1)
        removeOption = true
      }
    }
    if (removeOption === false) {
      tempList.push(e.target.value)
    }
    setQuestionnaireList(tempList)
  }

  const sanitizeQuestionnaireList = (questionnaireList) => {
    let output = []
    for (let i = 0; i < questionnaireList.length; ++i) {
      if (questionnaireList[i].length === 0) {
        continue
      }
      output.push(questionnaireList[i])
    }
    return output
  }

  const handleClose = () => {
    setSaveError('')
    let error = ''

    if (error.length === 0) error = checkQuestionnaireUsedInAnotherSetting()
    if (error.length === 0) error = checkNumberOfDays()
    if (error.length === 0) error = checkStartDate()

    if (error.length === 0) {
      group.diary_list[index].questionnaire_list =
        sanitizeQuestionnaireList(questionnaireList)
      group.diary_list[index].fix_start_date = fixStartDate
      group.diary_list[index].start_date = startDate
      group.diary_list[index].number_of_days = numberOfDays
      group.diary_list[index].timezone = timezone
      setShow(false)
    } else {
      setSaveError(error)
    }
  }

  return (
    <>
      {questionnaireList && (
        <Card className='p-2 my-2 rounded'>
          <Row style={{ marginLeft: '-5px' }}>
            {questionnaireList.length === 0 ? (
              <Col md={6} style={{ whiteSpace: 'normal' }}>
                Click 'Edit' to start setting up
              </Col>
            ) : (
              <Col md={6} style={{ whiteSpace: 'normal' }}>
                {questionnaireList.length > 1 ? (
                  <>
                    Settings applied to {questionnaireList.length}{' '}
                    questionnaires
                  </>
                ) : (
                  qx_list
                    .filter(
                      (qx) => String(qx._id) === String(questionnaireList[0])
                    )
                    .map((qx) => (
                      <div key={'diary_ax_preview_' + qx._id + group._id}>
                        Settings applied to {qx.chin_name} ({qx.abbrev})
                      </div>
                    ))
                )}
              </Col>
            )}
            <Col md={3}>
              <Button
                style={{ width: '100%' }}
                variant='primary'
                className='btn-sm m-1'
                onClick={handleOpen}
              >
                {readOnly ? <>View</> : <>Edit</>}
              </Button>
            </Col>
            {EMA_OR_EMI === 'ema' && !readOnly && (
              <Col md={3}>
                <Button
                  style={{ width: '100%', marginLeft: '-12px' }}
                  variant='danger'
                  className='btn-sm my-1'
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </Col>
            )}
          </Row>
        </Card>
      )}

      <ModalContainer
        title={
          readOnly
            ? 'Diary Assessment Settings'
            : 'Edit Diary Assessment Settings'
        }
        show={show}
        handleClose={handleClose}
      >
        {saveError && <Message variant='danger'>{saveError}</Message>}

        <Row>
          <Col md={12}>
            <Form.Group
              controlId={'diary_ax_fix_start_date' + group._id + index}
            >
              <Form.Check
                type='checkbox'
                label='Specify start date that applies to every particiapnt (or the start date will be set to be the day of group assignment if unchecked)'
                checked={fixStartDate}
                onChange={(e) => {
                  if (!e.target.checked) {
                    setStartDate(undefined)
                  }
                  setFixStartDate(e.target.checked)
                }}
              />
            </Form.Group>
          </Col>
        </Row>

        {fixStartDate && (
          <Row>
            <Col md={12}>
              <Form.Group controlId={'diary_ax_start_date' + group._id + index}>
                <Form.Label style={{ whiteSpace: 'normal' }}>
                  Start date
                </Form.Label>
                <Form.Control
                  type='date'
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value)
                  }}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
        )}

        <Row>
          <Col md={12}>
            <Form.Group
              controlId={'diary_ax_number_of_days' + group._id + index}
            >
              <Form.Label style={{ whiteSpace: 'normal' }}>
                For number of days
              </Form.Label>
              <Form.Control
                type='number'
                value={numberOfDays}
                placeholder='Days'
                onChange={(e) => {
                  setNumberOfDays(e.target.value)
                }}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId={'diary_ax_timezone' + group._id + index}>
              <Form.Label>Time Zone</Form.Label>
              <Form.Control
                as='select'
                value={timezone}
                onChange={(e) => {
                  setTimezone(e.target.value)
                }}
              >
                <option value='Hongkong'>Hong Kong</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            {EMA_OR_EMI === 'ema' ? (
              <Form.Group controlId={'diary_ax_groupQx_' + group._id + index}>
                <Form.Label style={{ whiteSpace: 'normal' }}>
                  Assessments with the above settings applied (Click to
                  select/unselect)
                </Form.Label>
                <Form.Control
                  as='select'
                  value={questionnaireList}
                  onChange={() => {}}
                  onClick={async (e) => {
                    await updateQuestionnaireList(e)
                  }}
                  multiple
                >
                  {qx_list.map((qx) => (
                    <option
                      key={'diary_ax' + qx._id + group._id}
                      value={qx._id}
                    >
                      {qx.chin_name} ({qx.abbrev})
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            ) : (
              <>
                <Form.Label style={{ whiteSpace: 'normal' }}>
                  Assessments with the above settings applied
                </Form.Label>
                <ul>
                  {qx_list
                    .filter((qx) =>
                      questionnaireList.find(
                        (x) => String(x) === String(qx._id)
                      )
                    )
                    .map((qx) => (
                      <li key={qx._id + group._id + '_name'}>
                        {qx.chin_name} ({qx.abbrev})
                      </li>
                    ))}
                </ul>
              </>
            )}
          </Col>
        </Row>
      </ModalContainer>
    </>
  )
}

export default DiaryAx
