import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button, Row, Col, Form, Card } from 'react-bootstrap'
import ModalContainer from './ModalContainer'
import Message from './Message'

export const EmaAx = ({ group, index, qx_list, handleDelete }) => {
  //It is needed to check if the logged in user is an admin
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const [show, setShow] = useState(false)

  const [questionnaireList, setQuestionnaireList] = useState([]) // For emaAx, not for selection choices
  const [randomSettings, setRandomSettings] = useState(undefined)
  const [timeList, setTimeList] = useState([])
  const [timezone, setTimezone] = useState('')
  const [numberOfDays, setNumberOfDays] = useState(undefined)
  const [dayOneEqSameDay, setDayOneEqSameDay] = useState(true)
  const [reminderInterval, setReminderInterval] = useState(undefined)
  const [responseIn, setResponseIn] = useState(undefined)

  const [saveError, setSaveError] = useState('')

  useEffect(() => {
    setQuestionnaireList(group.ema_list[index].questionnaire_list)
    setRandomSettings(group.ema_list[index].random_settings)
    setTimeList(group.ema_list[index].time_list)
    setTimezone(group.ema_list[index].timezone)
    setNumberOfDays(group.ema_list[index].number_of_days)
    setDayOneEqSameDay(group.ema_list[index].day_one_eq_same_day)
    setReminderInterval(group.ema_list[index].reminder_interval)
    setResponseIn(group.ema_list[index].response_in)
  }, [group, index])

  const handleOpen = () => {
    setShow(true)
  }

  const handleTimeListDelete = (i) => {
    let updatedTimeList = [...timeList]
    updatedTimeList.splice(i, 1)
    setTimeList(updatedTimeList)
  }

  const handleTimeListAdd = () => {
    let updatedTimeList = [...timeList]
    updatedTimeList.push('09:00')
    setTimeList(updatedTimeList)
  }

  ////////// Validator ///////////

  const checkQuestionnaireUsedInAnotherSetting = () => {
    for (let i = 0; i < group.ema_list.length; ++i) {
      if (i !== index) {
        const found_questionnaire = group.ema_list[i].questionnaire_list.find(
          (otherQid) => {
            const is_found = questionnaireList.find((thisQid) => {
              return thisQid === otherQid
            })
            if (is_found !== undefined) {
              return true
            } else {
              return false
            }
          }
        )
        if (found_questionnaire !== undefined) {
          return 'Questionnaire has been selected in another EMA setting'
        }
      }
    }
    return ''
  }

  const checkNumberOfDays = () => {
    if (!numberOfDays || numberOfDays <= 0) {
      return "'For number of days' field must be larger than 0"
    }
    return ''
  }

  const checkReminderInterval = () => {
    if (isNaN(reminderInterval)) {
      return 'Interval between reminder notifications must be a number'
    }
    if (Number(reminderInterval) < 5) {
      return 'Interval between reminder notifications cannot be empty or less than 5 minutes'
    }
    return ''
  }

  const checkTimeList = () => {
    if (timeList.length === 0) {
      return 'There must be at least one assessment time'
    }
    return ''
  }

  const checkResponseIn = () => {
    if (isNaN(responseIn)) {
      return "'Response has to be made within' field must be a number"
    }
    if (Number(responseIn) < 5) {
      return "'Response has to be made within' field cannot be empty or less than 5 minutes"
    }
    if (Number(reminderInterval) >= Number(responseIn)) {
      return "Interval between reminder notifications cannot be larger than or equal to the number in 'Response has to be made within' field"
    }
    return ''
  }

  /////////////////////////////////

  const updateQuestionnaireList = async (e) => {
    let tempList = JSON.parse(JSON.stringify(questionnaireList))

    let removeOption = false
    for (let i = tempList.length - 1; i >= 0; --i) {
      if (tempList[i] === e.target.value) {
        tempList.splice(i, 1)
        removeOption = true
      }
    }
    if (removeOption === false) {
      tempList.push(e.target.value)
    }
    setQuestionnaireList(tempList)
  }

  const sanitizeQuestionnaireList = (questionnaireList) => {
    let output = []
    for (let i = 0; i < questionnaireList.length; ++i) {
      if (questionnaireList[i].length === 0) {
        continue
      }
      output.push(questionnaireList[i])
    }
    return output
  }

  const handleClose = () => {
    setSaveError('')
    let error = ''

    if (error.length === 0) error = checkQuestionnaireUsedInAnotherSetting()
    if (error.length === 0) error = checkNumberOfDays()
    if (error.length === 0) error = checkReminderInterval()
    if (error.length === 0) error = checkTimeList()
    if (error.length === 0) error = checkResponseIn()

    if (error.length === 0) {
      group.ema_list[index].questionnaire_list =
        sanitizeQuestionnaireList(questionnaireList)
      group.ema_list[index].timezone = timezone
      group.ema_list[index].response_in = responseIn
      group.ema_list[index].reminder_interval = reminderInterval
      group.ema_list[index].time_list = timeList
      group.ema_list[index].number_of_days = numberOfDays
      group.ema_list[index].day_one_eq_same_day = dayOneEqSameDay
      //TBD: handle random settings
      setShow(false)
    } else {
      setSaveError(error)
    }
  }

  return (
    <>
      {questionnaireList && (
        <Card className='p-2 my-2 rounded'>
          <Row style={{ marginLeft: '-5px' }}>
            {questionnaireList.length === 0 ? (
              <Col md={6} style={{ whiteSpace: 'normal' }}>
                Click 'Edit' to start setting up
              </Col>
            ) : (
              <Col md={6} style={{ whiteSpace: 'normal' }}>
                Settings applied to {questionnaireList.length} questionnaire(s)
              </Col>
            )}
            <Col md={3}>
              <Button
                style={{ width: '100%' }}
                variant='primary'
                className='btn-sm m-1'
                onClick={handleOpen}
              >
                Edit
              </Button>
            </Col>
            <Col md={3}>
              <Button
                style={{ width: '100%', marginLeft: '-12px' }}
                variant='danger'
                className='btn-sm my-1'
                onClick={handleDelete}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Card>
      )}

      <ModalContainer
        title='Edit EMA Settings'
        show={show}
        handleClose={handleClose}
      >
        {saveError && <Message variant='danger'>{saveError}</Message>}
        <Form.Label>Assessment Time</Form.Label>
        {timeList &&
          timeList.map((time, i) => (
            <Row key={'ema_timelist' + group._id + index + i}>
              <Col md={9}>
                <Form.Group
                  controlId={'ema_timelist_row' + group._id + index + i}
                >
                  <Form.Control
                    type='time'
                    value={time}
                    onChange={(e) => {
                      let tempList = [...timeList]
                      tempList[i] = e.target.value
                      setTimeList(tempList)
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Button
                  style={{ width: '100%', marginLeft: '-12px' }}
                  variant='danger'
                  className='btn-sm my-1'
                  onClick={() => handleTimeListDelete(index)}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          ))}

        <Row>
          <Col md={12}>
            <Button
              style={{ width: '100%', marginBottom: '10px' }}
              variant='primary'
              onClick={handleTimeListAdd}
            >
              Add Assessment Time
            </Button>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId={'ema_timezone' + group._id + index}>
              <Form.Label>Time Zone</Form.Label>
              <Form.Control
                as='select'
                value={timezone}
                onChange={(e) => {
                  setTimezone(e.target.value)
                }}
              >
                <option value='Hongkong'>Hong Kong</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Form.Group controlId={'ema_number_of_days' + group._id + index}>
              <Form.Label style={{ whiteSpace: 'normal' }}>
                For number of days
              </Form.Label>
              <Form.Control
                type='number'
                value={numberOfDays}
                placeholder='Days'
                onChange={(e) => {
                  setNumberOfDays(e.target.value)
                }}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Form.Group
              controlId={'ema_day_one_eq_same_day' + group._id + index}
            >
              <Form.Check
                type='checkbox'
                label='First notification will be sent on the same day when participants are first assigned into a participant group (Or 1 day later if the checkbox is unticked)'
                checked={dayOneEqSameDay}
                onChange={(e) => {
                  setDayOneEqSameDay(e.target.checked)
                }}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId={'ema_interval' + group._id + index}>
              <Form.Label>
                Interval between reminder notifications (minutes)
              </Form.Label>
              <Form.Control
                type='number'
                value={reminderInterval}
                placeholder='Minutes'
                onChange={(e) => {
                  setReminderInterval(e.target.value)
                }}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId={'ema_response_in' + group._id + index}>
              <Form.Label>Response has to be made within (minutes)</Form.Label>
              <Form.Control
                type='number'
                value={responseIn}
                placeholder='Minutes'
                onChange={(e) => {
                  setResponseIn(e.target.value)
                }}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Form.Group controlId={'ema_groupQx_' + group._id + index}>
              <Form.Label style={{ whiteSpace: 'normal' }}>
                Assessments with the above settings applied (Click to
                select/unselect)
              </Form.Label>
              <Form.Control
                as='select'
                value={questionnaireList}
                onChange={() => {}}
                onClick={async (e) => {
                  await updateQuestionnaireList(e)
                }}
                multiple
              >
                {qx_list.map((qx) => (
                  <option key={'ema' + qx._id + group._id} value={qx._id}>
                    {qx.chin_name} ({qx.abbrev})
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </ModalContainer>
    </>
  )
}

export default EmaAx
