import FormContainer from '../components/FormContainer'
import { Row, Col } from 'react-bootstrap'

const headingStyle = {
  fontSize: '2rem',
  fontWeight: 'bold',
  marginTop: '3rem',
}

const GenericErrorScreen = () => {
  return (
    <FormContainer>
      <Row>
        <Col>
          <h2 style={headingStyle}>Oops! Something went wrong!</h2>
        </Col>
      </Row>
    </FormContainer>
  )
}

export default GenericErrorScreen
