import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Row, Col, Form } from 'react-bootstrap'
import ModalContainer from './ModalContainer'

import { createTxPage } from '../actions/txMaterialsActions'
import Loader from './Loader'
import Message from './Message'
import { TX_PAGE_CREATE_RESET } from '../constants/txMaterialsConstants'

export const CreateTxPage = ({ studyId, onCreatePage }) => {
  const txPageCreate = useSelector((state) => state.txPageCreate)
  const { loading, error, success, txPage: createdPage } = txPageCreate

  const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [saveError, setSaveError] = useState('')
  const [identifier, setIdentifier] = useState('')

  useEffect(() => {
    if (success) {
      setShow(false)
      onCreatePage(createdPage)
    }
  }, [success, onCreatePage])

  const handleOpen = () => {
    dispatch({ type: TX_PAGE_CREATE_RESET })
    setShow(true)
  }

  const checkIdentifierNotEmpty = () => {
    return identifier.trim().length === 0 ? 'Please fill in identifier' : ''
  }

  const handleSave = (e) => {
    e.preventDefault()
    setSaveError('')
    let error = ''

    // frontend validation
    if (error.length === 0) error = checkIdentifierNotEmpty()

    // save
    if (error.length === 0) {
      dispatch(createTxPage(studyId, identifier))
    } else {
      setSaveError(error)
    }
  }

  const handleClose = () => {
    setShow(false)
  }

  return (
    <>
      <Button className='my-3' onClick={handleOpen}>
        <i className='fas fa-plus'></i> Create New Treatment Page
      </Button>

      <ModalContainer
        title='Create New Treatment Page'
        show={show}
        handleSave={handleSave}
        handleClose={handleClose}
        showSave={true}
      >
        {error && <Message variant='danger'>{error}</Message>}
        {saveError && <Message variant='danger'>{saveError}</Message>}

        {loading ? (
          <Loader />
        ) : (
          <>
            <Row>
              <Col>
                <Form.Group controlId={'new_tx_page_identifier'}>
                  <Form.Label>Identifier</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Identifier'
                    value={identifier}
                    onChange={(e) => {
                      setIdentifier(e.target.value)
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </>
        )}
      </ModalContainer>
    </>
  )
}

export default CreateTxPage
