import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Row, Col, Form } from 'react-bootstrap'
import ModalContainer from '../components/ModalContainer'

import { createParticipant } from '../actions/participantActions'
import Loader from '../components/Loader'
import Message from './Message'
import { CREATE_PARTI_RESET } from '../constants/participantConstants'

export const CreateParticipant = ({ studyId }) => {
  const createParticipantState = useSelector((state) => state.createParti)
  const { loading, success, error } = createParticipantState

  const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [saveError, setSaveError] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  useEffect(() => {
    if (success) {
      setShow(false)
    }
  }, [success])

  const handleOpen = () => {
    dispatch({ type: CREATE_PARTI_RESET })
    setShow(true)
  }

  const checkEmailNotEmpty = () => {
    return email.trim().length === 0 ? 'Please fill in email' : ''
  }
  const checkPasswordNotEmpty = () => {
    return password.trim().length === 0 || confirmPassword.trim().length === 0
      ? 'Please fill in password and confirm password'
      : ''
  }
  const checkConfirmPassword = () => {
    return password !== confirmPassword
      ? 'You entered a different confirmed password'
      : ''
  }

  const handleSave = (e) => {
    e.preventDefault()
    setSaveError('')
    let error = ''

    // frontend validation
    if (error.length === 0) error = checkEmailNotEmpty()
    if (error.length === 0) error = checkPasswordNotEmpty()
    if (error.length === 0) error = checkConfirmPassword()

    // save
    if (error.length === 0) {
      dispatch(createParticipant(studyId, email, password))
    } else {
      setSaveError(error)
    }
  }

  const handleClose = () => {
    setShow(false)
  }

  return (
    <>
      <Button className='my-3' onClick={handleOpen}>
        <i className='fas fa-plus'></i> Create New Participant
      </Button>

      <ModalContainer
        title='Create New Participant'
        show={show}
        handleSave={handleSave}
        handleClose={handleClose}
        showSave={true}
      >
        {error && <Message variant='danger'>{error}</Message>}
        {saveError && <Message variant='danger'>{saveError}</Message>}

        {loading ? (
          <Loader />
        ) : (
          <>
            <Row>
              <Col>
                <Form.Group controlId={'new_participant_email'}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Email'
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value)
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId={'new_participant_password'}>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type='password'
                    placeholder='Enter Password'
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value)
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId={'new_participant_confirm_password'}>
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type='password'
                    placeholder='Enter Password Again'
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value)
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </>
        )}
      </ModalContainer>
    </>
  )
}

export default CreateParticipant
