/* This screen for system owner to list all users in the lab */

import React, { useEffect } from 'react'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { systemOwnerGetUserByLab } from '../actions/labActions'
import { systemOwnerDeleteUser } from '../actions/userActions'
import { LinkContainer } from 'react-router-bootstrap'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { Link } from 'react-router-dom'
import {
  SYSTEM_OWNER_GET_USER_DETAIL_RESET,
  SYSTEM_OWNER_REGISTER_ADMIN_RESET,
} from '../constants/userConstants'

import Organizations from '../../enums/organizations'
import getSubdomain from '../../getSubdomain'
import findOrganizationBySubdomain from '../../findOrganizationBySubdomain'
import redirectDueToInvalidSubdomain from '../../redirectDueToInvalidSubdomain'

const SystemOwnerListingUserScreen = ({ match, history }) => {
  // Make sure system owner is only accessible through longitudinax site
  const subdomain = getSubdomain(window)
  const siteOrganization = findOrganizationBySubdomain(subdomain)
  if (siteOrganization !== Organizations.Longitudinax) {
    // Keep the site subdomain by providing undefined to the second parameter
    redirectDueToInvalidSubdomain(window, undefined)
  }

  const labId = match.params.id

  const dispatch = useDispatch()

  const labUserList = useSelector((state) => state.systemOwnerLabUserList)
  const systemOwnerLogin = useSelector((state) => state.systemOwnerLogin)
  const userDelete = useSelector((state) => state.systemOwnerUserDelete)

  const { success: successDelete } = userDelete

  const { systemOwnerInfo, logout } = systemOwnerLogin

  const { labUsers, loading, error } = labUserList

  useEffect(() => {
    // if system own have not logged in or the token expires, the redirect to the login page
    let now = new Date()
    if (!systemOwnerInfo || systemOwnerInfo.expires < now || logout) {
      history.push('/systemowner/login')
    } else {
      dispatch({ type: SYSTEM_OWNER_GET_USER_DETAIL_RESET })
      dispatch(systemOwnerGetUserByLab(labId))
    }
  }, [history, dispatch, systemOwnerInfo, successDelete, labId, logout])

  const goToRegisterAdminHandler = () => {
    dispatch({ type: SYSTEM_OWNER_REGISTER_ADMIN_RESET })
    history.push(`/systemowner/register/${labId}`)
  }

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(systemOwnerDeleteUser(id))
    }
  }

  const headingStyle = {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginTop: '0.9rem',
  }

  return (
    <>
      <Link to='/systemowner/home' className='btn btn-light my-3'>
        Go Back
      </Link>
      <Row>
        <Col>
          <h1 style={headingStyle}>Lab Users</h1>
        </Col>
        <Col className='text-right'>
          <Button className='my-2' onClick={goToRegisterAdminHandler}>
            <i className='fas fa-plus'></i> Register a new user
          </Button>
        </Col>
      </Row>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Table
          striped
          bordered
          responsive
          hover
          className='table-sm text-center'
        >
          <thead>
            <tr>
              <td>ID</td>
              <td>EMAIL</td>
              <td>MANAGER</td>
              <td>CREATED AT</td>
              <td>EDIT</td>
            </tr>
          </thead>
          <tbody>
            {labUsers &&
              labUsers.map((labUser) => (
                <tr key={labUser._id}>
                  <td>{labUser._id}</td>
                  <td>{labUser.email}</td>
                  <td>
                    {' '}
                    {labUser.isLabManager ? (
                      <i
                        className='fas fa-check'
                        style={{ color: 'green' }}
                      ></i>
                    ) : (
                      <i className='fas fa-times' style={{ color: 'red' }}></i>
                    )}
                  </td>
                  <td>{labUser.createdAt.substring(0, 10)}</td>
                  <td>
                    <LinkContainer
                      to={`/systemowner/user/${labUser._id}/edit?labId=${labId}`}
                    >
                      <Button variant='light' className='btn-sm'>
                        <i className='fas fa-edit'></i>
                      </Button>
                    </LinkContainer>
                    <Button
                      variant='danger'
                      className='btn-sm'
                      onClick={() => deleteHandler(labUser._id)}
                    >
                      <i className='fas fa-trash'></i>
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  )
}

export default SystemOwnerListingUserScreen
