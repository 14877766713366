import React, { useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'

export const ModalContainer = ({ title, children, show, handleClose }) => {
  useEffect(() => {}, [children])

  return (
    <Modal show={show} size='lg' onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalContainer
