import FormContainer from '../components/FormContainer'
import { Row, Col } from 'react-bootstrap'

const headingStyle = {
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '1rem',
}

const heading2Style = {
  fontSize: '1.5rem',
  fontWeight: 'bold',
  marginBottom: '0.75rem',
}

const TermsCondScreen = () => {
  return (
    <FormContainer>
      <Row>
        <Col>
          <h2 style={headingStyle}>服務條款和細則 (簡稱“條款和細則”)</h2>
          <p>
            Longitudinax-pro.com及其子域（統稱“網站”）由Infinite 6
            Limited（簡稱“Infinite 6”）所持有。Infinite 6(統稱“本公司”)
            除了通過網站提供服務，還會透過由本公司開發的流動應用程式程式(其包括流動應用程式)（簡稱“應用程式”）向用戶提供服務。網站和應用程序
            (簡稱或統稱“平臺”)。用戶瀏覽和使用由平臺所提供的服務必須遵守此條款和細則（列於下文）及私隱政策（簡稱“條款和細則”）。
          </p>

          <p>
            通過使用本平臺的服務，你會被視為接受並同意遵守條款和細則及私隱政策。若你不接受任何條款和細則及私隱政策，請不要使用本平臺的服務。本公司保留權利在沒有預先通知的情況下，隨時修改條款和細則及私隱政策。一旦在平臺上發佈，修訂後的條款和細則及私隱政策將適用於所有用戶。本公司建議你應定期瀏覽此頁面，查看最新條款和細則及私隱政策。你一旦通知本公司不接受任何修訂，其應立即終止使用本平臺的服務，否則繼續使用本平臺的服務將被視為接受所有修訂並受其約束。
          </p>

          <p>
            你進一步接受並同意，你應就其使用平臺遵守所有適用的法律、法規、條例和規章（不論是否具有法律效力的）。你了解到互聯網之全球性質並瞭解和同意他們或須遵守其司法管轄區以外適用的法律、
            法規、
            條例或規章。術語“用戶”是指所有的個人/實體在任何時候，無論是在香港或其他地方，以任何理由或目的登入和/或使用渠道。
          </p>

          <h3 style={heading2Style}>1. 平臺的使用</h3>
          <p>
            根據使用平臺的條款及細則，你只可以到訪及使用平臺進行你個人及非商業上的使用。其他方面的使用須事先獲得我們的書面同意，對於批准或拒絕你的要求，我們有絕對酌情權。
          </p>

          <h3 style={heading2Style}>2. 註冊及密碼</h3>
          <p>
            作爲探訪者，你可以以匿名身份到訪及使用平臺和應用程序而毋須提供個人識別資料。但爲了可以登入本平臺的某些服務及内容，例如問卷調查，你需要註冊成爲會員，並不能使用別名或其他的方法隱瞞你真正的身份。在進行註冊的時候，你需要選擇註冊電郵和密碼於登入時使用。請注意我們不接受暫時性電郵地址作為註冊電郵。在任何時候你都應小心保管密碼，你有責任保管好該密碼及對該密碼進行的任何種類的使用或不獲授權的使用，你有絕對的法律責任。我們可在有通知或無通知的情況下，隨時暫停或終止你的會籍。
          </p>

          <h3 style={heading2Style}>3. 不獲授權的使用</h3>
          <p>
            你不可以使用蜘蛛（spiders）、漫遊器（robots）、暫時性電郵地址或其他的自動數據挖掘技術將本平臺的内容紀錄、下載、儲存或者以其他方式複製或發佈。你不可以採取任何行動干擾本平臺或任何其他使用者使用本平臺，其中包括但不限於通過任何渠道使本平臺超過負荷（overloading）、"
            泛洪（flooding）"、"濫發電郵"或"撞擊（crashing）"。你不可以在沒有獲得收件者的事先同意使用本平臺發送任何電郵，包括推廣及/或宣傳/廣告產品或服務給任何人，或於本平臺的任何電郵或郵件中假冒或隱瞞你的真正身份。你必須遵守所有適用的法律，包括但不限於CAN-SPAM
            Act 2003 (美國)。你不可於其他媒體捏造本平臺的任何部分。
            你不得郵寄或傳送任何具威脅、誹謗 、
            破壞名譽、淫穢、造謠中傷或煽情内容的資料或任何可能觸犯法律的資料。如有任何執法機構或法院要求或命令Infinite
            6透露發佈此種資料或物件的人士的身份，Infinite 6將會全面配合。
          </p>

          <h3 style={heading2Style}>4. 知識產權</h3>
          <p>
            本平臺的所有頁面及内容，包括但不限於HTML編碼、軟件、文本、圖畫、音頻、視頻、照片、調查、標誌或其他東西皆屬於Infinite
            6及其授權者、業務夥伴及附屬機構的知識產權或獲其授權使用，其中包括註冊商標、服務商標、版權、專利權及貿易秘密。除了在本條款及細則中有明確表示准許外，否則在沒有事先得到Infinte
            6的書面同意，你不可以以任何方式修改、複印、複製、創造衍生作品、重新發行、展示、上載、張貼、傳送或發佈本平臺的内容，包括但不限於字體、圖像、連結按鈕、牆紙及桌面主題。
          </p>

          <h3 style={heading2Style}>5. 第三方的資料</h3>
          <p>
            本平臺可能含有（i）非由Infinite
            6所管理的網站的連結或（ii）第三方的產品或服務資料。Infinite
            6不會驗證這些第三方的商業操守及不會代他們做保證。我們鼓勵你離開本平臺到其他網站時要小心，並且細閱它們的條款及細則。本平臺的內容謹供參考。本網站的觀點和意見並不代表Infinite
            6的立場。
          </p>

          <h3 style={heading2Style}>6. 私隱</h3>
          <p>
            Infinite
            6高度重視你的私隱及個人資料。我們的條款及細則中列明我們的私隱政策。為進一步了解我們如何處理及使用從你那裡收集得來的資料，以及何時及作何用途，請瀏覽我們的私隱政策。
          </p>

          <h3 style={heading2Style}>7. 免責條款聲明</h3>
          <p>
            在本平臺所提供的，包括所有載於本平臺或通過本平臺登入的内容和服務皆為"如其當時的狀況"。在法律允許的最大範圍內，就本平臺内容而言，Infinite
            6不會作任何形式的代表或保證。此外，Infnite
            6免責於任何明示或暗示保證，包括但不限於不侵權、所有權、商業適售性或是否適合作某種特定用途。Infinite
            6不保證載於本平臺内的功能或任何資料或內容不會受干擾或全無錯。問題將會獲得糾正，或讓該等問題出現的本平臺或伺服器將獲得糾正，或讓該等問題出現的本平臺或伺服器不受病毒或其他有害成分所影響。在法律允許的最大範圍內，任何因侵權、合同、嚴重的法律責任或其他法律或衡平法的原則而引致任何利潤受損、遺失數據、錯過機會、賠償費用、損害賠償、個人損傷/錯誤致死、特別、附帶、間接或其他連帶的損害，Infinite
            6一概不負任何法律責任。雙方現在同意對上述各項損害不需予以責任，無論任何一方是否已獲悉該等損害可能會出現。
          </p>

          <h3 style={heading2Style}>8. 更改</h3>
          <p>
            所有張貼在本平臺的資料如有變更，恕不另行通知。另外，本條款及細則會隨時更改而不會事先通知。我們會將更改的資料公佈於本平臺並在公佈時即時生效。你需要經常查閲本網站以獲知更改的資料。你在獲知該更改後仍繼續到訪及使用本網站，則視作你已接受該更改。
          </p>

          <h3 style={heading2Style}>9. 賠償</h3>
          <p>
            你同意就因你違反條款及細則或相關的法律所引致的或與之相關的任何由或向第三方提出的索償、索求、法律責任、費用或支出，包括合理的法律費用及支出，而為Infinite
            6、其僱員、董事、人員、代理人或代表作出賠償、辯護，和使該等人士免受損害。
          </p>

          <h3 style={heading2Style}>10. 可分割性</h3>
          <p>
            即使具有司法管轄權的法院因任何理由而裁斷本條款及細則的任何部分應被擱置或宣佈無效或不可強制執行，該條文應屬無效但本條款及細則內的其餘部分則不受影響。
          </p>

          <h3 style={heading2Style}>11. 管轄法律</h3>
          <p>
            本條款及細則應受香港法律的規管。你同意該專屬管轄權和法院行使管轄權的地點。
          </p>

          <h3 style={heading2Style}>12. 查詢</h3>
          <p>如對本條款與細則有任何疑問，你可電郵至admin@longitudinax.com。</p>
        </Col>
      </Row>
    </FormContainer>
  )
}

export default TermsCondScreen
