/* This screen for system owner to create new lab (including the lab manger) */

import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import { FormContainer } from '../components/FormContainer'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { systemOwnerCreateLab } from '../actions/labActions'
import { SYSTEM_OWNER_CREATE_LABS_RESET } from '../constants/labConstants'

import Organizations from '../../enums/organizations'
import getSubdomain from '../../getSubdomain'
import findOrganizationBySubdomain from '../../findOrganizationBySubdomain'
import redirectDueToInvalidSubdomain from '../../redirectDueToInvalidSubdomain'

const SystemOwnerCreateLabScreen = ({ history }) => {
  // Make sure system owner is only accessible through longitudinax site
  const subdomain = getSubdomain(window)
  const siteOrganization = findOrganizationBySubdomain(subdomain)
  if (siteOrganization !== Organizations.Longitudinax) {
    // Keep the site subdomain by providing undefined to the second parameter
    redirectDueToInvalidSubdomain(window, undefined)
  }

  const dispatch = useDispatch()

  const labCreate = useSelector((state) => state.systemOwnerLabCreate)
  const { loading, error, success } = labCreate

  const systemOwnerLogin = useSelector((state) => state.systemOwnerLogin)
  const { systemOwnerInfo, logout } = systemOwnerLogin

  const [labName, setLabName] = useState('')
  const [email, setEmail] = useState('')
  const [organization, setOrganization] = useState(Organizations.Longitudinax)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState('')

  function validateEmail(email) {
    let re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  useEffect(() => {
    let now = new Date()
    if (!systemOwnerInfo || systemOwnerInfo.expires < now || logout) {
      history.push('/systemowner/login')
    } else {
      if (success) {
        history.push('/systemowner/home')
        dispatch({ type: SYSTEM_OWNER_CREATE_LABS_RESET })
      }
    }
  }, [history, systemOwnerInfo, success, dispatch, logout])

  const submitHandler = (e) => {
    e.preventDefault()

    if (password !== confirmPassword) {
      setMessage('Passwords do not match')
    } else if (!validateEmail(email)) {
      setMessage('Please input valid email')
    } else {
      dispatch(systemOwnerCreateLab({ labName, email, password, organization }))
    }
  }

  return (
    <>
      <Link to='/systemowner/home' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1 className='py-3'>Create New Lab</h1>
        {error && <Message variant='danger'>{error}</Message>}
        {message && <Message variant='danger'>{message}</Message>}
        {loading && <Loader />}

        <Form onSubmit={submitHandler} className='mt-3'>
          <Form.Group controlId='organizationChoices'>
            <Form.Label>Organization</Form.Label>
            <Form.Control
              as='select'
              value={organization}
              onChange={(e) => {
                setOrganization(e.target.value)
              }}
            >
              {Object.keys(Organizations).map((orgName, index) => (
                <option value={Organizations[orgName]} key={orgName}>
                  {orgName}
                </option>
              ))}
            </Form.Control>{' '}
          </Form.Group>

          <Form.Group controlId='labName'>
            <Form.Label>Lab Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter lab name'
              value={labName}
              onChange={(e) => setLabName(e.target.value)}
              required
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='email'>
            <Form.Label> Lab Manager Email Address</Form.Label>
            <Form.Control
              type='email'
              placeholder='Enter email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='password'>
            <Form.Label> Lab Manager Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Enter password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='confirmPassword'>
            <Form.Label> Confirm Lab Manager Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Confirm password'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            ></Form.Control>
          </Form.Group>

          <Button type='submit' variant='primary mt-2 ' className='btn btn-lg'>
            Create Lab
          </Button>
        </Form>
      </FormContainer>
    </>
  )
}

export default SystemOwnerCreateLabScreen
