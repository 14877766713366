// get media files (admin)
export const MEDIA_FILES_REQUEST = 'MEDIA_FILES_REQUEST'
export const MEDIA_FILES_SUCCESS = 'MEDIA_FILES_SUCCESS'
export const MEDIA_FILES_FAIL = 'MEDIA_FILES_FAIL'
export const MEDIA_FILES_LOGOUT = 'MEDIA_FILES_LOGOUT'
export const MEDIA_FILES_RESET = 'MEDIA_FILES_RESET'

// create a media file (admin)
export const CREATE_MEDIA_FILES_REQUEST = 'CREATE_MEDIA_FILES_REQUEST'
export const CREATE_MEDIA_FILES_SUCCESS = 'CREATE_MEDIA_FILES_SUCCESS'
export const CREATE_MEDIA_FILES_FAIL = 'CREATE_MEDIA_FILES_FAIL'
export const CREATE_MEDIA_FILES_RESET = 'CREATE_MEDIA_FILES_RESET'

// get single media file (admin)
export const GET_MEDIA_FILES_REQUEST = 'GET_MEDIA_FILES_REQUEST'
export const GET_MEDIA_FILES_SUCCESS = 'GET_MEDIA_FILES_SUCCESS'
export const GET_MEDIA_FILES_FAIL = 'GET_MEDIA_FILES_FAIL'

// update a media file (admin)
export const UPDATE_MEDIA_FILES_REQUEST = 'UPDATE_MEDIA_FILES_REQUEST'
export const UPDATE_MEDIA_FILES_SUCCESS = 'UPDATE_MEDIA_FILES_SUCCESS'
export const UPDATE_MEDIA_FILES_FAIL = 'UPDATE_MEDIA_FILES_FAIL'
export const UPDATE_MEDIA_FILES_RESET = 'UPDATE_MEDIA_FILES_RESET'

// delete a media file (admin)
export const DELETE_MEDIA_FILES_REQUEST = 'DELETE_MEDIA_FILES_REQUEST'
export const DELETE_MEDIA_FILES_SUCCESS = 'DELETE_MEDIA_FILES_SUCCESS'
export const DELETE_MEDIA_FILES_FAIL = 'DELETE_MEDIA_FILES_FAIL'

// get media files by keyword
export const GET_TARGET_MEDIA_FILE_REQUEST = 'GET_TARGET_MEDIA_FILE_REQUEST'
export const GET_TARGET_MEDIA_FILE_SUCCESS = 'GET_TARGET_MEDIA_FILE_SUCCESS'
export const GET_TARGET_MEDIA_FILE_FAIL = 'GET_TARGET_MEDIA_FILE_FAIL'
