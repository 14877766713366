import React, { useState, useEffect } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import SearchBox from '../../media_module/components/SearchBox'
//***********************************
//It is also a working code for directly updating fields of prop(s),
// while not affecting UI performance
//************************************

export const ResponseSettings = ({ qx, qIndex, rIndex, response, mcList }) => {
  // For mc type
  const [responseChoices, setResponseChoices] = useState('')
  const [mcAllowMultiple, setMcAllowMultiple] = useState('')
  // For text type
  const [responsePreLabel, setResponsePreLabel] = useState('')
  const [responsePostLabel, setResponsePostLabel] = useState('')
  const [responseNumberOnly, setResponseNumberOnly] = useState(false)
  const [textValidation, setTextValidation] = useState('')
  const [prevQuestionSame, setPrevQuestionSame] = useState(false)
  // For duration type
  const [responseDay, setResponseDay] = useState(false)
  const [responseHour, setResponseHour] = useState(false)
  const [responseMinute, setResponseMinute] = useState(false)
  // For time type
  const [responsePickerFormat, setResponsePickerFormat] = useState('hh mm A')
  const [responseInstructionMsg, setResponseInstructionMsg] =
    useState('請選擇時間...')
  const [questions, setQuestions] = useState([])
  const [requirement, setRequirement] = useState('')
  const [requirementQuestionPos, setRequirementQuestionPos] = useState('')
  const [requirementResponsePos, setRequirementResponsePos] = useState('')
  // For media type
  const [fileSource, setFileSource] = useState('') // 'uploaded' or 'youTube' or 'imageUrl'
  const [fileType, setFileType] = useState('') // 'video' or 'audio' or 'pic'
  const [fileUrl, setFileUrl] = useState('') // website url when file source is 'url', otherwise it is the relative path
  // For slider type
  const [sliderMaxLabel, setSliderMaxLabel] = useState('')
  const [sliderMax, setSliderMax] = useState('')
  const [sliderMinLabel, setSliderMinLabel] = useState('')
  const [sliderMin, setSliderMin] = useState('')

  // For updating validation rule for text tyep
  const handleTextValidation = (request) => {
    if (request === 'email') {
      response.validation['regex_string'] = '^\\S+@\\S+\\.\\S+$'
    }
    if (request === 'eightDigits') {
      response.validation['regex_string'] = '^\\d{8}$'
    }
    if (request === '' && response.validation.regex_string) {
      response.validation.regex_string = ''
    }
    if (request === '' && response.validation.identical_to_prev_question) {
      response.validation.identical_to_prev_question = false
    }
  }

  // For updating validation rule for time type
  const handleRequirement = (request) => {
    if (request === 'earlier' && !requirement) {
      response.validation[request] = { target_qpos: -1, target_rpos: -1 }
    }
    if (request === '' && response.validation.earlier) {
      delete response.validation.earlier
    }
  }

  useEffect(() => {
    setQuestions(qx.chin_content)
    // For slider type
    if (response.max_label) setSliderMaxLabel(response.max_label)
    if (response.max !== undefined) setSliderMax(response.max)
    if (response.min_label) setSliderMinLabel(response.min_label)
    if (response.min !== undefined) setSliderMin(response.min)
    // For media type
    if (response.media_source) setFileSource(response.media_source)
    if (response.media_type) setFileType(response.media_type)
    if (response.media_url) setFileUrl(response.media_url)
    // For mc type
    if (response.choices) setResponseChoices(response.choices)
    if (response.multiple !== undefined) setMcAllowMultiple(response.multiple)
    // For text type
    if (response.pre_label) setResponsePreLabel(response.pre_label)
    if (response.post_label) setResponsePostLabel(response.post_label)
    if (response.number_only) setResponseNumberOnly(response.number_only)
    if (response.validation.regex_string) {
      if (response.validation.regex_string === '^\\S+@\\S+\\.\\S+$') {
        setTextValidation('email')
      }
      if (response.validation.regex_string === '^\\d{8}$') {
        setTextValidation('eightDigits')
      }
    }
    if (response.validation.identical_to_prev_question) {
      setPrevQuestionSame(true)
    }
    // For duration type
    if (response.day) setResponseDay(response.day)
    if (response.hour) setResponseHour(response.hour)
    if (response.minute) setResponseMinute(response.minute)
    // For time type
    if (response.picker_format) setResponsePickerFormat(response.picker_format)
    if (response.instruction_msg)
      setResponseInstructionMsg(response.instruction_msg)
    if (response.validation.earlier) {
      setRequirement('earlier')
      setRequirementQuestionPos(response.validation.earlier.target_qpos)
      setRequirementResponsePos(response.validation.earlier.target_rpos)
    } else {
      setRequirement('')
      setRequirementQuestionPos('')
      setRequirementResponsePos('')
    }
  }, [qx, response, requirement, requirementQuestionPos])

  const removeTag = (question) => {
    return question.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, '')
  }

  const prevQuestionIsTextBox = () => {
    // If the current or previous question has more than one responses, it is not applicable
    if (
      qIndex === 0 ||
      rIndex !== 0 ||
      qx.chin_content[qIndex - 1].other_info.length !== 1 ||
      qx.chin_content[qIndex - 1].other_info[0].type !== 'text'
    ) {
      if (prevQuestionSame) {
        setPrevQuestionSame(false)
        response.validation.identical_to_prev_question = false
      }
      return false
    } else {
      return true
    }
  }

  return (
    <Row>
      {response.type === 'mc' && (
        <>
          <Col md={4}>
            {mcList && (
              <Form.Group controlId={'rchoices' + response._id}>
                <Form.Label>Choices Set</Form.Label>
                <Form.Control
                  as='select'
                  value={responseChoices}
                  onChange={(e) => {
                    setResponseChoices(e.target.value)
                    response.choices = e.target.value
                  }}
                >
                  <option value='' key='emptyMCChoice'>
                    Please Select...
                  </option>
                  {mcList.map((mc, index) => (
                    <option value={mc._id} key={mc._id}>
                      {mc.name}
                    </option>
                  ))}
                </Form.Control>{' '}
              </Form.Group>
            )}
          </Col>
          <Col md={4}>
            <Form.Group controlId={'rchoices_multiple' + response._id}>
              <Form.Check
                type='checkbox'
                label='Allow multiple selection'
                checked={mcAllowMultiple}
                onChange={(e) => {
                  setMcAllowMultiple(e.target.checked)
                  response.multiple = e.target.checked
                }}
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <p>
              Please Click the button 'Create or Modify Multiple Choices' at the
              bottom of this page to create/modify multiple choices
            </p>
          </Col>
        </>
      )}
      {response.type === 'text' && (
        <>
          <Col md={4}>
            <Form.Group controlId={'rprelabel' + response._id}>
              <Form.Label>Words Before Text Box</Form.Label>
              <Form.Control
                type='text'
                value={responsePreLabel}
                placeholder='Optional'
                onChange={(e) => {
                  setResponsePreLabel(e.target.value)
                  response.pre_label = e.target.value
                }}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId={'rpostlabel' + response._id}>
              <Form.Label>Words After Text Box</Form.Label>
              <Form.Control
                type='text'
                value={responsePostLabel}
                placeholder='Optional'
                onChange={(e) => {
                  setResponsePostLabel(e.target.value)
                  response.post_label = e.target.value
                }}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId={'rnumonly' + response._id}>
              <Form.Label>Constraint response to numbers?</Form.Label>
              <Form.Control
                as='select'
                value={responseNumberOnly}
                onChange={(e) => {
                  setResponseNumberOnly(e.target.value)
                  response.number_only = e.target.value
                  if (e.target.value !== responseNumberOnly) {
                    setTextValidation('')
                    response.validation.regex_string = ''
                  }
                }}
              >
                <option value='true'>Yes</option>
                <option value='false'>No</option>
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId={'textResponseCheck_' + response._id}>
              <Form.Label>Response Check</Form.Label>
              <Form.Control
                as='select'
                value={textValidation}
                onChange={(e) => {
                  setTextValidation(e.target.value)
                  handleTextValidation(e.target.value)
                }}
              >
                <option value=''>No Response check</option>
                {(responseNumberOnly === 'false' ||
                  responseNumberOnly === false) && (
                  <option value='email'>Has to be an email address</option>
                )}
                {(responseNumberOnly === 'true' ||
                  responseNumberOnly === true) && (
                  <option value='eightDigits'>Has 8 digits</option>
                )}
              </Form.Control>
            </Form.Group>
          </Col>
          {prevQuestionIsTextBox() && (
            <Col md={12}>
              <Form.Group controlId={'prevQuestioneSame_' + response._id}>
                <Form.Check
                  type='checkbox'
                  label='Has to be identical to the text box response in the previous
                question'
                  checked={prevQuestionSame}
                  onChange={(e) => {
                    setPrevQuestionSame(e.target.checked)
                    response.validation.identical_to_prev_question =
                      e.target.checked
                  }}
                />
              </Form.Group>
            </Col>
          )}
        </>
      )}
      {response.type === 'duration' && (
        <>
          <Col md={4}>
            <Form.Group controlId={'rday' + response._id}>
              <Form.Check
                type='checkbox'
                label='Allow choosing number of days'
                checked={responseDay}
                onChange={(e) => {
                  setResponseDay(e.target.checked)
                  response.day = e.target.checked
                }}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId={'rhour' + response._id}>
              <Form.Check
                type='checkbox'
                label='Allow choosing number of hours'
                checked={responseHour}
                onChange={(e) => {
                  setResponseHour(e.target.checked)
                  response.hour = e.target.checked
                }}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId={'rminute' + response._id}>
              <Form.Check
                type='checkbox'
                label='Allow choosing number of minutes'
                checked={responseMinute}
                onChange={(e) => {
                  setResponseMinute(e.target.checked)
                  response.minute = e.target.checked
                }}
              />
            </Form.Group>
          </Col>
        </>
      )}
      {response.type === 'time' && (
        <>
          <Col md={4}>
            <Form.Group controlId={'rpickerformat' + response._id}>
              <Form.Label>Response Format</Form.Label>
              <Form.Control
                as='select'
                value={responsePickerFormat}
                onChange={(e) => {
                  setResponsePickerFormat(e.target.value)
                  response.picker_format = e.target.value
                  if (response.picker_format === 'hh mm A') {
                    setResponseInstructionMsg('請選擇時間...')
                    response.instruction_msg = '請選擇時間...'
                  }
                  if (response.picker_format === 'DD MM YYYY') {
                    setResponseInstructionMsg('請選擇日期...')
                    response.instruction_msg = '請選擇日期...'
                  }
                }}
              >
                <option value='hh mm A'>Hour | Minute</option>
                <option value='DD MM YYYY'>Day | Month | Year</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={8}>
            <Form.Group controlId={'rinstructionmsg' + response._id}>
              <Form.Label>Instruction text</Form.Label>
              <Form.Control
                type='text'
                value={responseInstructionMsg}
                onChange={(e) => {
                  setResponseInstructionMsg(e.target.value)
                  response.instruction_msg = e.target.value
                }}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId={''}>
              <Form.Label>Response Check</Form.Label>
              <Form.Control
                as='select'
                value={requirement}
                onChange={(e) => {
                  setRequirement(e.target.value)
                  handleRequirement(e.target.value)
                }}
              >
                <option value=''>No Response check</option>
                <option value='earlier'>Has to be earlier than</option>
              </Form.Control>
            </Form.Group>
          </Col>
          {requirement !== '' && (
            <Col md={6}>
              <Form.Group controlId={''}>
                <Form.Label>Question</Form.Label>
                <Form.Control
                  as='select'
                  value={requirementQuestionPos}
                  onChange={(e) => {
                    setRequirementQuestionPos(Number(e.target.value))
                    setRequirementResponsePos(1)
                    response.validation.earlier.target_qpos = Number(
                      e.target.value
                    )
                    response.validation.earlier.target_rpos = 1
                  }}
                >
                  <option value='-1'>Select a question.</option>
                  {questions.map((question, index) => {
                    if (
                      question.other_info[0].type === 'time' &&
                      question.position - 1 !== qIndex
                    ) {
                      return (
                        <option
                          value={question.position}
                          key={question._id + 'subreq'}
                        >
                          {question.question_num}{' '}
                          {removeTag(question.html_question)}
                        </option>
                      )
                    } else {
                      return <></>
                    }
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
          )}

          {/*requirement !== '' &&
            requirementQuestion !== '' &&
            questionsLoaded &&
            questions[requirementQuestionPos - 1] && (
              <Col md={4}>
                <Form.Group controlId={''}>
                  <Form.Label>Response</Form.Label>
                  <Form.Control
                    as='select'
                    value={requirementResponse - 1}
                    onChange={(e) => {
                      setRequirementResponse(e.target.value + 1)
                      response.validation.earlier.target_rpos =
                        parseInt(e.target.value) + 1
                    }}
                  >
                    <option value='-1'>Select a response.</option>
                    {questions[requirementQuestionPos - 1].other_info.map(
                      (response, index) => (
                        <option value={index} key={response._id + 'subreqRes'}>
                          Response {index + 1} ({response.instruction_msg})
                        </option>
                      )
                    )}
                  </Form.Control>
                </Form.Group>
              </Col>
                      )*/}
        </>
      )}
      {response.type === 'slider' && (
        <>
          <Col md={4}>
            <Form.Group controlId={'sliderMinlabel' + response._id}>
              <Form.Label>Minimum Label</Form.Label>
              <Form.Control
                type='text'
                value={sliderMinLabel}
                placeholder='Enter Text'
                onChange={(e) => {
                  setSliderMinLabel(e.target.value)
                  response.min_label = e.target.value
                }}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId={'sliderMin' + response._id}>
              <Form.Label>Min Value</Form.Label>
              <Form.Control
                type='number'
                value={sliderMin}
                placeholder='Enter Value'
                onChange={(e) => {
                  setSliderMin(e.target.value)
                  response.min = e.target.value
                }}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId={'sliderMaxlabel' + response._id}>
              <Form.Label>Maximum Label</Form.Label>
              <Form.Control
                type='text'
                value={sliderMaxLabel}
                placeholder='Enter Text'
                onChange={(e) => {
                  setSliderMaxLabel(e.target.value)
                  response.max_label = e.target.value
                }}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId={'sliderMax' + response._id}>
              <Form.Label>Max Value</Form.Label>
              <Form.Control
                type='number'
                value={sliderMax}
                placeholder='Enter Value'
                onChange={(e) => {
                  setSliderMax(e.target.value)
                  response.max = e.target.value
                }}
              ></Form.Control>
            </Form.Group>
          </Col>
        </>
      )}

      {response.type === 'media' && (
        <>
          <Col md={6}>
            <Form.Group controlId={'media_type' + response._id}>
              <Form.Label>File Type</Form.Label>
              <Form.Control
                as='select'
                value={fileType}
                onChange={(e) => {
                  setFileType(e.target.value)
                  setFileUrl('')
                  response.media_type = e.target.value
                  response.media_source = ''
                  response.media_url = ''
                }}
              >
                <option value=''>Select...</option>
                <option value='video'>Video (.mp4)</option>
                <option value='audio'>Audio (.mp3)</option>
                <option value='image'>Picture (.png or .jpeg or .jpg)</option>
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId={'media_source' + response._id}>
              <Form.Label>Source</Form.Label>
              <Form.Control
                as='select'
                value={fileSource}
                onChange={(e) => {
                  setFileSource(e.target.value)
                  setFileUrl('')
                  response.media_source = e.target.value
                  response.media_url = ''
                }}
              >
                <option value=''>Select...</option>
                <option value='uploaded'>From files uploaded in system</option>
                {response.media_type === 'video' && (
                  <option value='youTube'>YouTube video embeded url</option>
                )}
                {response.media_type === 'image' && (
                  <option value='imageUrl'>Image url</option>
                )}
              </Form.Control>
            </Form.Group>
          </Col>

          {response.media_type && response.media_source === 'uploaded' && (
            <Col md={9}>
              <SearchBox
                fileType={response.media_type}
                filePath={response.media_url}
                onSelectFile={(file) => {
                  response.media_url = file.file
                }}
              />
            </Col>
          )}

          {response.media_type &&
            (response.media_source === 'youTube' ||
              response.media_source === 'imageUrl') && (
              <Col md={9}>
                <Form.Group controlId={'media_url_external' + response._id}>
                  <Form.Label>Hyperlink of the media file</Form.Label>
                  <Form.Control
                    type='text'
                    value={fileUrl}
                    placeholder='Please type the URL here'
                    onChange={(e) => {
                      setFileUrl(e.target.value)
                      response.media_url = e.target.value
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
            )}
        </>
      )}
    </Row>
  )
}

export default ResponseSettings
