import React, { useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'

export const ModalContainer = ({
  title,
  children,
  show,
  handleSave,
  handleClose,
  showSave,
}) => {
  useEffect(() => {}, [children])

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
        {showSave && (
          <Button variant='primary' onClick={handleSave}>
            Save Changes
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default ModalContainer
