/* This screen for system owner to list all users in the lab */

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateFitbitToken } from '../actions/participantActions'

const FitbitOauthCallbackScreen = ({ history }) => {
  const dispatch = useDispatch()

  const updateFitbitState = useSelector((state) => state.updateFitbitToken)
  const { error, loading, success } = updateFitbitState

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (!params.has('code') || !params.has('state')) {
      history.push('/login')
    } else {
      console.log(error)
      if (!loading && !error && !success) {
        dispatch(updateFitbitToken(params.get('code'), params.get('state')))
      }
    }
  }, [dispatch, history, error, loading, success])

  return (
    <>
      {loading ? (
        <p>Integrating your Fitbit account...</p>
      ) : (
        <div>
          {error ? (
            <p>{error}</p>
          ) : (
            <p>
              Complete integration with Fitbit. You may now close this browser
              and get back to Good Shleep.
            </p>
          )}
        </div>
      )}
    </>
  )
}

export default FitbitOauthCallbackScreen
