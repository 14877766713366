const getDefaultEmaAx = () => {
  return {
    questionnaire_list: [],
    time_list: [], //in HH:mm, 24-hour format. In timezone, not in unix time
    random_settings: undefined, // TBD
    timezone: 'Hongkong',
    day_one_eq_same_day: true,
    reminder_interval: 5, //in minutes
    response_in: 15, //in minutes
  }
}

export default getDefaultEmaAx
