const getChoicesByResponsePosition = (question, rPos, mcList) => {
  const getMcByID = (id) => {
    return mcList.find(({ _id }) => _id === id)
  }
  let set = undefined
  if (question.other_info[rPos - 1].choices) {
    let mc = getMcByID(question.other_info[rPos - 1].choices)
    if (mc) {
      set = mc.set
    }
  }
  if (set) {
    return set.map((x, position) => (
      <option
        key={'dependency_mc_choice' + x._id + question.position + rPos}
        value={x.value}
      >
        {x.name}
      </option>
    ))
  }
}

export default getChoicesByResponsePosition
