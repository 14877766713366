import {
  CREATE_MEDIA_FILES_FAIL,
  CREATE_MEDIA_FILES_REQUEST,
  CREATE_MEDIA_FILES_RESET,
  CREATE_MEDIA_FILES_SUCCESS,
  DELETE_MEDIA_FILES_FAIL,
  DELETE_MEDIA_FILES_REQUEST,
  DELETE_MEDIA_FILES_SUCCESS,
  GET_MEDIA_FILES_FAIL,
  GET_MEDIA_FILES_REQUEST,
  GET_MEDIA_FILES_SUCCESS,
  GET_TARGET_MEDIA_FILE_FAIL,
  GET_TARGET_MEDIA_FILE_REQUEST,
  GET_TARGET_MEDIA_FILE_SUCCESS,
  MEDIA_FILES_FAIL,
  MEDIA_FILES_LOGOUT,
  MEDIA_FILES_REQUEST,
  MEDIA_FILES_RESET,
  MEDIA_FILES_SUCCESS,
  UPDATE_MEDIA_FILES_FAIL,
  UPDATE_MEDIA_FILES_REQUEST,
  UPDATE_MEDIA_FILES_RESET,
  UPDATE_MEDIA_FILES_SUCCESS,
} from '../constants/mediaConstants'

/************ get all media files (admin) **********/
export const mediaFilesReducer = (state = { files: [] }, action) => {
  switch (action.type) {
    case MEDIA_FILES_REQUEST:
      return { loading: true, logout: false, success: false, files: [] }
    case MEDIA_FILES_SUCCESS:
      return {
        loading: false,
        success: true,
        logout: false,
        files: action.payload.files,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case MEDIA_FILES_FAIL:
      return { loading: false, logout: false, error: action.payload }
    case MEDIA_FILES_LOGOUT:
      return { loading: false, logout: true }
    case MEDIA_FILES_RESET:
      return { files: [] }
    default:
      return state
  }
}

/************ create media files (admin) **********/
export const mediaFilesCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_MEDIA_FILES_REQUEST:
      return { loading: true }

    case CREATE_MEDIA_FILES_SUCCESS:
      return { loading: false, success: true }

    case CREATE_MEDIA_FILES_FAIL:
      return { loading: false, error: action.payload }

    case CREATE_MEDIA_FILES_RESET:
      return {}

    default:
      return state
  }
}

/************ delete a media file (admin) **********/

export const mediaFilesDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_MEDIA_FILES_REQUEST:
      return { loading: true }

    case DELETE_MEDIA_FILES_SUCCESS:
      return { loading: false, success: true }

    case DELETE_MEDIA_FILES_FAIL:
      return { loading: false, error: action.payload }

    default:
      return state
  }
}

/************ get single media files (admin) **********/

export const mediaFilesGetReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MEDIA_FILES_REQUEST:
      return { loading: true }

    case GET_MEDIA_FILES_SUCCESS:
      return { loading: false, file: action.payload }

    case GET_MEDIA_FILES_FAIL:
      return { loading: false, error: action.payload }

    default:
      return state
  }
}

/************** update single media file (admin) **********/

export const mediaFileUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MEDIA_FILES_REQUEST:
      return { loading: true }

    case UPDATE_MEDIA_FILES_SUCCESS:
      return { loading: false, success: true }

    case UPDATE_MEDIA_FILES_FAIL:
      return { loading: false, error: action.payload }

    case UPDATE_MEDIA_FILES_RESET:
      return {}

    default:
      return state
  }
}

/***************** get target media file (admin) ************/

export const targetMediaFileReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TARGET_MEDIA_FILE_REQUEST:
      return { loading: true }

    case GET_TARGET_MEDIA_FILE_SUCCESS:
      return { loading: false, targetFiles: action.payload }

    case GET_TARGET_MEDIA_FILE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
