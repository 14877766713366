import Organizations from './enums/organizations'

const isSubdomainValid = (subdomain) => {
  if (subdomain === undefined || subdomain === 'www') {
    return true
  }
  return Object.keys(Organizations).find(
    (key) => key.toLowerCase() === subdomain
  )
}

export default isSubdomainValid
