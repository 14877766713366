import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Row, Col, Form } from 'react-bootstrap'
import ModalContainer from './ModalContainer'

import { listStudyDetails } from '../../study_module/actions/studyActions'
import { STUDY_DETAILS_RESET } from '../../study_module/constants/studyConstants'

import { getTxPageList } from '../../tx_materials_module/actions/txMaterialsActions'
import { getQxList } from '../../qx_module/actions/qxActions'
import ObjectId from '../utils/ObjectId'

import {
  createNextTxStageInfo,
  editNextTxStageInfo,
} from '../actions/txProgressActions'
import Loader from './Loader'
import Message from './Message'
import {
  NEXT_TX_STAGE_INFO_CREATE_RESET,
  NEXT_TX_STAGE_INFO_EDIT_RESET,
  NEXT_TX_STAGE_INFO_LIST_RESET,
} from '../constants/txProgressConstants'
import TxStageRequiredTask from './TxStageRequiredTask'
import { QX_LIST_RESET } from '../../qx_module/constants/qxConstants'
import { TX_PAGE_LIST_RESET } from '../../tx_materials_module/constants/txMaterialsConstants'

export const UpsertNextTxStageInfo = ({
  studyId,
  nextTxStageInfoList,
  updatedNextTxStageInfo,
}) => {
  const modalRef = useRef()

  //////// generate unique id from index ////////
  const generateUidFromIndex = (index) => {
    return ObjectId()
  }

  const nextTxStageInfoCreate = useSelector(
    (state) => state.nextTxStageInfoCreate
  )
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
  } = nextTxStageInfoCreate

  const nextTxStageInfoEdit = useSelector((state) => state.nextTxStageInfoEdit)
  const {
    loading: loadingEdit,
    error: errorEdit,
    success: successEdit,
  } = nextTxStageInfoEdit

  const studyDetails = useSelector((state) => state.studyDetails)
  const {
    loading: loadingInitiateStudy,
    error: errorInitiateStudy,
    study: loadedStudy,
    success: successInitiateStudy,
  } = studyDetails

  const qxList = useSelector((state) => state.qxList)
  const {
    qx_list,
    error: errorGetQxList,
    loading: loadingGetQxList,
    success: successGetQxList,
  } = qxList

  const txPageList = useSelector((state) => state.txPageList)
  const {
    txPages,
    error: errorGetPageList,
    loading: loadingGetPageList,
    success: successGetPageList,
  } = txPageList

  const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [saveError, setSaveError] = useState('')

  const [selectedCurrentStage, setSelectedCurrentStage] = useState('')
  const [selectedNextStage, setSelectedNextStage] = useState('')
  const [localNextTxStageInfo, setLocalNextTxStageInfo] = useState({
    current_group: '',
    next_group: '',
    assign_next_after_days: 7,
    require_read_all_content: true,
    required_tasks: [],
  })

  useEffect(() => {
    if (show && !loadingInitiateStudy && !errorInitiateStudy) {
      if (!successInitiateStudy) {
        dispatch(listStudyDetails(studyId))
      } else {
        // When updating the nextTxStageInfo
        if (updatedNextTxStageInfo) {
          setSelectedCurrentStage(
            updatedNextTxStageInfo.current_group.stage._id
          )
          setSelectedNextStage(updatedNextTxStageInfo.next_group.stage._id)
          let { _id, study, ...tempNextTxStageInfo } = updatedNextTxStageInfo
          tempNextTxStageInfo.current_group =
            tempNextTxStageInfo.current_group._id
          tempNextTxStageInfo.next_group = tempNextTxStageInfo.next_group._id
          setLocalNextTxStageInfo(tempNextTxStageInfo)
        } else {
          setSelectedCurrentStage('')
          setSelectedNextStage('')
          setLocalNextTxStageInfo({
            current_group: '',
            next_group: '',
            assign_next_after_days: 7,
            require_read_all_content: true,
            required_tasks: [],
          })
        }

        if (successCreate || successEdit) {
          dispatch({ type: NEXT_TX_STAGE_INFO_LIST_RESET })
          setShow(false)
        }

        if (!loadingGetQxList && !errorGetQxList && !successGetQxList) {
          dispatch(getQxList())
        }

        if (!errorGetPageList && !errorGetPageList && !successGetPageList) {
          dispatch(getTxPageList(studyId))
        }
      }
    }
  }, [
    show,
    successCreate,
    successEdit,
    updatedNextTxStageInfo,
    loadingInitiateStudy,
    errorInitiateStudy,
    successInitiateStudy,
    loadingGetQxList,
    errorGetQxList,
    successGetQxList,
    loadingGetPageList,
    errorGetPageList,
    successGetPageList,
    studyId,
    dispatch,
  ])

  const handleOpen = () => {
    dispatch({ type: NEXT_TX_STAGE_INFO_CREATE_RESET })
    dispatch({ type: NEXT_TX_STAGE_INFO_EDIT_RESET })
    dispatch({ type: QX_LIST_RESET })
    dispatch({ type: TX_PAGE_LIST_RESET })
    dispatch({ type: STUDY_DETAILS_RESET })
    setShow(true)
  }

  const checkSelectedGroupsNotEmpty = () => {
    return !localNextTxStageInfo.current_group ||
      !localNextTxStageInfo.next_group
      ? `Please specify 'Current Treatment Group' and 'Next Treatment Group'`
      : ''
  }

  const checkAssignNextAfterDaysNotEmpty = () => {
    return !localNextTxStageInfo.assign_next_after_days
      ? `Please specify 'Perform assignment after number of days'`
      : ''
  }

  const checkPlanExists = () => {
    return nextTxStageInfoList.find(
      (nextTxStageInfo) =>
        nextTxStageInfo.current_group === localNextTxStageInfo.current_group &&
        nextTxStageInfo.next_group === localNextTxStageInfo.next_group
    )
      ? `Treatment assignment plan with the speicified current and next treatment group already exists`
      : ''
  }

  const handleSave = (e) => {
    e.preventDefault()
    setSaveError('')
    let error = ''

    // frontend validation
    if (error.length === 0) error = checkAssignNextAfterDaysNotEmpty()
    if (error.length === 0) error = checkSelectedGroupsNotEmpty()
    if (error.length === 0) error = checkPlanExists()

    // save
    if (error.length === 0) {
      if (updatedNextTxStageInfo) {
        dispatch(
          editNextTxStageInfo(
            studyId,
            updatedNextTxStageInfo._id,
            localNextTxStageInfo
          )
        )
      } else {
        dispatch(createNextTxStageInfo(studyId, localNextTxStageInfo))
      }
    } else {
      modalRef.current.scrollTop = 0
      setSaveError(error)
    }
  }

  const handleClose = () => {
    setShow(false)
  }

  const getStage = (study, stageId) => {
    return study.stage_list.find((stage) => stage._id === String(stageId))
  }

  const getGroup = (study, stageId, groupId) => {
    let foundStage = study.stage_list.find(
      (stage) => stage._id === String(stageId)
    )
    return foundStage
      ? foundStage.participant_group_list.find(
          (group) => group._id === String(groupId)
        )
      : undefined
  }

  const addRequiredTaskHandler = () => {
    let tempNextTxStageInfo = { ...localNextTxStageInfo }
    let newTaskTemplate = {
      _id: generateUidFromIndex(tempNextTxStageInfo.required_tasks.length),
      task_type: '',
    }

    tempNextTxStageInfo.required_tasks.push(newTaskTemplate)
    setLocalNextTxStageInfo(tempNextTxStageInfo)
  }

  const requiredTaskDeletedHandler = (index) => {
    let tempNextTxStageInfo = { ...localNextTxStageInfo }
    tempNextTxStageInfo.required_tasks.splice(index, 1)
    setLocalNextTxStageInfo(tempNextTxStageInfo)
  }

  return (
    <>
      {updatedNextTxStageInfo ? (
        <Button variant='primary' className='btn-sm mx-1' onClick={handleOpen}>
          Edit
        </Button>
      ) : (
        <Button className='my-3' onClick={handleOpen}>
          <i className='fas fa-plus'></i> Create Treatment Assignment Plan
        </Button>
      )}

      <ModalContainer
        title='Setup Treatment Assignment Plan'
        show={show}
        saveAvailable={true}
        handleSave={handleSave}
        handleClose={handleClose}
        showSave={true}
        modalRef={modalRef}
      >
        {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
        {errorEdit && <Message variant='danger'>{errorEdit}</Message>}
        {saveError && <Message variant='danger'>{saveError}</Message>}

        {loadingCreate || loadingEdit ? (
          <Loader />
        ) : (
          <>
            {loadedStudy?.stage_list.length > 1 ? (
              <>
                <Row className='mb-3'>
                  <Col md={6}>
                    <Form.Group controlId={'tx_assignment_current_stage'}>
                      <Form.Label style={{ whiteSpace: 'normal' }}>
                        Study Stage of Current Treatment Group
                      </Form.Label>
                    </Form.Group>
                    <Form.Control
                      as='select'
                      value={selectedCurrentStage}
                      onChange={(e) => {
                        if (e.target.value !== selectedCurrentStage) {
                          setSelectedCurrentStage(e.target.value)
                          localNextTxStageInfo.current_group = ''
                          if (e.target.value === '') {
                            setSelectedNextStage('')
                          } else {
                            let updatedCurrentStage =
                              loadedStudy.stage_list.find(
                                (x) => x._id === e.target.value
                              )
                            setSelectedNextStage(
                              loadedStudy.stage_list.find(
                                (x) =>
                                  x.stage_num ===
                                  updatedCurrentStage.stage_num + 1
                              )?._id
                            )
                          }
                          localNextTxStageInfo.next_group = ''
                          setLocalNextTxStageInfo({ ...localNextTxStageInfo })
                        }
                      }}
                    >
                      <option
                        key={'tx_assignment_current_stage_unselected'}
                        value={'Please Select'}
                      ></option>
                      {loadedStudy &&
                        loadedStudy.stage_list &&
                        loadedStudy.stage_list
                          .filter(
                            (x) => x.stage_num !== loadedStudy.stage_list.length
                          )
                          .map((stage) => (
                            <option
                              key={'tx_assignment_current_stage_' + stage._id}
                              value={stage._id}
                            >
                              {stage.chin_name} ({stage.stage_num})
                            </option>
                          ))}
                    </Form.Control>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId={'tx_assignment_current_group'}>
                      <Form.Label style={{ whiteSpace: 'normal' }}>
                        Current Treatment Group
                      </Form.Label>
                    </Form.Group>
                    <Form.Control
                      as='select'
                      value={localNextTxStageInfo.current_group}
                      onChange={(e) => {
                        if (
                          e.target.value !== localNextTxStageInfo.current_group
                        ) {
                          localNextTxStageInfo.current_group = e.target.value
                          setLocalNextTxStageInfo({ ...localNextTxStageInfo })
                        }
                      }}
                    >
                      <option
                        key={'tx_assignment_current_group_unselected'}
                        value={'Please Select'}
                      ></option>
                      {getStage(loadedStudy, selectedCurrentStage) &&
                        getStage(
                          loadedStudy,
                          selectedCurrentStage
                        ).participant_group_list.map((group) => (
                          <option
                            key={'tx_assignment_current_group_' + group._id}
                            value={group._id}
                          >
                            {group.name} ({group.group_num})
                          </option>
                        ))}
                    </Form.Control>
                  </Col>
                </Row>

                {selectedNextStage && (
                  <Row className='mb-3'>
                    <Col md={6}>
                      <Form.Group controlId={'tx_assignment_next_stage'}>
                        <Form.Label style={{ whiteSpace: 'normal' }}>
                          Study Stage of Next Treatment Group
                        </Form.Label>
                      </Form.Group>
                      <Form.Control
                        disabled={true}
                        as='select'
                        value={selectedNextStage}
                      >
                        <option
                          key={'tx_assignment_next_stage_unselected'}
                          value={'Please Select'}
                        ></option>
                        {loadedStudy &&
                          loadedStudy.stage_list &&
                          loadedStudy.stage_list.map((stage) => (
                            <option
                              key={'tx_assignment_next_stage_' + stage._id}
                              value={stage._id}
                            >
                              {stage.chin_name} ({stage.stage_num})
                            </option>
                          ))}
                      </Form.Control>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId={'tx_assignment_next_group'}>
                        <Form.Label style={{ whiteSpace: 'normal' }}>
                          Next Treatment Group
                        </Form.Label>
                      </Form.Group>
                      <Form.Control
                        as='select'
                        value={localNextTxStageInfo.next_group}
                        onChange={(e) => {
                          if (
                            e.target.value !== localNextTxStageInfo.next_group
                          ) {
                            localNextTxStageInfo.next_group = e.target.value
                            setLocalNextTxStageInfo({ ...localNextTxStageInfo })
                          }
                        }}
                      >
                        <option
                          key={'tx_assignment_next_group_unselected'}
                          value={'Please Select'}
                        ></option>
                        {getStage(loadedStudy, selectedNextStage) &&
                          getStage(
                            loadedStudy,
                            selectedNextStage
                          ).participant_group_list.map((group) => (
                            <option
                              key={'tx_assignment_next_group_' + group._id}
                              value={group._id}
                            >
                              {group.name} ({group.group_num})
                            </option>
                          ))}
                      </Form.Control>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col md={12}>
                    <Form.Group
                      controlId={'tx_assignment_assign_next_after_days'}
                    >
                      <Form.Label style={{ whiteSpace: 'normal' }}>
                        Perform assignment after number of days
                      </Form.Label>
                      <Form.Control
                        type='number'
                        value={localNextTxStageInfo.assign_next_after_days}
                        placeholder='Days'
                        onChange={(e) => {
                          localNextTxStageInfo.assign_next_after_days =
                            e.target.value
                          setLocalNextTxStageInfo({ ...localNextTxStageInfo })
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Form.Group
                      controlId={'tx_assignment_require_read_all_content'}
                    >
                      <Form.Check
                        type='checkbox'
                        label='Require to read all content'
                        checked={localNextTxStageInfo.require_read_all_content}
                        // for checkbox it is e.target.checked
                        onChange={(e) => {
                          localNextTxStageInfo.require_read_all_content =
                            e.target.checked
                          setLocalNextTxStageInfo({ ...localNextTxStageInfo })
                        }}
                      ></Form.Check>
                    </Form.Group>
                  </Col>
                </Row>

                {localNextTxStageInfo.current_group.length > 0 && (
                  <>
                    {loadingGetQxList || loadingGetPageList ? (
                      <Loader />
                    ) : (
                      <>
                        {localNextTxStageInfo.required_tasks.map(
                          (task, index) => (
                            <TxStageRequiredTask
                              key={'tx_assignment_required_tasks_' + index}
                              nextTxStageInfo={localNextTxStageInfo}
                              index={index}
                              onRequiredTaskDelete={() =>
                                requiredTaskDeletedHandler(index)
                              }
                              group={getGroup(
                                loadedStudy,
                                selectedCurrentStage,
                                localNextTxStageInfo.current_group
                              )}
                              qxList={qx_list}
                              pageList={txPages}
                            ></TxStageRequiredTask>
                          )
                        )}

                        <Row>
                          <Col>
                            <Button
                              className='mx-1 mt-2'
                              onClick={addRequiredTaskHandler}
                            >
                              Add New Required Task
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <p>
                You need at least two study stages to set up treatment
                assignment
              </p>
            )}
          </>
        )}
      </ModalContainer>
    </>
  )
}

export default UpsertNextTxStageInfo
