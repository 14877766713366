import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLabInfo } from '../../admin_module/actions/labActions'

import hasValidLoginCredential from '../../admin_module/utils/hasValidLoginCredential'

const ProgressBar = ({ history }) => {
  let storageSize = undefined
  let progressBarPercentage = undefined

  const theLabInfo = useSelector((state) => state.getLabInfo)
  const {
    loading: loadingLabInfo,
    error: errorLabInfo,
    success: successLabInfo,
    labInfo,
  } = theLabInfo

  //It is needed to check if the logged in user is an admin
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const dispatch = useDispatch()

  if (labInfo) {
    storageSize = labInfo.storage
    progressBarPercentage = (storageSize / 2147483648).toFixed(2) * 100 + '%'
  }

  useEffect(() => {
    let now = new Date()
    if (!hasValidLoginCredential(userInfo, logout, window)) {
      history.push('/login')
    } else {
      if (!loadingLabInfo && !successLabInfo && !errorLabInfo) {
        dispatch(getLabInfo(userInfo.lab))
      }
    }
  }, [
    userInfo,
    history,
    loadingLabInfo,
    successLabInfo,
    errorLabInfo,
    dispatch,
    logout,
  ])

  return (
    <>
      <div className='progress' style={getProgressBarStyle()}>
        <div
          className='progress-bar'
          style={getProgressBarPercentageStyle(
            progressBarPercentage && progressBarPercentage
          )}
        ></div>
      </div>
      <p style={getProgressBarInfoStyle()}>
        {formatBytes(storageSize)} of 2 GB used
      </p>
    </>
  )
}

const getProgressBarStyle = () => {
  return {
    width: '35%',
  }
}

const getProgressBarPercentageStyle = (progressBarPercentage) => {
  return {
    width: progressBarPercentage,
  }
}

const getProgressBarInfoStyle = () => {
  return {
    fontSize: '0.8rem',
  }
}

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export default ProgressBar
