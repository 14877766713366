import axios from 'axios'
import { SYSTEM_OWNER_LOGOUT } from '../../admin_module/constants/userConstants'
import {
  QX_LIST_FAIL,
  QX_LIST_REQUEST,
  QX_LIST_SUCCESS,
} from '../constants/qxConstants'

import BACKEND_URL from '../../backendUrl'

const standardErrorHandling = (dispatch, error, errorConstant) => {
  if (error.response && error.response.status === 401) {
    // This ensure userInfo is clear
    dispatch({
      type: SYSTEM_OWNER_LOGOUT,
    })
    localStorage.removeItem('adminModuleSystemOwnerInfo')
  } else {
    // In the custom express error handler, there could be custom error message,
    //  which is stored in error.response as { message: ..., stack: ... }
    // If there is no such custom error message, output the generic error message (i.e. error.message)
    dispatch({
      type: errorConstant,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// Following are the actions that will be fired off after calling by components
export const getQxList =
  (managedByUserId, pageNumber = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: QX_LIST_REQUEST })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        systemOwnerLogin: { systemOwnerInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${systemOwnerInfo.token}`,
        },
      }

      const { data } = await axios.post(
        BACKEND_URL + `/api/qx_list/systemowner?pageNumber=${pageNumber}`,
        { managedByUserId },
        config
      )

      dispatch({
        type: QX_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      standardErrorHandling(dispatch, error, QX_LIST_FAIL)
    }
  }
