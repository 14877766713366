const getOperatorByResponsePosition = (question, rPos) => {
  if (question) {
    let type = question.other_info[rPos - 1].type
    let numberOnly = question.other_info[rPos - 1].number_only

    if (type === 'text') {
      if (numberOnly) {
        return (
          <>
            <option value='=='>equal</option>
            <option value='!='>not equal</option>
            <option value='>'>greater than</option>
            <option value='<'>less than</option>
            <option value='>='>greater than or equal</option>
            <option value='<='>less than or equal</option>
          </>
        )
      } else {
        return (
          <>
            <option value='=='>is</option>
            <option value='!='>is not</option>
          </>
        )
      }
    } else if (type === 'mc') {
      return (
        <>
          <option value='=='>is selected</option>
          <option value='!='>is not selected</option>
        </>
      )
    }
  }
}

export default getOperatorByResponsePosition
