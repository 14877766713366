export const STUDY_LIST_REQUEST = 'STUDY_LIST_REQUEST'
export const STUDY_LIST_SUCCESS = 'STUDY_LIST_SUCCESS'
export const STUDY_LIST_FAIL = 'STUDY_LIST_FAIL'
export const STUDY_LIST_LOGOUT = 'STUDY_LIST_LOGOUT'
export const STUDY_LIST_RESET = 'STUDY_LIST_RESET'

export const STUDY_CREATE_REQUEST = 'STUDY_CREATE_REQUEST'
export const STUDY_CREATE_SUCCESS = 'STUDY_CREATE_SUCCESS'
export const STUDY_CREATE_FAIL = 'STUDY_CREATE_FAIL'
export const STUDY_CREATE_RESET = 'STUDY_CREATE_RESET'

export const STUDY_ACTIVATE_REQUEST = 'STUDY_ACTIVATE_REQUEST'
export const STUDY_ACTIVATE_SUCCESS = 'STUDY_ACTIVATE_SUCCESS'
export const STUDY_ACTIVATE_FAIL = 'STUDY_ACTIVATE_FAIL'
export const STUDY_ACTIVATE_RESET = 'STUDY_ACTIVATE_RESET'

export const STUDY_DEACTIVATE_REQUEST = 'STUDY_DEACTIVATE_REQUEST'
export const STUDY_DEACTIVATE_SUCCESS = 'STUDY_DEACTIVATE_SUCCESS'
export const STUDY_DEACTIVATE_FAIL = 'STUDY_DEACTIVATE_FAIL'
export const STUDY_DEACTIVATE_RESET = 'STUDY_DEACTIVATE_RESET'

export const STUDY_DELETE_REQUEST = 'STUDY_DELETE_REQUEST'
export const STUDY_DELETE_SUCCESS = 'STUDY_DELETE_SUCCESS'
export const STUDY_DELETE_FAIL = 'STUDY_DELETE_FAIL'
export const STUDY_DELETE_RESET = 'STUDY_DELETE_RESET'

export const STUDY_DETAILS_REQUEST = 'STUDY_DETAILS_REQUEST'
export const STUDY_DETAILS_SUCCESS = 'STUDY_DETAILS_SUCCESS'
export const STUDY_DETAILS_FAIL = 'STUDY_DETAILS_FAIL'
export const STUDY_DETAILS_LOGOUT = 'STUDY_DETAILS_LOGOUT'
export const STUDY_DETAILS_RESET = 'STUDY_DETAILS_RESET'

export const STAGE_ADD_REQUEST = 'STAGE_ADD_REQUEST'
export const STAGE_ADD_SUCCESS = 'STAGE_ADD_SUCCESS'
export const STAGE_ADD_FAIL = 'STAGE_ADD_FAIL'
export const STAGE_ADD_RESET = 'STAGE_ADD_RESET'

export const STAGE_DELETE_REQUEST = 'STAGE_DELETE_REQUEST'
export const STAGE_DELETE_SUCCESS = 'STAGE_DELETE_SUCCESS'
export const STAGE_DELETE_FAIL = 'STAGE_DELETE_FAIL'
export const STAGE_DELETE_RESET = 'STAGE_DELETE_RESET'

export const PARTI_GROUP_ADD_REQUEST = 'PARTI_GROUP_ADD_REQUEST'
export const PARTI_GROUP_ADD_SUCCESS = 'PARTI_GROUP_ADD_SUCCESS'
export const PARTI_GROUP_ADD_FAIL = 'PARTI_GROUP_ADD_FAIL'
export const PARTI_GROUP_ADD_RESET = 'PARTI_GROUP_ADD_RESET'

export const PARTI_GROUP_DELETE_REQUEST = 'PARTI_GROUP_DELETE_REQUEST'
export const PARTI_GROUP_DELETE_SUCCESS = 'PARTI_GROUP_DELETE_SUCCESS'
export const PARTI_GROUP_DELETE_FAIL = 'PARTI_GROUP_DELETE_FAIL'
export const PARTI_GROUP_DELETE_RESET = 'PARTI_GROUP_DELETE_RESET'

export const PARTI_GROUP_EDIT_REQUEST = 'PARTI_GROUP_EDIT_REQUEST'
export const PARTI_GROUP_EDIT_SUCCESS = 'PARTI_GROUP_EDIT_SUCCESS'
export const PARTI_GROUP_EDIT_FAIL = 'PARTI_GROUP_EDIT_FAIL'
export const PARTI_GROUP_EDIT_RESET = 'PARTI_GROUP_EDIT_RESET'

export const STUDY_EDIT_EXCEPT_CONSENT_REQUEST =
  'STUDY_EDIT_EXCEPT_CONSENT_REQUEST'
export const STUDY_EDIT_EXCEPT_CONSENT_SUCCESS =
  'STUDY_EDIT_EXCEPT_CONSENT_SUCCESS'
export const STUDY_EDIT_EXCEPT_CONSENT_FAIL = 'STUDY_EDIT_EXCEPT_CONSENT_FAIL'
export const STUDY_EDIT_EXCEPT_CONSENT_RESET = 'STUDY_EDIT_EXCEPT_CONSENT_RESET'

export const STUDY_EDIT_BASIC_REQUEST = 'STUDY_EDIT_BASIC_REQUEST'
export const STUDY_EDIT_BASIC_SUCCESS = 'STUDY_EDIT_BASIC_SUCCESS'
export const STUDY_EDIT_BASIC_FAIL = 'STUDY_EDIT_BASIC_FAIL'
export const STUDY_EDIT_BASIC_RESET = 'STUDY_EDIT_BASIC_RESET'

export const STUDY_EDIT_CONSENT_REQUEST = 'STUDY_EDIT_CONSENT_REQUEST'
export const STUDY_EDIT_CONSENT_SUCCESS = 'STUDY_EDIT_CONSENT_SUCCESS'
export const STUDY_EDIT_CONSENT_FAIL = 'STUDY_EDIT_CONSENT_FAIL'
export const STUDY_EDIT_CONSENT_RESET = 'STUDY_EDIT_CONSENT_RESET'

export const STAGE_EDIT_BASIC_REQUEST = 'STAGE_EDIT_BASIC_REQUEST'
export const STAGE_EDIT_BASIC_SUCCESS = 'STAGE_EDIT_BASIC_SUCCESS'
export const STAGE_EDIT_BASIC_FAIL = 'STAGE_EDIT_BASIC_FAIL'
export const STAGE_EDIT_BASIC_RESET = 'STAGE_EDIT_BASIC_RESET'

export const STUDY_LOCK_REQUEST = 'STUDY_LOCK_REQUEST'
export const STUDY_LOCK_SUCCESS = 'STUDY_LOCK_SUCCESS'
export const STUDY_LOCK_FAIL = 'STUDY_LOCK_FAIL'
export const STUDY_LOCK_RESET = 'STUDY_LOCK_RESET'

export const STUDY_UNLOCK_REQUEST = 'STUDY_UNLOCK_REQUEST'
export const STUDY_UNLOCK_SUCCESS = 'STUDY_UNLOCK_SUCCESS'
export const STUDY_UNLOCK_FAIL = 'STUDY_UNLOCK_FAIL'
export const STUDY_UNLOCK_RESET = 'STUDY_UNLOCK_RESET'
