import {
  DIARY_AX_TASK_LIST_FAIL,
  DIARY_AX_TASK_LIST_REQUEST,
  DIARY_AX_TASK_LIST_RESET,
  DIARY_AX_TASK_LIST_SUCCESS,
} from '../constants/diaryAxConstants'

export const diaryAxTaskListReducer = (
  state = { diaryAxTasks: [] },
  action
) => {
  switch (action.type) {
    case DIARY_AX_TASK_LIST_REQUEST:
      return { loading: true, diaryAxTasks: [] }
    case DIARY_AX_TASK_LIST_SUCCESS:
      return {
        loading: false,
        diaryAxTasks: action.payload.diaryAxTasks,
        success: true,
      }
    case DIARY_AX_TASK_LIST_FAIL:
      return { loading: false, error: action.payload }
    case DIARY_AX_TASK_LIST_RESET:
      return { diaryAxTasks: [] }
    default:
      return state
  }
}
