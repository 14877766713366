/* This screen is for system owner to log in */

import React, { useState, useEffect } from 'react'
import FormContainer from '../components/FormContainer'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button } from 'react-bootstrap'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { systemOwnerLoginAction } from '../actions/userActions'

import Organizations from '../../enums/organizations'
import getSubdomain from '../../getSubdomain'
import findOrganizationBySubdomain from '../../findOrganizationBySubdomain'
import redirectDueToInvalidSubdomain from '../../redirectDueToInvalidSubdomain'

const SystemOwnerLoginScreen = ({ history }) => {
  // Make sure system owner is only accessible through longitudinax site
  const subdomain = getSubdomain(window)
  const siteOrganization = findOrganizationBySubdomain(subdomain)
  if (siteOrganization !== Organizations.Longitudinax) {
    // Keep the site subdomain by providing undefined to the second parameter
    redirectDueToInvalidSubdomain(window, undefined)
  }
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useDispatch()

  const systemOwnerLogin = useSelector((state) => state.systemOwnerLogin)
  const { loading, error, attemptLogin, systemOwnerInfo } = systemOwnerLogin

  useEffect(() => {
    // Check attemptLogin flag to allow login with another user
    let today = new Date()
    if (attemptLogin && systemOwnerInfo && systemOwnerInfo.expires > today) {
      history.push('/systemowner/home')
    }
  }, [attemptLogin, systemOwnerInfo, history])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(systemOwnerLoginAction(email, password))
  }

  return (
    <>
      <FormContainer>
        <h1 className='py-3'>Sign In</h1>
        {error && <Message variant='danger'>{error}</Message>}
        {loading && <Loader />}

        <Form onSubmit={submitHandler} className='mt-3'>
          <Form.Group controlId='email'>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type='email'
              placeholder='Enter email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='password'>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Enter password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            ></Form.Control>
          </Form.Group>

          <Button type='submit' variant='primary mt-2 ' className='btn btn-lg'>
            Sign In
          </Button>
        </Form>
      </FormContainer>
    </>
  )
}

export default SystemOwnerLoginScreen
