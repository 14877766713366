import React from 'react'
import { Button, Row, Col, Form, Card } from 'react-bootstrap'
import getChoicesByResponsePosition from '../utils/getChoicesByResponsePosition'
import getResponseItemsByQuestion from '../utils/getResponseItemsByQuestion'
import getOperatorByResponsePosition from '../utils/getOperatorByResponsePosition'

const DependencyEntry = ({
  andIndex,
  orIndex,
  dependency,
  qxContent,
  mcList,
  onDependencyDelete,
}) => {
  const removeTag = (question) => {
    return question.replace(/<[^>]+>/g, '')
  }

  return (
    <Row className='ml-1'>
      <Col>
        <Form.Group
          controlId={'loadedDependency_question' + andIndex + '_' + orIndex}
        >
          <Form.Label>Question</Form.Label>
          <Form.Control readOnly as='select'>
            <option>
              {qxContent[dependency.dependent_qpos - 1].question_num}{' '}
              {removeTag(
                qxContent[dependency.dependent_qpos - 1].html_question
              )}
            </option>
          </Form.Control>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group
          controlId={'loadedDependency_rpos_' + andIndex + '_' + orIndex}
        >
          <Form.Label>Response Item</Form.Label>
          <Form.Control
            readOnly
            as='select'
            value={dependency.dependent_response_pos}
          >
            <option value=''>Select...</option>
            {getResponseItemsByQuestion(
              qxContent[dependency.dependent_qpos - 1]
            )}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group
          controlId={'loadedDependency_operator_' + andIndex + '_' + orIndex}
        >
          <Form.Label>Operator</Form.Label>
          <Form.Control
            readOnly
            as='select'
            value={dependency.criterion_operator}
          >
            {getOperatorByResponsePosition(
              qxContent[dependency.dependent_qpos - 1],
              dependency.dependent_response_pos
            )}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col>
        {dependency.criterion_value &&
          qxContent[dependency.dependent_qpos - 1].other_info[
            dependency.dependent_response_pos - 1
          ].type === 'text' && (
            <Form.Group
              controlId={'loadedDependency_value_' + andIndex + '_' + orIndex}
            >
              <Form.Label>Response</Form.Label>
              <Form.Control
                readOnly
                type='text'
                value={dependency.criterion_value}
              ></Form.Control>
            </Form.Group>
          )}

        {dependency.criterion_value &&
          qxContent[dependency.dependent_qpos - 1].other_info[
            dependency.dependent_response_pos - 1
          ].type === 'mc' && (
            <Form.Group
              controlId={'loadedDependency_value_' + andIndex + '_' + orIndex}
            >
              <Form.Label>Response</Form.Label>
              <Form.Control
                readOnly
                as='select'
                value={dependency.criterion_value}
              >
                {getChoicesByResponsePosition(
                  qxContent[dependency.dependent_qpos - 1],
                  dependency.dependent_response_pos,
                  mcList
                )}
              </Form.Control>
            </Form.Group>
          )}
      </Col>
      <Col>
        <Form.Group controlId={'loadedDependency_value_remove_btn'}>
          <Button
            type='button'
            className='btn btn-danger float-left mt-4'
            onClick={() => {
              onDependencyDelete(andIndex, orIndex)
            }}
          >
            Remove
          </Button>
        </Form.Group>
      </Col>
    </Row>
  )
}

export default DependencyEntry
