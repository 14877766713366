import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Row, Col, Form, Table } from 'react-bootstrap'
import ModalContainer from './ModalContainer'
import Loader from './Loader'
import Message from './Message'

import { listStudyDetails } from '../../study_module/actions/studyActions'

import { assignParticipant } from '../actions/participantActions'
import { ASSIGN_PARTI_RESET } from '../constants/participantConstants'
import { STUDY_DETAILS_RESET } from '../../study_module/constants/studyConstants'

export const AssignParticipant = ({ enrolment, email, onSaveSuccess }) => {
  //It is needed to check if the logged in user is an admin
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const assignParti = useSelector((state) => state.assignParti)
  const {
    loading: loadingAssignParti,
    error: errorAssignParti,
    success: successAssignParti,
  } = assignParti

  const studyDetails = useSelector((state) => state.studyDetails)
  const {
    loading: loadingInitiateStudy,
    error: errorInitiateStudy,
    study: loadedStudy,
    success: successInitiateStudy,
  } = studyDetails

  const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [currentStage, setCurrentStage] = useState('')
  const [currentGroup, setCurrentGroup] = useState('')

  const [assignStage, setAssignStage] = useState('')
  const [assignGroup, setAssignGroup] = useState('')

  const [saveError, setSaveError] = useState('')

  useEffect(() => {
    let now = new Date()
    if (userInfo && userInfo.expires > now && !logout) {
      if (successAssignParti) {
        dispatch({ type: ASSIGN_PARTI_RESET })
        setShow(false)
        onSaveSuccess()
      }
    }
  }, [
    enrolment,
    userInfo,
    logout,
    dispatch,
    onSaveSuccess,
    successInitiateStudy,
    loadingInitiateStudy,
    errorInitiateStudy,
    successAssignParti,
  ])

  const handleOpen = () => {
    let now = new Date()
    if (userInfo && userInfo.expires > now && !logout) {
      if (enrolment) {
        if (
          !successInitiateStudy &&
          !loadingInitiateStudy &&
          !errorInitiateStudy
        ) {
          dispatch(listStudyDetails(enrolment.study))
        }
        setCurrentStage(enrolment.current_stage.chin_name)
        setCurrentGroup(enrolment.current_group.name)
      }
    }
    setShow(true)
  }

  const onChangeStage = (e) => {
    setAssignStage(e.target.value)
    setAssignGroup('')
  }

  const checkAssignedStageNonEmpty = () => {
    if (assignStage.length === 0) {
      return 'Assigned stage cannot be empty'
    } else {
      return ''
    }
  }

  const checkAssignedGroupNonEmpty = () => {
    if (assignGroup.length === 0) {
      return 'Assigned group cannot be empty'
    } else {
      return ''
    }
  }

  const handleSave = (e) => {
    e.preventDefault()

    setSaveError('')
    let error = ''

    if (error.length === 0) error = checkAssignedStageNonEmpty()
    if (error.length === 0) error = checkAssignedGroupNonEmpty()
    //if (error.length === 0) error = checkSameAsCurrentGroup() <==== To allow reset ema tasks, comment this check

    if (error.length === 0) {
      dispatch(
        assignParticipant(enrolment._id, {
          studyId: loadedStudy._id,
          stageId: assignStage,
          groupId: assignGroup,
        })
      )
    } else {
      setSaveError(error)
    }
  }

  const handleClose = () => {
    dispatch({ type: STUDY_DETAILS_RESET })
    setShow(false)
  }

  const filteredGroups = (study, stageId) => {
    const targetStage = study.stage_list.find((x) => x._id === stageId)
    return targetStage.participant_group_list
  }

  return (
    <>
      <Button variant='primary' className='btn-sm m-1' onClick={handleOpen}>
        Assign To Another Stage
      </Button>

      <ModalContainer
        title='Assign Participant'
        show={show}
        handleSave={handleSave}
        handleClose={handleClose}
      >
        {errorInitiateStudy && (
          <Message variant='danger'>{errorInitiateStudy}</Message>
        )}
        {errorAssignParti && (
          <Message variant='danger'>{errorAssignParti}</Message>
        )}
        {saveError && <Message variant='danger'>{saveError}</Message>}

        {loadingInitiateStudy || loadingAssignParti ? (
          <Loader />
        ) : (
          <>
            {successInitiateStudy && (
              <>
                <Row>
                  <Col md={12}>
                    <Table
                      bordered
                      hover
                      responsive
                      className='table-sm'
                      style={{ marginTop: '20px' }}
                    >
                      <thead>
                        <tr>
                          <th>PARTICIPANT</th>
                          <th>CURRENT STAGE</th>
                          <th>CURRENT GROUP</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{email}</td>
                          <td>{currentStage}</td>
                          <td>{currentGroup}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group controlId={'assigned_study'}>
                      <Form.Label>Assigned Stage</Form.Label>
                      <Form.Control
                        as='select'
                        value={assignStage}
                        onChange={(e) => {
                          onChangeStage(e)
                        }}
                      >
                        <option value='' key={'empty_stage'}>
                          Please Select
                        </option>
                        {loadedStudy.stage_list.map((stage) => (
                          <option value={stage._id} key={stage._id}>
                            {stage.chin_name}
                          </option>
                        ))}
                      </Form.Control>{' '}
                    </Form.Group>
                  </Col>
                </Row>

                {assignStage !== '' && (
                  <>
                    <Row>
                      <Col>
                        <Form.Group controlId={'assigned_group'}>
                          <Form.Label>Assigned Group</Form.Label>
                          <Form.Control
                            as='select'
                            value={assignGroup}
                            onChange={(e) => {
                              setAssignGroup(e.target.value)
                            }}
                          >
                            <option value='' key={'empty_group'}>
                              Please Select
                            </option>
                            {filteredGroups(loadedStudy, assignStage).map(
                              (group) => (
                                <option value={group._id} key={group._id}>
                                  {group.name}
                                </option>
                              )
                            )}
                          </Form.Control>{' '}
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
          </>
        )}
      </ModalContainer>
    </>
  )
}

export default AssignParticipant
