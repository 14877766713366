import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button, Table } from 'react-bootstrap'
import Message from '../components/Message'
import ToastMessage from '../components/ToastMessage'
import Loader from '../components/Loader'
import CreateTxPage from '../components/CreateTxPage'

import { getTxPageList, deleteTxPage } from '../actions/txMaterialsActions'
import {
  CUSTOM_PAGE_COMPONENT_DETAILS_RESET,
  CUSTOM_PAGE_COMPONENT_LIST_RESET,
  TX_PAGE_DELETE_RESET,
  TX_PAGE_DETAILS_RESET,
  TX_PAGE_LIST_RESET,
} from '../constants/txMaterialsConstants'

import resetErrorState from '../utils/resetErrorState'

import hasValidLoginCredential from '../../admin_module/utils/hasValidLoginCredential'
import { getAllAccess } from '../../access_module/actions/accessActions'
import { GET_ALL_ACCESS_RESET } from '../../access_module/constants/accessConstants'
import { QX_LIST_RESET } from '../../qx_module/constants/qxConstants'
import { STUDY_DETAILS_RESET } from '../../study_module/constants/studyConstants'
import { listStudyDetails } from '../../study_module/actions/studyActions'

const TxPageListScreen = ({ history, match }) => {
  const studyId = match.params.studyId

  // For toast message
  const [toastMessages, setToastMessages] = useState([])

  const toastOnCloseHandler = async (uid) => {
    setToastMessages((toastMessages) =>
      toastMessages.filter((message) => message.uid !== uid)
    )
  }

  const dispatch = useDispatch()

  //It is needed to check if the logged in user is an admin
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const txPageList = useSelector((state) => state.txPageList)
  const {
    txPages,
    error: errorGetList,
    loading: loadingGetList,
    success: successGetList,
  } = txPageList

  const txPageDelete = useSelector((state) => state.txPageDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = txPageDelete

  const allAccess = useSelector((state) => state.allAccess)
  const {
    loading: laodingGetAllAccess,
    error: errorGetAllAccess,
    success: successGetAllAccess,
    allAccessList,
  } = allAccess

  const studyDetails = useSelector((state) => state.studyDetails)
  const {
    loading: loadingStudy,
    error: errorStudy,
    study: loadedStudy,
    success: successStudy,
  } = studyDetails

  useEffect(() => {
    if (!hasValidLoginCredential(userInfo, logout, window)) {
      dispatch({ type: TX_PAGE_LIST_RESET })
      history.push('/login')
    } else {
      if (!loadingGetList && !successGetList && !errorGetList) {
        resetErrorState(dispatch)
        // Put the reset for getting all access just before loading the main content again
        dispatch({ type: GET_ALL_ACCESS_RESET })
        dispatch(getTxPageList(studyId))
      }

      if (!loadingStudy && !errorStudy & !successStudy) {
        dispatch(listStudyDetails(studyId))
      }

      if (!laodingGetAllAccess && !successGetAllAccess && !errorGetAllAccess) {
        dispatch(getAllAccess())
      }

      if (successDelete) {
        dispatch({ type: TX_PAGE_DELETE_RESET })
        dispatch(getTxPageList(studyId))
      }
    }
  }, [
    dispatch,
    history,
    userInfo,
    studyId,
    logout,
    loadingGetList,
    laodingGetAllAccess,
    errorGetList,
    errorGetAllAccess,
    successDelete,
    successGetList,
    successGetAllAccess,
  ])

  const goToEditHandler = async (txpage) => {
    dispatch({ type: TX_PAGE_LIST_RESET })
    dispatch({ type: TX_PAGE_DETAILS_RESET })
    dispatch({ type: CUSTOM_PAGE_COMPONENT_LIST_RESET })
    dispatch({ type: CUSTOM_PAGE_COMPONENT_DETAILS_RESET })
    dispatch({ type: QX_LIST_RESET })
    dispatch({ type: STUDY_DETAILS_RESET })
    history.push(`/txPages/${studyId}/${txpage._id}/edit`)
  }

  const deleteTxPageHandler = async (txPage) => {
    if (window.confirm('Are you sure?')) {
      resetErrorState(dispatch)
      dispatch(deleteTxPage(studyId, txPage._id))
    }
  }

  const createTxPageHandler = async (txPage) => {
    history.push(`/txPages/${studyId}/${txPage._id}/edit`)
  }

  const headingStyle = {
    fontSize: '2rem',
    fontWeight: 'bold',
  }

  return (
    <>
      {/* Display toast messages */}
      <div
        style={{
          position: 'absolute',
          backgroundColor: 'transparent',
          pointerEvents: 'none',
          height: '80vh',
          width: '80vw',
          zIndex: 1,
        }}
      >
        {toastMessages.map((toastMessage) => (
          <ToastMessage
            key={toastMessage.uid}
            variant={toastMessage.variant}
            onClose={() => toastOnCloseHandler(toastMessage.uid)}
          >
            {toastMessage.message}
          </ToastMessage>
        ))}
      </div>

      <Row className='align-items-center'>
        <Col>
          <h1 style={headingStyle}>Treatment Page List</h1>
        </Col>

        {!loadedStudy.lock && (
          <CreateTxPage
            studyId={studyId}
            onCreatePage={createTxPageHandler}
          ></CreateTxPage>
        )}
      </Row>

      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {loadingGetList || loadingDelete ? (
        <Loader />
      ) : errorGetList ? (
        <Message variant='danger'>{errorGetList}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>NAME</th>
                <th>PAGE IDENTIFIER</th>
                <th>CREATED AT</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!logout &&
                txPages.map((txPage) => (
                  <tr key={txPage._id}>
                    <td className='align-middle'>{txPage.name}</td>
                    <td className='align-middle'>{txPage.identifier}</td>
                    <td className='align-middle'>
                      {txPage.createdAt.substring(0, 10)}
                    </td>
                    <td className='align-middle'>
                      <Button
                        variant='primary'
                        className='btn-sm mx-1'
                        onClick={() => goToEditHandler(txPage)}
                      >
                        {loadedStudy.lock ? <>View</> : <>Edit</>}
                      </Button>

                      {txPage.can_delete_by_admin && !loadedStudy.lock && (
                        <>
                          <Button
                            variant='primary'
                            className='btn-sm mx-1'
                            onClick={() => deleteTxPageHandler(txPage)}
                          >
                            Delete
                          </Button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  )
}

export default TxPageListScreen
