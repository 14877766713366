import React, { useEffect, useState } from 'react'
import FormContainer from '../components/FormContainer'
import { Form, FormControl, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { createMediaFiles } from '../actions/mediaActions'
import { CREATE_MEDIA_FILES_RESET } from '../constants/mediaConstants'

import hasValidLoginCredential from '../../admin_module/utils/hasValidLoginCredential'

const CreateFileScreen = ({ history }) => {
  const [name, setName] = useState('')
  const [error, setError] = useState('')
  const [type, setType] = useState('')
  const [file, setFile] = useState(null)

  const mediaFilesCreate = useSelector((state) => state.createMediaFiles)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const dispatch = useDispatch()

  const {
    loading: mediaFilesCreateLoading,
    error: mediaFilesCreateLoadingError,
    success: mediaFilesCreateSuccess,
  } = mediaFilesCreate

  useEffect(() => {
    if (!hasValidLoginCredential(userInfo, logout, window)) {
      history.push('/login')
    } else {
      if (mediaFilesCreateSuccess) {
        dispatch({
          type: CREATE_MEDIA_FILES_RESET,
        })
        history.goBack()
      }
    }
  }, [history, mediaFilesCreateSuccess, dispatch, userInfo, logout])

  // since it is a http request, make it async
  const uploadFileHandler = async (e) => {
    setError('')
    // only get the first file in the array
    const file = e.target.files[0]

    if (file) {
      let extension = file.name.split('.').pop()

      if (checkExtension(extension)) {
        // determine the type of uploaded file
        let type =
          extension === 'jpg' || extension === 'jpeg' || extension === 'png'
            ? 'image'
            : extension === 'mp3'
            ? 'audio'
            : 'video'

        if (checkFileSize(file, type)) {
          setType(type)
          setFile(file)
        }
      }
    }
  }

  const submitHandler = async (e) => {
    e.preventDefault()

    let filePath

    let extension = file.name.split('.').pop()

    if (type === 'image') {
      filePath = `images/${userInfo.lab}/${Date.now()}.${extension}`
    }
    if (type === 'audio') {
      filePath = `audios/${userInfo.lab}/${Date.now()}.${extension}`
    }
    if (type === 'video') {
      filePath = `videos/${userInfo.lab}/${Date.now()}.${extension}}`
    }

    // make the request (type is for database storage, fileType is for S3 upload)
    try {
      dispatch(
        createMediaFiles({
          name,
          type,
          file: file,
          lab: userInfo.lab,
          size: file.size,
          filePath: filePath,
          fileType: file.type,
        })
      )
    } catch (error) {
      console.error(error)
    }
  }

  // check the file size of user uploaded
  function checkFileSize(file, type) {
    if (type === 'image') {
      if (file.size > 2097152) {
        setError('Image should be no more than 2MB in size')
        return false
      }
    } else if (type === 'audio') {
      if (file.size > 20971520) {
        setError('Audio should be no more than 20MB in size')
        return false
      }
      // type === 'video'
    } else {
      if (file.size > 31457280) {
        setError('Video should be no more than 30MB in size')
        return false
      }
    }

    return true
  }

  // check the file extension of user uploaded

  function checkExtension(extension) {
    if (
      extension === 'jpg' ||
      extension === 'jpeg' ||
      extension === 'png' ||
      extension === 'mp4' ||
      extension === 'mp3'
    ) {
      return true
    } else {
      setError('files must be in .JPG .JPEG .PNG .MP3 or .MP4 format')
    }
  }

  return (
    <>
      <Link to='/media/files' className='btn btn-light my-3'>
        Go Back
      </Link>

      <FormContainer>
        {mediaFilesCreateLoading && <Loader />}
        {!mediaFilesCreateLoading && (
          <>
            {mediaFilesCreateLoadingError && (
              <Message variant='danger'>{mediaFilesCreateLoadingError}</Message>
            )}

            {error && <Message variant='danger'>{error}</Message>}
            <h2 className='mb-3'>Create File</h2>

            <Form onSubmit={submitHandler}>
              <Form.Group controlId='name'>
                <Form.Label>Name</Form.Label>
                <FormControl
                  type='name'
                  placeholder='Enter file name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                ></FormControl>
              </Form.Group>

              <Form.Group controlId='media'>
                <Form.File
                  id='media-file'
                  // label="Choose File"
                  type='file'
                  // custom
                  onChange={uploadFileHandler}
                  required
                  // value={media}
                ></Form.File>

                <small className='form-text text-muted'>
                  Max 2 MB size (Image) OR 20 MB size (Audio) OR 30 MB size
                  (Video){' '}
                </small>
              </Form.Group>

              <Button type='submit' variant='primary'>
                Update
              </Button>
            </Form>
          </>
        )}
      </FormContainer>
    </>
  )
}

export default CreateFileScreen
