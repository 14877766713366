export const PARTI_LIST_REQUEST = 'PARTI_LIST_REQUEST'
export const PARTI_LIST_SUCCESS = 'PARTI_LIST_SUCCESS'
export const PARTI_LIST_FAIL = 'PARTI_LIST_FAIL'
export const PARTI_LIST_RESET = 'PARTI_LIST_RESET'

export const ASSIGN_PARTI_REQUEST = 'ASSIGN_PARTI_REQUEST'
export const ASSIGN_PARTI_SUCCESS = 'ASSIGN_PARTI_SUCCESS'
export const ASSIGN_PARTI_FAIL = 'ASSIGN_PARTI_FAIL'
export const ASSIGN_PARTI_RESET = 'ASSIGN_PARTI_RESET'

export const DOWNLOAD_TEXT_RESP_REQUEST = 'DOWNLOAD_TEXT_RESP_REQUEST'
export const DOWNLOAD_TEXT_RESP_SUCCESS = 'DOWNLOAD_TEXT_RESP_SUCCESS'
export const DOWNLOAD_TEXT_RESP_FAIL = 'DOWNLOAD_TEXT_RESP_FAIL'
export const DOWNLOAD_TEXT_RESP_RESET = 'DOWNLOAD_TEXT_RESP_RESET'

export const DOWNLOAD_RESP_TIME_REQUEST = 'DOWNLOAD_RESP_TIME_REQUEST'
export const DOWNLOAD_RESP_TIME_SUCCESS = 'DOWNLOAD_RESP_TIME_SUCCESS'
export const DOWNLOAD_RESP_TIME_FAIL = 'DOWNLOAD_RESP_TIME_FAIL'
export const DOWNLOAD_RESP_TIME_RESET = 'DOWNLOAD_RESP_TIME_RESET'

export const CREATE_PARTI_REQUEST = 'CREATE_PARTI_REQUEST'
export const CREATE_PARTI_SUCCESS = 'CREATE_PARTI_SUCCESS'
export const CREATE_PARTI_FAIL = 'CREATE_PARTI_FAIL'
export const CREATE_PARTI_RESET = 'CREATE_PARTI_RESET'

// for public OAuth callback handler
export const UPDATE_FITBIT_TOKEN_REQUEST = 'UPDATE_FITBIT_TOKEN_REQUEST'
export const UPDATE_FITBIT_TOKEN_SUCCESS = 'UPDATE_FITBIT_TOKEN_SUCCESS'
export const UPDATE_FITBIT_TOKEN_FAIL = 'UPDATE_FITBIT_TOKEN_FAIL'
export const UPDATE_FITBIT_TOKEN_RESET = 'UPDATE_FITBIT_TOKEN_RESET'

export const GET_FITBIT_SLEEP_LOG_REQUEST = 'GET_FITBIT_SLEEP_LOG_REQUEST'
export const GET_FITBIT_SLEEP_LOG_SUCCESS = 'GET_FITBIT_SLEEP_LOG_SUCCESS'
export const GET_FITBIT_SLEEP_LOG_FAIL = 'GET_FITBIT_SLEEP_LOG_FAIL'
export const GET_FITBIT_SLEEP_LOG_RESET = 'GET_FITBIT_SLEEP_LOG_RESET'
