import React, { useEffect, useState } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button, Table } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import ProgressBar from '../components/ProgressBar'

import { deleteMediaFile, getMediaFiles } from '../actions/mediaActions'
import { getLabInfo } from '../../admin_module/actions/labActions'
import { GET_LAB_INFO_RESET } from '../../admin_module/constants/labConstants'
import {
  CREATE_MEDIA_FILES_RESET,
  MEDIA_FILES_RESET,
} from '../constants/mediaConstants'

import hasValidLoginCredential from '../../admin_module/utils/hasValidLoginCredential'

const MediaFilesScreen = ({ history, match }) => {
  ///////////////// For pagination ///////////////////
  const pageNumber = match.params.pageNumber || 1
  const [page, setPage] = useState(pageNumber)
  ///////////////////////////////////////////////////

  let storageSize = undefined
  let progressBarPercentage = undefined

  const dispatch = useDispatch()

  //It is needed to check if the logged in user is an admin
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const mediaFiles = useSelector((state) => state.mediaFiles)
  const {
    loading: loadingGetList,
    error: errorGetList,
    success: successGetList,
    files,
    pages,
  } = mediaFiles

  const mediaDelete = useSelector((state) => state.deleteMediaFiles)

  const {
    loading: loadingDelete,
    success: successDelete,
    error: errorDelete,
  } = mediaDelete

  const theLabInfo = useSelector((state) => state.getLabInfo)
  const {
    loading: loadingLabInfo,
    error: errorLabInfo,
    success: successLabInfo,
    labInfo,
  } = theLabInfo

  if (labInfo) {
    storageSize = labInfo.storage
    progressBarPercentage = (storageSize / 2147483648).toFixed(2) * 100 + '%'
  }

  useEffect(() => {
    if (!hasValidLoginCredential(userInfo, logout, window)) {
      history.push('/login')
    } else {
      if (!loadingLabInfo && !successLabInfo && !errorLabInfo) {
        dispatch(getLabInfo(userInfo.lab))
      }
      if (successLabInfo) {
        if (!loadingGetList && !successGetList && !errorGetList) {
          dispatch(getMediaFiles(pageNumber))
          setPage(pageNumber)
        }
      }

      /////////////////   For pagination ///////////////////
      //pages > 0 is essential, otherwise there is an error when the list is empty
      if (pages > 0 && page > pages) {
        history.push(`/media/files/${pages}`)
        dispatch({ type: MEDIA_FILES_RESET })
      }
      if (pageNumber !== page) {
        dispatch({ type: MEDIA_FILES_RESET })
        setPage(pageNumber)
      }
      /////////////////////////////////////////////////////
    }
  }, [
    dispatch,
    history,
    userInfo,
    pageNumber,
    logout,
    successDelete,
    loadingGetList,
    successGetList,
    errorGetList,
    loadingLabInfo,
    successLabInfo,
    errorLabInfo,
    pages,
    page,
  ])

  const goToCreateHandler = async () => {
    dispatch({ type: GET_LAB_INFO_RESET })
    dispatch({ type: MEDIA_FILES_RESET })
    dispatch({ type: CREATE_MEDIA_FILES_RESET })
    history.push(`/media/createfile`)
  }

  const deleteFileHandler = (file) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteMediaFile(file._id))
    }
  }

  const headingStyle = {
    fontSize: '2rem',
    fontWeight: 'bold',
  }

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1 style={headingStyle}>Media Files</h1>
        </Col>
        <Col className='text-right'>
          <Button className='my-3' onClick={goToCreateHandler}>
            <i className='fas fa-plus'></i> Create File
          </Button>
        </Col>
      </Row>

      {loadingGetList || loadingDelete || loadingLabInfo ? (
        <Loader />
      ) : errorGetList ? (
        <Message variant='danger'>{errorGetList}</Message>
      ) : errorDelete ? (
        <Message variant='danger'>{errorDelete}</Message>
      ) : errorLabInfo ? (
        <Message variant='danger'>{errorLabInfo}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className='text-center '>
            <thead>
              <tr>
                <th>NAME</th>
                <th>TYPE</th>
                <th>CREATED AT</th>
                <th>EDIT</th>
              </tr>
            </thead>
            <tbody>
              {files &&
                files.map((file) => (
                  <tr key={file._id}>
                    <td className='align-middle'>{file.name}</td>
                    <td className='align-middle'>{file.type}</td>
                    <td className='align-middle'>
                      {file.createdAt.substring(0, 10)}
                    </td>
                    <td className='align-middle'>
                      <>
                        <LinkContainer to={`/media/files/edit/${file._id}`}>
                          <Button variant='primary' className='btn-sm mx-1'>
                            Edit
                          </Button>
                        </LinkContainer>
                        <Button
                          variant='danger'
                          className='btn-sm mx-1'
                          onClick={() => deleteFileHandler(file)}
                        >
                          Delete
                        </Button>
                      </>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          {/* For Pagination */}
          <Paginate pages={pages} page={page}></Paginate>

          {/*<ProgressBar /> TBD: update lab storage size by counting file size */}
        </>
      )}
    </>
  )
}

const getThumbnailStyle = () => {
  return {
    width: '10rem',
  }
}

export default MediaFilesScreen
