export const NEXT_TX_STAGE_INFO_LIST_REQUEST = 'NEXT_TX_STAGE_INFO_LIST_REQUEST'
export const NEXT_TX_STAGE_INFO_LIST_SUCCESS = 'NEXT_TX_STAGE_INFO_LIST_SUCCESS'
export const NEXT_TX_STAGE_INFO_LIST_FAIL = 'NEXT_TX_STAGE_INFO_LIST_FAIL'
export const NEXT_TX_STAGE_INFO_LIST_RESET = 'NEXT_TX_STAGE_INFO_LIST_RESET'

export const NEXT_TX_STAGE_INFO_CREATE_REQUEST =
  'NEXT_TX_STAGE_INFO_CREATE_REQUEST'
export const NEXT_TX_STAGE_INFO_CREATE_SUCCESS =
  'NEXT_TX_STAGE_INFO_REATE_SUCCESS'
export const NEXT_TX_STAGE_INFO_CREATE_FAIL = 'NEXT_TX_STAGE_INFO_CREATE_FAIL'
export const NEXT_TX_STAGE_INFO_CREATE_RESET = 'NEXT_TX_STAGE_INFO_CREATE_RESET'

export const NEXT_TX_STAGE_INFO_DELETE_REQUEST =
  'NEXT_TX_STAGE_INFO_DELETE_REQUEST'
export const NEXT_TX_STAGE_INFO_DELETE_SUCCESS =
  'NEXT_TX_STAGE_INFO_DELETE_SUCCESS'
export const NEXT_TX_STAGE_INFO_DELETE_FAIL = 'NEXT_TX_STAGE_INFO_DELETE_FAIL'
export const NEXT_TX_STAGE_INFO_DELETE_RESET = 'NEXT_TX_STAGE_INFO_DELETE_RESET'

export const NEXT_TX_STAGE_INFO_EDIT_REQUEST = 'NEXT_TX_STAGE_INFO_EDIT_REQUEST'
export const NEXT_TX_STAGE_INFO_EDIT_SUCCESS = 'NEXT_TX_STAGE_INFO_EDIT_SUCCESS'
export const NEXT_TX_STAGE_INFO_EDIT_FAIL = 'NEXT_TX_STAGE_INFO_EDIT_FAIL'
export const NEXT_TX_STAGE_INFO_EDIT_RESET = 'NEXT_TX_STAGE_INFO_EDIT_RESET'
