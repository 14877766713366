import {
  PARTI_GROUP_ADD_RESET,
  PARTI_GROUP_DELETE_RESET,
  STAGE_ADD_RESET,
  STAGE_DELETE_RESET,
  STUDY_DELETE_RESET,
  STUDY_EDIT_CONSENT_RESET,
  STUDY_EDIT_EXCEPT_CONSENT_RESET,
} from '../constants/studyConstants'

const resetErrorState = (dispatch) => {
  dispatch({ type: STUDY_DELETE_RESET })
  dispatch({ type: STUDY_EDIT_CONSENT_RESET })
  dispatch({ type: STAGE_DELETE_RESET })
  dispatch({ type: STAGE_ADD_RESET })
  dispatch({ type: PARTI_GROUP_DELETE_RESET })
  dispatch({ type: PARTI_GROUP_ADD_RESET })
  dispatch({ type: STUDY_EDIT_EXCEPT_CONSENT_RESET })
}

export default resetErrorState
