/*  This screen for system owner to update user profile */

import React, { useEffect, useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  systemOwnerGetUserDetail,
  systemOwnerUpdateUserDetail,
} from '../actions/userActions'
import { SYSTEM_OWNER_UPDATE_USER_DETAIL_RESET } from '../constants/userConstants'

import Organizations from '../../enums/organizations'
import getSubdomain from '../../getSubdomain'
import findOrganizationBySubdomain from '../../findOrganizationBySubdomain'
import redirectDueToInvalidSubdomain from '../../redirectDueToInvalidSubdomain'

const SystemOwnerEditUserScreen = ({ history, match, location }) => {
  // Make sure system owner is only accessible through longitudinax site
  const subdomain = getSubdomain(window)
  const siteOrganization = findOrganizationBySubdomain(subdomain)
  if (siteOrganization !== Organizations.Longitudinax) {
    // Keep the site subdomain by providing undefined to the second parameter
    redirectDueToInvalidSubdomain(window, undefined)
  }

  const labId = location.search ? location.search.split('=')[1] : 1

  const userId = match.params.id

  const [email, setEmail] = useState('')

  const [password, setPassword] = useState('')

  const [isLabManager, setIsLabManager] = useState(false)

  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.systemOwnerUserDetails)
  const { user, loading, error } = userDetails

  const systemOwnerLogin = useSelector((state) => state.systemOwnerLogin)
  const { systemOwnerInfo, logout } = systemOwnerLogin

  const userUpdate = useSelector((state) => state.systemOwnerUserUpdate)

  const {
    success: successUpdate,
    loading: loadingUpdate,
    error: errorUpdate,
  } = userUpdate

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(
      systemOwnerUpdateUserDetail({
        _id: userId,
        email,
        password,
        isLabManager,
      })
    )
  }

  const goBackHandler = () => {
    dispatch({ type: SYSTEM_OWNER_UPDATE_USER_DETAIL_RESET })
    history.go(-1)
  }

  useEffect(() => {
    let now = new Date()
    if (!systemOwnerInfo || systemOwnerInfo.expires < now || logout) {
      history.push('/systemowner/login')
    } else {
      if (successUpdate) {
        dispatch({ type: SYSTEM_OWNER_UPDATE_USER_DETAIL_RESET })
        history.push(`/systemowner/lab/${labId}`)
      } else {
        if (user._id !== userId || !user.email) {
          dispatch(systemOwnerGetUserDetail(userId))
        } else {
          setEmail(user.email)
          setIsLabManager(user.isLabManager)
        }
      }
    }
  }, [
    successUpdate,
    user,
    systemOwnerInfo,
    dispatch,
    userId,
    history,
    labId,
    logout,
  ])

  return (
    <>
      <Button className='btn btn-light my-3' onClick={goBackHandler}>
        Go Back
      </Button>
      <FormContainer>
        <h1 className='mb-5'>Edit User</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='email'>
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='password'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='isLabManager'>
              <Form.Check
                type='checkbox'
                label='Is Lab Manager'
                checked={isLabManager}
                // for checkbox it is e.target.checked
                onChange={(e) => setIsLabManager(e.target.checked)}
              ></Form.Check>
            </Form.Group>

            <Button type='submit' variant='primary'>
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default SystemOwnerEditUserScreen
