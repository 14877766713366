import FormContainer from '../components/FormContainer'
import { Row, Col } from 'react-bootstrap'

const headingStyle = {
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '1rem',
}

const heading2Style = {
  fontSize: '1.5rem',
  fontWeight: 'bold',
  marginBottom: '0.75rem',
}

const PrivacyCondScreen = () => {
  return (
    <FormContainer>
      <Row>
        <Col>
          <h2 style={headingStyle}>個人資料政策聲明 (簡稱“私隱政策”)</h2>
          <p>
            Infinite 6 Limited（簡稱“Infinite
            6”）承諾遵守香港法例第486章“個人資料（私隱）條例”的規定。Ininite
            6（統稱“本公司”)將確保對用戶提交的所有個人信息和數據遵守最嚴格的安全和保密標準。你的個人資料（包括電郵地址、姓名及電話號碼）及可識別的個人回應只會按此政策中的説明使用及在沒有獲得你的准許，我們將不會向任何人透露你的資料。
          </p>

          <p>
            本公司強烈建議用戶仔細閱讀本私隱政策以便瞭解本公司對於用戶在渠道上提供的個人資料的相關政策和實際應用。本私隱政策適用於註冊和非註冊用戶，並且當本公司認為必要時可不定期更新、修訂、更改和/或修改相關條款內容。
          </p>

          <h3 style={heading2Style}>1.申請會籍必須提供個人資料</h3>
          <p>
            要成爲Longitudinax
            Pro會員，你必須提供個人資料予本公司。如果你不能提供我們所要求的資料，你則不可成爲會員及不可參加於本平臺上發佈的問卷調查。爲了讓我們的合作夥伴就研究事項直接聯絡你，我們希望你提供你的電話號碼。爲了保護網上調查的可靠性，所有的調查答案都會被監控，以便防止網上欺騙行為包括雙重會籍及多重答案。
          </p>

          <h3 style={heading2Style}>2.個人資料的使用及披露</h3>
          <p>
            除非獲得你的特別同意，否則我們只會使用你的個人資料（如你的姓名、電郵地址及電話號碼及其他特別識別到你的資料）作爲以下用途（並不作其他用途）：（1）就研究事項直接聯絡你；(2)幫助我們核實你遵守使用本網站或作爲一個Longitudinax
            Pro會員的條款與細則（例如：幫助我們核實每個Longitudinax
            Pro會員只擁有一個賬戶）；（3
            ）回答你發送給我們的問題;（4）邀請你參與問卷調查;
            (5)讓合作機構就其調查所收集的資料作其指明的用途;（6）按法院命令、政府機構、或法律要求透露個人資料。
            我們不會出售你的個人資料予第三方。但是，如果本公司或該部門為負責提供這些服務的單位被出售或被收購，你的個人資料將會轉讓至另一公司。
          </p>

          <h3 style={heading2Style}>3.敏感資料</h3>
          <p>
            我們會由於一些調查的主題所需而向你收集敏感資料。敏感資料包括你的種族或族裔或對其的意見，政治見解和黨籍，宗教或哲學信仰，工會或協會的會籍，犯罪記錄，或你的健康或性生活的細節。
            你選擇完成該相關問卷調查代表你同意本公司嚴格根據本私隱政策收集及使用你於該特別調查中回應的敏感資料。倘若您不想本公司或其合作機構收集及使用任何關於你的敏感資料，你有權選擇不完成該問卷調查。
          </p>

          <h3 style={heading2Style}>4.安全保障</h3>
          <p>
            本公司已實施了最先進及合乎行業標準的安全系統以確保只有經過身份驗證的用戶才可以輸入自己的資料至數據庫系統，及只有合作機構的研究人員和數據庫管理人員才能夠看閲。雖然我們盡力保障你資料的安全和我們的系統安全，但不能保證互聯網傳輸是百分之百安全的。因此，使用我們的服務代表你會承擔該風險。只要你是Longitudinax
            Pro的會員，本公司就會保留你的個人資料。本公司一般會於你結束Longitudinax
            Pro會籍後3個月內銷毀你的個人資料或將其去除識別。
          </p>

          <h3 style={heading2Style}>5.個人資料的存取</h3>
          <p>
            倘若你希望存取本公司為你保管的任何個人資料或要求其將你的任何個人資料銷毀、刪除或去除識別，
            請發送你的要求至admin@longitudinax.com。
            倘若你要求的資料難以找到及費時才可達到你的要求，我們可能會徵收費用。該等合理收費將會根據你的要求的性質和複雜程度而定。雖然本公司非常樂意為你提供協助，
            但本公司有權在某些情況下拒絕你的要求。
          </p>

          <h3 style={heading2Style}>6.電郵及短訊</h3>
          <p>
            本公司或其合作機構會發送電郵或短訊邀請你參加問卷調查或通知你有關問卷調查的事宜。這些電郵或短訊可能含有我們的宣傳資料，包括我們的網站，問卷調查的相關資料，亦可能通知你如何登入問卷調查及參加其他問卷調查的資料以及其他你可能需要知道的資料。你使用Longitudinax
            Pro代表你已同意本公司或其合作機構向你發送這些電郵。如果你於任何時候改變主意，你可以聯絡我們進行取消接收。
          </p>

          <h3 style={heading2Style}>7.私隱政策的修改</h3>
          <p>
            關於此私隱政策中的條文，我們保留隨時修改、增加或刪除的權利。你需要定時閱覽此頁面以確保你得知任何修改。但是，如果有任何修改涉及使用你的個人資料而有異於本隱私政策，我們會先以電郵取得你的同意。然而，你於該修改後繼續登入本網站則明確代表你同意所有修改。
          </p>

          <h3 style={heading2Style}>8.終止會籍</h3>
          <p>
            倘若你不想繼續成爲Longitudinax會員，你可隨時要求我們從活躍會員名單中將你的個人資料刪除。我們會盡快處理你的要求。但是，由我們收到你的要求至能夠從所有已安排的訊息及名單中刪除你的資料可能會有時間差距。故此，在你要求從我們的名單中刪除至最後成功處理前，你可能會收到數個電郵。
          </p>

          <h3 style={heading2Style}>9.條款與細則</h3>
          <p>
            Longitudinax
            Pro的使用受本條款及細則内的條文所約束。此私隱政策乃本條款與細則的其中一部分。你使用或登入本網站代表你同意受各項條文所約束。倘若你不同意本條款與細則，
            你將不可以登入或使用Longitudinax Pro。
          </p>

          <h3 style={heading2Style}>10.投訴及更新個人資料</h3>
          <p>
            倘若你想更新或修改任何本公司所保管有關你的個人資料，或對與你的個人資料相關的私隱政策有問題或疑慮，請透過電郵admin@longitudinax.com與我們聯絡。
          </p>
        </Col>
      </Row>
    </FormContainer>
  )
}

export default PrivacyCondScreen
