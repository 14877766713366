import axios from 'axios'
import path from 'path'

import { USER_LOGOUT } from '../../admin_module/constants/userConstants'
import {
  CREATE_MEDIA_FILES_FAIL,
  CREATE_MEDIA_FILES_REQUEST,
  CREATE_MEDIA_FILES_SUCCESS,
  DELETE_MEDIA_FILES_FAIL,
  DELETE_MEDIA_FILES_REQUEST,
  DELETE_MEDIA_FILES_SUCCESS,
  GET_MEDIA_FILES_FAIL,
  GET_MEDIA_FILES_REQUEST,
  GET_MEDIA_FILES_SUCCESS,
  GET_TARGET_MEDIA_FILE_FAIL,
  GET_TARGET_MEDIA_FILE_REQUEST,
  GET_TARGET_MEDIA_FILE_SUCCESS,
  MEDIA_FILES_FAIL,
  MEDIA_FILES_REQUEST,
  MEDIA_FILES_SUCCESS,
  UPDATE_MEDIA_FILES_FAIL,
  UPDATE_MEDIA_FILES_REQUEST,
  UPDATE_MEDIA_FILES_SUCCESS,
} from '../constants/mediaConstants'

import BACKEND_URL from '../../backendUrl'

// Following are the actions that will be fired off after calling by components
export const getMediaFiles =
  (pageNumber = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: MEDIA_FILES_REQUEST })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        BACKEND_URL + `/api/files/userFiles?pageNumber=${pageNumber}`,
        config
      )

      dispatch({
        type: MEDIA_FILES_SUCCESS,
        payload: data,
      })
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // This ensure userInfo is clear
        dispatch({
          type: USER_LOGOUT,
        })
        localStorage.removeItem('adminModuleUserInfo')
      } else {
        // In the custom express error handler, there could be custom error message,
        //  which is stored in error.response as { message: ..., stack: ... }
        // If there is no such custom error message, output the generic error message (i.e. error.message)
        dispatch({
          type: MEDIA_FILES_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      }
    }
  }

/******************** create media files (admin)  ****************/

export const createMediaFiles = (newMedia) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_MEDIA_FILES_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    // Get signed URL for AWS S3 upload
    const { data: s3URLData } = await axios.post(
      BACKEND_URL + '/api/files/genLabS3URL',
      {
        filePath: newMedia.filePath,
        fileType: newMedia.fileType,
        requestType: 'putObject',
      },
      config
    )

    console.log(s3URLData)

    // Upload to S3 using the s3URL link returned in previous call (s3URLData.data)
    await axios.put(s3URLData, newMedia.file, {
      headers: {
        'Content-Type': newMedia.fileType,
      },
    })

    // Update record to database
    const { data } = await axios.post(
      BACKEND_URL + '/api/files/createfiles',
      {
        lab: newMedia.lab,
        name: newMedia.name,
        type: newMedia.type,
        file: newMedia.filePath,
        size: newMedia.size,
      },
      config
    )

    dispatch({
      type: CREATE_MEDIA_FILES_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // This ensure userInfo is clear
      dispatch({
        type: USER_LOGOUT,
      })
      localStorage.removeItem('adminModuleUserInfo')
    } else {
      dispatch({
        type: CREATE_MEDIA_FILES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
}

/********************* get media files (admin) ******************/

export const getMediaFile = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_MEDIA_FILES_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      BACKEND_URL + `/api/files/mediafile/${id}`,
      config
    )

    dispatch({
      type: GET_MEDIA_FILES_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // This ensure userInfo is clear
      dispatch({
        type: USER_LOGOUT,
      })
      localStorage.removeItem('adminModuleUserInfo')
    } else {
      dispatch({
        type: GET_MEDIA_FILES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
}

/******************** update media files (admin) *****************/

export const updateMediaFile = (id, name) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_MEDIA_FILES_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.put(BACKEND_URL + `/api/files/mediafile/${id}`, name, config)

    dispatch({
      type: UPDATE_MEDIA_FILES_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: UPDATE_MEDIA_FILES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

/******************** delete media files (admin)  ****************/

export const deleteMediaFile = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_MEDIA_FILES_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(BACKEND_URL + `/api/files/mediafile/${id}`, config)

    dispatch({
      type: DELETE_MEDIA_FILES_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: DELETE_MEDIA_FILES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

/**************** get media file by keyword ****************/

export const getMediaFileByKeyword =
  (keyword = '', type = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: GET_TARGET_MEDIA_FILE_REQUEST })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        BACKEND_URL + `/api/files/targetfiles?keyword=${keyword}&type=${type}`,
        config
      )

      dispatch({ type: GET_TARGET_MEDIA_FILE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: GET_TARGET_MEDIA_FILE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
