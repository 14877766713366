import getSubdomain from '../../getSubdomain'
import findOrganizationBySubdomain from '../../findOrganizationBySubdomain'

// It is a function used to validate login credential
// Important note: the parameters (except window) must be watched in useEffect function for it to check again when useInfo/logout state is updated

const hasValidLoginCredential = (userInfo, logout, window) => {
  const now = new Date()
  const organization = findOrganizationBySubdomain(getSubdomain(window))
  if (
    !userInfo ||
    userInfo.expires < now ||
    logout ||
    userInfo.organization !== organization
  ) {
    return false
  } else {
    return true
  }
}

export default hasValidLoginCredential
