import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Row, Col, Form, Card } from 'react-bootstrap'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import TxPackage from './TxPagekage'
import getDefaultTxPackage from '../utils/getDefaultTxPackage'

import { getTxPageList } from '../actions/txMaterialsActions'

import resetErrorState from '../utils/resetErrorState'

import hasValidLoginCredential from '../../admin_module/utils/hasValidLoginCredential'
import { TX_PAGE_LIST_RESET } from '../constants/txMaterialsConstants'

// axType can be 'questionnaire', 'no_submission_limit' or 'diary'
const getTxPackagePageListAxQuestionnaireList = (
  axType,
  txPages,
  txPackagePages
) => {
  if (!txPackagePages) {
    return []
  }
  let result = []
  for (let i = 0; i < txPackagePages.length; ++i) {
    let foundPage = txPages.find(
      (x) => String(x._id) === String(txPackagePages[i].page)
    )
    if (foundPage) {
      for (let j = 0; j < foundPage.page_components.length; ++j) {
        if (
          foundPage.page_components[j].type === 'button' &&
          foundPage.page_components[j].button_link_type === 'qx'
        ) {
          if (
            axType === 'questionnaire' &&
            foundPage.page_components[j].button_link_qx_type === 'questionnaire'
          ) {
            if (foundPage.page_components[j].button_link_questionnaire) {
              result.push(
                foundPage.page_components[j].button_link_questionnaire
              )
            }
          }
          if (
            axType === 'no_submission_limit' &&
            foundPage.page_components[j].button_link_qx_type ===
              'no_submission_limit'
          ) {
            if (foundPage.page_components[j].button_link_no_submission_limit) {
              result.push(
                foundPage.page_components[j].button_link_no_submission_limit
              )
            }
          }
          if (
            axType === 'diary' &&
            foundPage.page_components[j].button_link_qx_type === 'diary'
          ) {
            if (foundPage.page_components[j].button_link_diary) {
              result.push(foundPage.page_components[j].button_link_diary)
            }
          }
        }
      }
    }
  }
  return [...new Set(result)]
}

// Each item in group.tx_package_list here shall consist of name and _id
// While when saving as tx_package_list in study model, it shall be transformed (at backend) to a list of txPackage ids only
export const GroupTxPackages = ({
  group,
  goToLogin,
  updateGroupAx,
  readOnly,
}) => {
  const dispatch = useDispatch()

  const [packageList, setPackageList] = useState([])
  const [txPackagesAx, setTxPackagesAx] = useState({}) // key: txPackage._id, value: {questionnaire_qid_list, no_submission_limit_qid_list, diary_qid_list}

  //It is needed to check if the logged in user is an admin
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const txPageList = useSelector((state) => state.txPageList)
  const {
    txPages,
    error: errorGetList,
    loading: loadingGetList,
    success: successGetList,
  } = txPageList

  useEffect(() => {
    if (!hasValidLoginCredential(userInfo, logout, window)) {
      dispatch({ type: TX_PAGE_LIST_RESET })
      goToLogin()
    } else {
      if (group.study && !loadingGetList && !successGetList && !errorGetList) {
        resetErrorState(dispatch)
        dispatch(getTxPageList(group.study))
      }
      setPackageList(group.tx_package_list)
      let tempTxPackagesAx = {}
      for (let i = 0; i < group.tx_package_list.length; ++i) {
        let txPackagePages = group.tx_package_list[i].pages
        tempTxPackagesAx[i] = {
          questionnaire_qid_list: getTxPackagePageListAxQuestionnaireList(
            'questionnaire',
            txPages,
            txPackagePages
          ),
          no_submission_limit_qid_list: getTxPackagePageListAxQuestionnaireList(
            'no_submission_limit',
            txPages,
            txPackagePages
          ),
          diary_qid_list: getTxPackagePageListAxQuestionnaireList(
            'diary',
            txPages,
            txPackagePages
          ),
        }
      }
      setTxPackagesAx(tempTxPackagesAx)
    }
  }, [
    group,
    dispatch,
    loadingGetList,
    successGetList,
    errorGetList,
    goToLogin,
    logout,
    userInfo,
    txPages,
  ])

  // Dependency: react-beautiful-dnd
  const handleDrop = (droppedItem) => {
    //Ignore drop outside droppable container
    if (!droppedItem.destination) return
    let updatedList = [...group.tx_package_list]
    // Remove dragged item
    let [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem)
    // Update State
    group.tx_package_list = updatedList
  }

  const addTxPackage = () => {
    group.tx_package_list = [...group.tx_package_list, getDefaultTxPackage()]
    setPackageList(group.tx_package_list)
  }

  const deleteTxPackage = (index) => {
    if (window.confirm('Confirm?')) {
      let temp = []
      for (let i = 0; i < group.tx_package_list.length; ++i) {
        if (i === index) {
          continue
        } else {
          temp.push(group.tx_package_list[i])
        }
      }
      group.tx_package_list = temp
      setPackageList(temp)

      // Update txPackagesAx
      let tempTxPackagesAx = { ...txPackagesAx }
      delete tempTxPackagesAx[index]
      let keys = Object.keys(tempTxPackagesAx)
      let out = {}
      for (let i = 0; i < keys.length; ++i) {
        if (i < index) {
          out[keys[i]] = tempTxPackagesAx[keys[i]]
        } else {
          out[keys[i] - 1] = tempTxPackagesAx[keys[i]]
        }
      }
      setTxPackagesAx(out)
      syncTxPackagesAxWithStudyAx(out)
    }
  }

  const syncTxPackagesAxWithStudyAx = (tempTxPackagesAx) => {
    let questionnaireList = []
    let noSubmissionLimitList = []
    let diaryList = []

    let values = Object.values(tempTxPackagesAx)

    for (let i = 0; i < values.length; ++i) {
      questionnaireList = questionnaireList.concat(
        values[i].questionnaire_qid_list
      )
      noSubmissionLimitList = noSubmissionLimitList.concat(
        values[i].no_submission_limit_qid_list
      )
      diaryList = diaryList.concat(values[i].diary_qid_list)
    }

    questionnaireList = [...new Set(questionnaireList)]
    noSubmissionLimitList = [...new Set(noSubmissionLimitList)]
    diaryList = [...new Set(diaryList)]

    updateGroupAx(questionnaireList, noSubmissionLimitList, diaryList)
  }

  const updateTxPackagesAx = (index) => {
    let tempTxPackagesAx = { ...txPackagesAx }
    let txPackagePages = group.tx_package_list[index].pages
    tempTxPackagesAx[index] = {
      questionnaire_qid_list: getTxPackagePageListAxQuestionnaireList(
        'questionnaire',
        txPages,
        txPackagePages
      ),
      no_submission_limit_qid_list: getTxPackagePageListAxQuestionnaireList(
        'no_submission_limit',
        txPages,
        txPackagePages
      ),
      diary_qid_list: getTxPackagePageListAxQuestionnaireList(
        'diary',
        txPages,
        txPackagePages
      ),
    }
    setTxPackagesAx(tempTxPackagesAx)
    syncTxPackagesAxWithStudyAx(tempTxPackagesAx)
  }

  return (
    <>
      <div style={{ marginBottom: '18px' }}>
        <Form.Label style={{ whiteSpace: 'normal' }}>
          Treatment Chapter(s) Added after Group Assignment
        </Form.Label>

        {group.tx_package_list.length > 0 && (
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId='group-tx-package-list-container'>
              {(provided) => (
                <Card
                  className='p-2 rounded'
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {packageList.map((txPackage, index) => (
                    <Draggable
                      key={'group_tx_package_draggable_' + group._id + index}
                      draggableId={
                        'group_tx_package_draggable_' + group._id + index
                      }
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                        >
                          <TxPackage
                            key={'group_tx_package_' + group._id + Date()}
                            group={group}
                            index={index}
                            handleDelete={() => {
                              deleteTxPackage(index)
                            }}
                            txPages={txPages}
                            updateGroupAx={() => {
                              updateTxPackagesAx(index)
                            }}
                            readOnly={readOnly}
                          ></TxPackage>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Card>
              )}
            </Droppable>
          </DragDropContext>
        )}

        {!readOnly && (
          <Row>
            <Col md={12}>
              <Button
                style={{ width: '100%', marginTop: '5px' }}
                variant='primary'
                onClick={addTxPackage}
              >
                Add Treatment Chapter
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </>
  )
}

export default GroupTxPackages
