import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Row, Col, Breadcrumb, Form, Card } from 'react-bootstrap'
import ModalContainer from './ModalContainer'

import Loader from './Loader'
import Message from './Message'
import {
  CUSTOM_PAGE_COMPONENT_CREATE_RESET,
  CUSTOM_PAGE_COMPONENT_DELETE_RESET,
  CUSTOM_PAGE_COMPONENT_DETAILS_RESET,
  CUSTOM_PAGE_COMPONENT_EDIT_RESET,
} from '../constants/txMaterialsConstants'
import {
  createCustomPageComponent,
  deleteCustomPageComponent,
  editCustomPageComponent,
  listCustomPageComponent,
} from '../actions/txMaterialsActions'

export const ModifyCustomPageComponent = ({
  studyId,
  customPageComponentList,
  onUpdate,
  onCreate,
  onDelete,
}) => {
  const modalRef = useRef()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const customPageComponentCreate = useSelector(
    (state) => state.customPageComponentCreate
  )
  const {
    loading: loadingCreate,
    success: successCreate,
    error: errorCreate,
  } = customPageComponentCreate

  const customPageComponentEdit = useSelector(
    (state) => state.customPageComponentEdit
  )
  const {
    loading: loadingEdit,
    success: successEdit,
    error: errorEdit,
  } = customPageComponentEdit

  const customPageComponentDelete = useSelector(
    (state) => state.customPageComponentDelete
  )
  const {
    loading: loadingDelete,
    success: successDelete,
    error: errorDelete,
  } = customPageComponentDelete

  const customPageComponentDetails = useSelector(
    (state) => state.customPageComponentDetails
  )
  const {
    loading: loadingCustomPageComponentDetails,
    success: successCustomPageComponentDetails,
    customPageComponent: initialCustomPageComponentDetails,
    error: errorCustomPageComponentDetails,
  } = customPageComponentDetails

  const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [loadedCustomPageComponentList, setLoadedCustomPageComponentList] =
    useState([])
  const [selectedCustomPageComponent, setSelectedCustomPageComponent] =
    useState(undefined)
  const [saveError, setSaveError] = useState('')

  useEffect(() => {
    setLoadedCustomPageComponentList(customPageComponentList)

    let now = new Date()
    if (userInfo && userInfo.expires > now && !logout) {
      if (successCustomPageComponentDetails) {
        setSelectedCustomPageComponent(initialCustomPageComponentDetails)
      }
      if (successEdit) {
        setSelectedCustomPageComponent(undefined)
        setShow(false)
        onUpdate()
        dispatch({ type: CUSTOM_PAGE_COMPONENT_EDIT_RESET })
      }
      if (successCreate) {
        setSelectedCustomPageComponent(undefined)
        setShow(false)
        onCreate()
        dispatch({ type: CUSTOM_PAGE_COMPONENT_CREATE_RESET })
      }
      if (successDelete) {
        setSelectedCustomPageComponent(undefined)
        setShow(false)
        onDelete()
        dispatch({ type: CUSTOM_PAGE_COMPONENT_DELETE_RESET })
      }
    }
  }, [
    customPageComponentList,
    userInfo,
    logout,
    successCustomPageComponentDetails,
    successEdit,
    successCreate,
    successDelete,
    initialCustomPageComponentDetails,
    onUpdate,
    onCreate,
    onDelete,
    dispatch,
  ])

  const handleOpen = () => {
    setShow(true)
  }

  const handleClose = () => {
    setSelectedCustomPageComponent(undefined)
    setShow(false)
  }

  //////// Validators //////////

  const checkIdentifierNonEmpty = () => {
    let error_msg = ''
    if (!selectedCustomPageComponent.custom_develop_identifier?.length) {
      error_msg = 'Identifier cannot be empty'
      return error_msg
    }
    return error_msg
  }

  const checkDescriptionNonEmpty = () => {
    let error_msg = ''
    if (!selectedCustomPageComponent.custom_develop_note?.length) {
      error_msg = 'Component description cannot be empty'
      return error_msg
    }
    return error_msg
  }

  //////////////////////////////

  const handleSelectCustomPageComponent = (e, id) => {
    e.preventDefault()
    dispatch({ type: CUSTOM_PAGE_COMPONENT_DETAILS_RESET })
    dispatch(listCustomPageComponent(studyId, id))
  }

  const handleDeleteCustomPageComponent = (e, id) => {
    e.preventDefault()
    dispatch(deleteCustomPageComponent(studyId, id))
  }

  // When clicking create new
  const handleAddNewCustomPageComponent = () => {
    const newCustomPageComponent = {
      _id: '',
      can_delete_by_adminme: true,
      custom_develop_identifier: '',
      custom_develop_note: '',
    }

    setSelectedCustomPageComponent(newCustomPageComponent)
  }

  const handleSave = () => {
    setSaveError('')
    let error = ''

    if (error.length === 0) error = checkIdentifierNonEmpty()
    if (error.length === 0) error = checkDescriptionNonEmpty()

    if (error.length === 0) {
      if (selectedCustomPageComponent._id) {
        //Edit
        dispatch(editCustomPageComponent(studyId, selectedCustomPageComponent))
      } else {
        //Create
        dispatch(
          createCustomPageComponent(studyId, selectedCustomPageComponent)
        )
      }
    } else {
      setSaveError(error)
      modalRef.current.scrollTop = 0
    }
  }

  return (
    <>
      {loadingCreate ||
      loadingEdit ||
      loadingCustomPageComponentDetails ||
      loadingDelete ? (
        <Loader />
      ) : (
        <Button
          variant='primary'
          onClick={handleOpen}
          className='my-1'
          style={{ float: 'left', marginLeft: '10px' }}
        >
          Create or Modify Description for Custom Page Components
        </Button>
      )}
      <ModalContainer
        title='Custom Page Components'
        show={show}
        showSave={selectedCustomPageComponent}
        handleSave={handleSave}
        handleClose={handleClose}
        modalRef={modalRef}
      >
        {saveError && <Message variant='danger'>{saveError}</Message>}
        {errorEdit && <Message variant='danger'>{errorEdit}</Message>}
        {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
        {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
        {errorCustomPageComponentDetails && (
          <Message variant='danger'>{errorCustomPageComponentDetails}</Message>
        )}

        <Row>
          <Col>
            <Breadcrumb>
              {loadedCustomPageComponentList &&
                loadedCustomPageComponentList.map((component) => (
                  <Breadcrumb.Item
                    key={component._id}
                    onClick={(e) => {
                      handleSelectCustomPageComponent(e, component._id)
                    }}
                    active={
                      selectedCustomPageComponent &&
                      selectedCustomPageComponent._id &&
                      selectedCustomPageComponent._id === component._id
                    }
                  >
                    {component.custom_develop_identifier}
                  </Breadcrumb.Item>
                ))}
              <Breadcrumb.Item
                onClick={handleAddNewCustomPageComponent}
                active={
                  selectedCustomPageComponent &&
                  selectedCustomPageComponent._id.length === 0
                }
              >
                Create New
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        {!selectedCustomPageComponent ? (
          <Row>
            <Col>
              <p>Please select or create a new custom page component above</p>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col>
                {selectedCustomPageComponent._id && (
                  <Button
                    className='mb-2'
                    variant='danger'
                    onClick={(e) =>
                      handleDeleteCustomPageComponent(
                        e,
                        selectedCustomPageComponent._id
                      )
                    }
                  >
                    Remove{' '}
                    {
                      loadedCustomPageComponentList.find(
                        (x) => x._id === selectedCustomPageComponent._id
                      ).custom_develop_identifier
                    }
                  </Button>
                )}
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group
                  controlId={
                    'custom_page_component_identifier_' +
                    selectedCustomPageComponent._id
                  }
                >
                  <Form.Label>Component Identifier</Form.Label>
                  <Form.Control
                    type='text'
                    value={
                      selectedCustomPageComponent.custom_develop_identifier
                    }
                    onChange={(e) => {
                      let tempSelectedCustomPageComponent = {
                        ...selectedCustomPageComponent,
                      }
                      tempSelectedCustomPageComponent.custom_develop_identifier =
                        e.target.value
                      setSelectedCustomPageComponent(
                        tempSelectedCustomPageComponent
                      )
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group
                  controlId={
                    'custom_page_component_note_' +
                    selectedCustomPageComponent._id
                  }
                >
                  <Form.Label>Component Description</Form.Label>
                  <Form.Control
                    as='textarea'
                    value={selectedCustomPageComponent.custom_develop_note}
                    onChange={(e) => {
                      let tempSelectedCustomPageComponent = {
                        ...selectedCustomPageComponent,
                      }
                      tempSelectedCustomPageComponent.custom_develop_note =
                        e.target.value
                      setSelectedCustomPageComponent(
                        tempSelectedCustomPageComponent
                      )
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </>
        )}
      </ModalContainer>
    </>
  )
}

export default ModifyCustomPageComponent
