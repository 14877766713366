import {
  NEXT_TX_STAGE_INFO_CREATE_FAIL,
  NEXT_TX_STAGE_INFO_CREATE_REQUEST,
  NEXT_TX_STAGE_INFO_CREATE_RESET,
  NEXT_TX_STAGE_INFO_CREATE_SUCCESS,
  NEXT_TX_STAGE_INFO_DELETE_FAIL,
  NEXT_TX_STAGE_INFO_DELETE_REQUEST,
  NEXT_TX_STAGE_INFO_DELETE_RESET,
  NEXT_TX_STAGE_INFO_DELETE_SUCCESS,
  NEXT_TX_STAGE_INFO_EDIT_FAIL,
  NEXT_TX_STAGE_INFO_EDIT_REQUEST,
  NEXT_TX_STAGE_INFO_EDIT_RESET,
  NEXT_TX_STAGE_INFO_EDIT_SUCCESS,
  NEXT_TX_STAGE_INFO_LIST_FAIL,
  NEXT_TX_STAGE_INFO_LIST_REQUEST,
  NEXT_TX_STAGE_INFO_LIST_RESET,
  NEXT_TX_STAGE_INFO_LIST_SUCCESS,
} from '../constants/txProgressConstants'

export const nextTxStageInfoListReducer = (
  state = { next_tx_stage_info_list: [] },
  action
) => {
  switch (action.type) {
    case NEXT_TX_STAGE_INFO_LIST_REQUEST:
      return { loading: true, next_tx_stage_info_list: [] }
    case NEXT_TX_STAGE_INFO_LIST_SUCCESS:
      return {
        loading: false,
        next_tx_stage_info_list: action.payload.nextTxStageInfoList,
        success: true,
      }
    case NEXT_TX_STAGE_INFO_LIST_FAIL:
      return { loading: false, error: action.payload }
    case NEXT_TX_STAGE_INFO_LIST_RESET:
      return { next_tx_stage_info_list: [] }
    default:
      return state
  }
}

export const nextTxStageInfoCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case NEXT_TX_STAGE_INFO_CREATE_REQUEST:
      return { loading: true }
    case NEXT_TX_STAGE_INFO_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case NEXT_TX_STAGE_INFO_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case NEXT_TX_STAGE_INFO_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const nextTxStageInfoDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case NEXT_TX_STAGE_INFO_DELETE_REQUEST:
      return { loading: true }
    case NEXT_TX_STAGE_INFO_DELETE_SUCCESS:
      return { loading: false, success: true }
    case NEXT_TX_STAGE_INFO_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case NEXT_TX_STAGE_INFO_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const nextTxStageInfoEditReducer = (state = {}, action) => {
  switch (action.type) {
    case NEXT_TX_STAGE_INFO_EDIT_REQUEST:
      return { loading: true }
    case NEXT_TX_STAGE_INFO_EDIT_SUCCESS:
      return { loading: false, success: true }
    case NEXT_TX_STAGE_INFO_EDIT_FAIL:
      return { loading: false, error: action.payload }
    case NEXT_TX_STAGE_INFO_EDIT_RESET:
      return {}
    default:
      return state
  }
}
