const redirectDueToInvalidSubdomain = (window, invalidSubdomain) => {
  let dest
  if (invalidSubdomain !== undefined) {
    dest = `${window.location.protocol}//${window.location.host.replace(
      invalidSubdomain + '.',
      ''
    )}/error`
  } else {
    // invalidSubdomain is undefined only for the case when attempting to browsing system owner page with an existing subdomain
    dest = `${window.location.protocol}//${window.location.host}/error`
  }

  window.location.replace(dest)
}

export default redirectDueToInvalidSubdomain
