import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import { QX_LIST_RESET } from '../qx_module/constants/qxConstants'
import { logout } from '../admin_module/actions/userActions'

import getSubdomain from '../getSubdomain'
import findSiteInfoBySubdomain from '../findSiteInfoBySubdomain'

const Header = () => {
  const theme = findSiteInfoBySubdomain(getSubdomain(window)).theme

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const goToQxList = () => {
    dispatch({ type: QX_LIST_RESET })
  }

  const logoutHandler = () => {
    dispatch(logout())
  }

  return (
    <header>
      <Navbar
        variant={theme}
        expand='lg'
        collapseOnSelect
        style={{
          backgroundColor: findSiteInfoBySubdomain(getSubdomain(window))
            .primaryColor,
        }}
      >
        <Container>
          <Navbar.Brand>
            <span style={{ color: theme === 'dark' ? 'white' : 'black' }}>
              {findSiteInfoBySubdomain(getSubdomain(window)).siteName}
            </span>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ml-auto'>
              <LinkContainer to='/studies/1'>
                <Nav.Link>
                  <span style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                    Studies
                  </span>
                </Nav.Link>
              </LinkContainer>
              {userInfo && userInfo.isLabManager && (
                <LinkContainer to='/lm/get_users'>
                  <Nav.Link>
                    <span
                      style={{ color: theme === 'dark' ? 'white' : 'black' }}
                    >
                      Lab Members
                    </span>
                  </Nav.Link>
                </LinkContainer>
              )}
              <LinkContainer to='/qx/qx_list/1' onClick={() => goToQxList()}>
                <Nav.Link>
                  <span style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                    Questionnaires
                  </span>
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to='/media/files'>
                <Nav.Link>
                  <span style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                    Uploaded Files
                  </span>
                </Nav.Link>
              </LinkContainer>
              <NavDropdown title='Account' id='basic-nav-dropdown'>
                {userInfo && (
                  <NavDropdown.ItemText>{userInfo.email}</NavDropdown.ItemText>
                )}
                <NavDropdown.Divider />
                <LinkContainer to='/login' onClick={() => logoutHandler()}>
                  <NavDropdown.Item>Logout</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
