/* This screen for system owner to list all labs */

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { systemOwnerListLabs } from '../actions/labActions'
import { Row, Col, Button } from 'react-bootstrap'
import Lab from '../components/Lab'
import Loader from '../components/Loader'
import Message from '../components/Message'

import { LinkContainer } from 'react-router-bootstrap'

import Organizations from '../../enums/organizations'
import getSubdomain from '../../getSubdomain'
import findOrganizationBySubdomain from '../../findOrganizationBySubdomain'
import redirectDueToInvalidSubdomain from '../../redirectDueToInvalidSubdomain'

const SystemOwnerHomeScreen = ({ history }) => {
  // Make sure system owner is only accessible through longitudinax site
  const subdomain = getSubdomain(window)
  const siteOrganization = findOrganizationBySubdomain(subdomain)
  if (siteOrganization !== Organizations.Longitudinax) {
    // Keep the site subdomain by providing undefined to the second parameter
    redirectDueToInvalidSubdomain(window, undefined)
  }

  const dispatch = useDispatch()

  const systemOwnerLogin = useSelector((state) => state.systemOwnerLogin)
  const { systemOwnerInfo, logout } = systemOwnerLogin

  const labList = useSelector((state) => state.systemOwnerLabList)

  const { loading, labs, error } = labList

  // when the tab is close, clear the system owner info in the local storage
  window.onbeforeunload = () => {
    localStorage.removeItem('adminModuleSystemOwnerInfo')
  }

  useEffect(() => {
    // if system owner does not log in or the token expires, then redirect to the login page
    let now = new Date()
    if (!systemOwnerInfo || systemOwnerInfo.expires < now || logout) {
      history.push('/systemowner/login')
    } else {
      dispatch(systemOwnerListLabs())
    }
  }, [history, systemOwnerInfo, dispatch, logout])

  return (
    <>
      <Row>
        <Col>
          <h1>Labs</h1>
        </Col>

        <Col className='text-right'>
          <LinkContainer to='/systemowner/createLab'>
            <Button className='my-3'>
              <i className='fas fa-plus'></i> Create Lab
            </Button>
          </LinkContainer>
        </Col>
      </Row>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Row>
          {labs &&
            labs.map((lab) => (
              <Col key={lab._id} sm={12} md={6} lg={4} xl={3}>
                <Lab lab={lab} />
              </Col>
            ))}
        </Row>
      )}
    </>
  )
}

export default SystemOwnerHomeScreen
