import React, { useEffect, useState } from 'react'
import FormContainer from '../components/FormContainer'
import { Form, FormControl, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { getMediaFile, updateMediaFile } from '../actions/mediaActions'
import { UPDATE_MEDIA_FILES_RESET } from '../constants/mediaConstants'

import hasValidLoginCredential from '../../admin_module/utils/hasValidLoginCredential'

const MediaFileEditScreen = ({ match, history }) => {
  const mediaFileId = match.params.id

  const [name, setName] = useState('')

  const mediaFile = useSelector((state) => state.getMediaFiles)

  const mediaUpdate = useSelector((state) => state.updateMediaFiles)
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const { loading: fileLoading, error: fileError, file } = mediaFile

  const { success: updateSuccess } = mediaUpdate

  useEffect(() => {
    if (!hasValidLoginCredential(userInfo, logout, window)) {
      history.push('/login')
    } else {
      if (updateSuccess) {
        dispatch({
          type: UPDATE_MEDIA_FILES_RESET,
        })

        dispatch(getMediaFile(mediaFileId))

        history.goBack()
      } else {
        if (!file || mediaFileId !== file._id) {
          dispatch(getMediaFile(mediaFileId))
        } else {
          setName(file.name)
        }
      }
    }
  }, [dispatch, file, mediaFileId, updateSuccess, history, userInfo, logout])

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(updateMediaFile(file._id, { name }))
  }

  return (
    <>
      <Link to='/media/files' className='btn btn-light my-3'>
        Go Back
      </Link>

      <FormContainer>
        <h2>Update Filename</h2>

        {fileLoading ? (
          <Loader />
        ) : fileError ? (
          <Message variant='danger'> {fileError}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            {file &&
              (file.type === 'video' ? (
                <video style={getStyle()} controls>
                  <source src={file.file} type='video/mp4' />
                </video>
              ) : (
                <img src={file.file} style={getStyle()} alt={file.name}></img>
              ))}

            <Form.Group controlId='name' className='mt-3'>
              {/* <Form.Label>Name</Form.Label> */}
              <FormControl
                type='name'
                placeholder='input media file new name'
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              ></FormControl>
            </Form.Group>

            <Button type='submit' variant='primary'>
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

const getStyle = () => {
  return {
    width: '32.5rem',
  }
}

export default MediaFileEditScreen
