import {
  NEXT_TX_STAGE_INFO_CREATE_RESET,
  NEXT_TX_STAGE_INFO_DELETE_RESET,
  NEXT_TX_STAGE_INFO_LIST_RESET,
} from '../constants/txProgressConstants'

const resetErrorState = (dispatch) => {
  dispatch({ type: NEXT_TX_STAGE_INFO_CREATE_RESET })
  dispatch({ type: NEXT_TX_STAGE_INFO_DELETE_RESET })
  dispatch({ type: NEXT_TX_STAGE_INFO_LIST_RESET })
}

export default resetErrorState
