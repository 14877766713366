export const TX_PAGE_LIST_REQUEST = 'TX_PAGE_LIST_REQUEST'
export const TX_PAGE_LIST_SUCCESS = 'TX_PAGE_LIST_SUCCESS'
export const TX_PAGE_LIST_FAIL = 'TX_PAGE_LIST_FAIL'
export const TX_PAGE_LIST_RESET = 'TX_PAGE_LIST_RESET'

export const TX_PAGE_CREATE_REQUEST = 'TX_PAGE_CREATE_REQUEST'
export const TX_PAGE_CREATE_SUCCESS = 'TX_PAGE_CREATE_SUCCESS'
export const TX_PAGE_CREATE_FAIL = 'TX_PAGE_CREATE_FAIL'
export const TX_PAGE_CREATE_RESET = 'TX_PAGE_CREATE_RESET'

export const TX_PAGE_DELETE_REQUEST = 'TX_PAGE_DELETE_REQUEST'
export const TX_PAGE_DELETE_SUCCESS = 'TX_PAGE_DELETE_SUCCESS'
export const TX_PAGE_DELETE_FAIL = 'TX_PAGE_DELETE_FAIL'
export const TX_PAGE_DELETE_RESET = 'TX_PAGE_DELETE_RESET'

export const TX_PAGE_DETAILS_REQUEST = 'TX_PAGE_DETAILS_REQUEST'
export const TX_PAGE_DETAILS_SUCCESS = 'TX_PAGE_DETAILS_SUCCESS'
export const TX_PAGE_DETAILS_FAIL = 'TX_PAGE_DETAILS_FAIL'
export const TX_PAGE_DETAILS_RESET = 'TX_PAGE_DETAILS_RESET'

export const TX_PAGE_EDIT_REQUEST = 'TX_PAGE_EDIT_REQUEST'
export const TX_PAGE_EDIT_SUCCESS = 'TX_PAGE_EDIT_SUCCESS'
export const TX_PAGE_EDIT_FAIL = 'TX_PAGE_EDIT_FAIL'
export const TX_PAGE_EDIT_RESET = 'TX_PAGE_EDIT_RESET'

export const CUSTOM_PAGE_COMPONENT_LIST_REQUEST =
  'CUSTOM_PAGE_COMPONENT_LIST_REQUEST'
export const CUSTOM_PAGE_COMPONENT_LIST_SUCCESS =
  'CUSTOM_PAGE_COMPONENT_LIST_SUCCESS'
export const CUSTOM_PAGE_COMPONENT_LIST_FAIL = 'CUSTOM_PAGE_COMPONENT_LIST_FAIL'
export const CUSTOM_PAGE_COMPONENT_LIST_RESET =
  'CUSTOM_PAGE_COMPONENT_LIST_RESET'

export const CUSTOM_PAGE_COMPONENT_CREATE_REQUEST =
  'CUSTOM_PAGE_COMPONENT_CREATE_REQUEST'
export const CUSTOM_PAGE_COMPONENT_CREATE_SUCCESS =
  'CUSTOM_PAGE_COMPONENT_CREATE_SUCCESS'
export const CUSTOM_PAGE_COMPONENT_CREATE_FAIL =
  'CUSTOM_PAGE_COMPONENT_CREATE_FAIL'
export const CUSTOM_PAGE_COMPONENT_CREATE_RESET =
  'CUSTOM_PAGE_COMPONENT_CREATE_RESET'

export const CUSTOM_PAGE_COMPONENT_DELETE_REQUEST =
  'CUSTOM_PAGE_COMPONENT_DELETE_REQUEST'
export const CUSTOM_PAGE_COMPONENT_DELETE_SUCCESS =
  'CUSTOM_PAGE_COMPONENT_DELETE_SUCCESS'
export const CUSTOM_PAGE_COMPONENT_DELETE_FAIL =
  'CUSTOM_PAGE_COMPONENT_DELETE_FAIL'
export const CUSTOM_PAGE_COMPONENT_DELETE_RESET =
  'CUSTOM_PAGE_COMPONENT_DELETE_RESET'

export const CUSTOM_PAGE_COMPONENT_DETAILS_REQUEST =
  'CUSTOM_PAGE_COMPONENT_DETAILS_REQUEST'
export const CUSTOM_PAGE_COMPONENT_DETAILS_SUCCESS =
  'CUSTOM_PAGE_COMPONENT_DETAILS_SUCCESS'
export const CUSTOM_PAGE_COMPONENT_DETAILS_FAIL =
  'CUSTOM_PAGE_COMPONENT_DETAILS_FAIL'
export const CUSTOM_PAGE_COMPONENT_DETAILS_RESET =
  'CUSTOM_PAGE_COMPONENT_DETAILS_RESET'

export const CUSTOM_PAGE_COMPONENT_EDIT_REQUEST =
  'CUSTOM_PAGE_COMPONENT_EDIT_REQUEST'
export const CUSTOM_PAGE_COMPONENT_EDIT_SUCCESS =
  'CUSTOM_PAGE_COMPONENT_EDIT_SUCCESS'
export const CUSTOM_PAGE_COMPONENT_EDIT_FAIL = 'CUSTOM_PAGE_COMPONENT_EDIT_FAIL'
export const CUSTOM_PAGE_COMPONENT_EDIT_RESET =
  'CUSTOM_PAGE_COMPONENT_EDIT_RESET'
