import React, { useState, useEffect } from 'react'
import { Form, Col } from 'react-bootstrap'

// variableValueType can be 'mc', 'text_numeric', 'duration_total_minutes', 'time_hhmmA' (see ModigyQxVariables.js)
export const SelectQuestionResponseAsVariableValue = ({
  qx,
  value,
  variableValueType,
  onSelectedResponseChanged,
}) => {
  const [selectedQuestionPos, setSelectedQuestionPos] = useState('')
  const [selectedResponsePos, setSelectedResponsePos] = useState('')

  useEffect(() => {
    if (value.qpos) setSelectedQuestionPos(value.qpos)
    if (value.rpos) setSelectedResponsePos(value.rpos)
  }, [value])

  const removeTag = (question) => {
    return question.replace(/<[^>]+>/g, '')
  }

  const checkQuestionAllowSelect = (question) => {
    for (let j = 0; j < question.other_info.length; ++j) {
      if (variableValueType === 'mc') {
        if (question.other_info[j].type === 'mc') return true
      }
      if (variableValueType === 'text_numeric') {
        if (
          question.other_info[j].type === 'text' &&
          question.other_info[j].number_only === true
        )
          return true
      }
      if (variableValueType === 'duration_total_minutes') {
        if (question.other_info[j].type === 'duration') return true
      }
      if (variableValueType === 'time_hhmmA') {
        if (question.other_info[j].type === 'time') return true
      }
    }
    return false
  }

  const getResponseItemsByQuestion = (question) => {
    if (question) {
      return question.other_info.map((x, index) => {
        if (
          variableValueType === 'text_numeric' &&
          question.other_info[index].type === 'text' &&
          question.other_info[index].number_only
        ) {
          return (
            <option
              key={'select_question_response_' + question._id + '_' + index}
              value={index + 1}
            >
              Item {index + 1}
            </option>
          )
        }
        if (
          variableValueType === 'mc' &&
          question.other_info[index].type === 'mc'
        ) {
          return (
            <option
              key={'select_question_response_' + question._id + '_' + index}
              value={index + 1}
            >
              Item {index + 1}
            </option>
          )
        }
        if (
          variableValueType === 'duration_total_minutes' &&
          question.other_info[index].type === 'duration'
        ) {
          return (
            <option
              key={'select_question_response_' + question._id + '_' + index}
              value={index + 1}
            >
              Item {index + 1}
            </option>
          )
        }
        if (
          variableValueType === 'time_hhmmA' &&
          question.other_info[index].type === 'time' &&
          question.other_info[index].picker_format === 'hh mm A'
        ) {
          return (
            <option
              key={'select_question_response_' + question._id + '_' + index}
              value={index + 1}
            >
              Item {index + 1}
            </option>
          )
        }
        return <></>
      })
    }
  }

  return (
    <>
      <Col md={6}>
        <Form.Group>
          <Form.Control
            as='select'
            value={selectedQuestionPos}
            onChange={(e) => {
              setSelectedQuestionPos(e.target.value)
              setSelectedResponsePos('')
              onSelectedResponseChanged(e.target.value, '')
            }}
          >
            <option value=''>Select Question...</option>

            {qx.chin_content.map((question, index) => {
              if (checkQuestionAllowSelect(question)) {
                return (
                  <option
                    key={'select_question_' + question._id + '_' + index}
                    value={question.position}
                  >
                    {question.question_num} {removeTag(question.html_question)}
                  </option>
                )
              } else {
                return null
              }
            })}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col md={6}>
        {selectedQuestionPos && (
          <Form.Group>
            <Form.Control
              as='select'
              value={selectedResponsePos}
              onChange={(e) => {
                setSelectedResponsePos(e.target.value)
                onSelectedResponseChanged(selectedQuestionPos, e.target.value)
              }}
            >
              <option value=''>Select Response...</option>
              {getResponseItemsByQuestion(
                qx.chin_content[selectedQuestionPos - 1]
              )}
            </Form.Control>
          </Form.Group>
        )}
      </Col>
    </>
  )
}

export default SelectQuestionResponseAsVariableValue
