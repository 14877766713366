import React from 'react'
import { useState } from 'react'
import { Button, Row, Col, Form, Card } from 'react-bootstrap'
import getChoicesByResponsePosition from '../utils/getChoicesByResponsePosition'
import getResponseItemsByQuestion from '../utils/getResponseItemsByQuestion'
import getOperatorByResponsePosition from '../utils/getOperatorByResponsePosition'

const DependencyAdd = ({
  andIndex,
  onDependencyAddError,
  onDependencyAdd,
  qxContent,
  mcList,
  targetingQuestionIndex,
}) => {
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState('')
  const [responsePos, setResponsePos] = useState('')
  const [operator, setOperator] = useState('==')
  const [response, setResponse] = useState('')

  const [editing, setEditing] = useState(false)

  const resetSelection = () => {
    setSelectedQuestionIndex('')
    setResponsePos('')
    setOperator('==')
    setResponse('')
  }

  const removeTag = (question) => {
    return question.replace(/<[^>]+>/g, '')
  }

  const checkQuestionAllowSelect = (question, qIndex) => {
    for (let j = 0; j < question.other_info.length; ++j) {
      if (
        ['text', 'mc'].includes(question.other_info[j].type) &&
        qIndex !== targetingQuestionIndex
      ) {
        return true
      }
    }
    return false
  }

  ///////////// Validators /////////////

  const checkSelectQuestionIndexNonEmpty = () => {
    let error_msg = ''
    if (!selectedQuestionIndex) {
      error_msg = 'Please select a question.'
    }
    return error_msg
  }

  const checkResponsePosNonEmpty = () => {
    let error_msg = ''
    if (!responsePos) {
      error_msg = 'Please select a response Item.'
    }
    return error_msg
  }

  const checkResponseNonEmpty = () => {
    let error_msg = ''
    if (response.trim().length === 0) {
      error_msg = 'Please enter an value in response to check with.'
    }
    return error_msg
  }

  const checkNumberOnlyResponseValid = () => {
    let error_msg = ''
    const responseItem =
      qxContent[selectedQuestionIndex].other_info[responsePos - 1]
    if (responseItem.type === 'text' && responseItem.number_only) {
      let isnum = /^\d+$/.test(response)
      if (!isnum) {
        error_msg =
          'Selected response item allow numbers only. Please enter a number in response to check with.'
      }
    }
    return error_msg
  }

  const handleAdd = () => {
    let error = ''

    if (error.length === 0) error = checkSelectQuestionIndexNonEmpty()
    if (error.length === 0) error = checkResponsePosNonEmpty()
    if (error.length === 0) error = checkResponseNonEmpty()
    if (error.length === 0) error = checkNumberOnlyResponseValid()
    if (error.length === 0) {
      onDependencyAdd(
        {
          dependent_qpos: qxContent[selectedQuestionIndex].position,
          dependent_response_pos: Number(responsePos),
          criterion_operator: operator,
          criterion_value: response,
        },
        andIndex
      )
      resetSelection()
      setEditing(false)
    } else {
      onDependencyAddError(error)
    }
  }

  const turnsToEditing = () => {
    setEditing(true)
  }

  return (
    <Card className='p-3 my-1 rounded' key={'loadedDependency'}>
      {editing ? (
        <Row>
          <Col>
            <Form.Group controlId={'loadedDependency_question'}>
              <Form.Label>Question</Form.Label>
              <Form.Control
                className='selectedQuestion'
                as='select'
                value={selectedQuestionIndex}
                onChange={(e) => {
                  setSelectedQuestionIndex(e.target.value)
                }}
              >
                <option value=''>Please select</option>

                {qxContent.map((question, index) => {
                  if (checkQuestionAllowSelect(question, index)) {
                    return (
                      <option
                        key={question._id + 'loadedQuestion' + index}
                        value={question.position - 1}
                      >
                        {question.question_num}{' '}
                        {removeTag(question.html_question)}
                      </option>
                    )
                  } else {
                    return <></>
                  }
                })}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            {selectedQuestionIndex && (
              <Form.Group controlId={'_selectedChoice'}>
                <Form.Label>Response Item</Form.Label>
                <Form.Control
                  className='selectedResponse'
                  as='select'
                  value={responsePos}
                  onChange={(e) => {
                    setResponsePos(e.target.value)
                  }}
                >
                  <option value=''>Select...</option>
                  {getResponseItemsByQuestion(qxContent[selectedQuestionIndex])}
                </Form.Control>
              </Form.Group>
            )}
          </Col>
          <Col>
            {selectedQuestionIndex && responsePos && (
              <Form.Group controlId={'_selectedResponse'}>
                <Form.Label>Operator</Form.Label>
                <Form.Control
                  className='selectedResponse'
                  as='select'
                  value={operator}
                  onChange={(e) => {
                    setOperator(e.target.value)
                  }}
                >
                  {getOperatorByResponsePosition(
                    qxContent[selectedQuestionIndex],
                    responsePos
                  )}
                </Form.Control>
              </Form.Group>
            )}
          </Col>
          <Col>
            {selectedQuestionIndex &&
              responsePos &&
              qxContent[selectedQuestionIndex].other_info[responsePos - 1]
                .type === 'text' && (
                <Form.Group controlId={'_input'}>
                  <Form.Label>Response</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter your value.'
                    value={response}
                    onChange={(e) => {
                      setResponse(e.target.value)
                    }}
                  ></Form.Control>
                </Form.Group>
              )}
            {selectedQuestionIndex &&
              responsePos &&
              qxContent[selectedQuestionIndex].other_info[responsePos - 1]
                .type === 'mc' && (
                <Form.Group controlId={'dependency_value'}>
                  <Form.Label>Response</Form.Label>
                  <Form.Control
                    className='selectedResponse'
                    as='select'
                    value={response}
                    onChange={(e) => {
                      setResponse(e.target.value)
                    }}
                  >
                    <option value=''>Please select</option>
                    {getChoicesByResponsePosition(
                      qxContent[selectedQuestionIndex],
                      responsePos,
                      mcList
                    )}
                  </Form.Control>
                </Form.Group>
              )}
          </Col>
          <Col>
            <Form.Label></Form.Label>
            <Form.Group controlId={'add_btn'}>
              <Button
                type='button'
                className='btn btn-primary float-left mt-2'
                onClick={handleAdd}
              >
                Add
              </Button>
            </Form.Group>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <Button
              type='button'
              className='btn btn-primary'
              style={{ width: '100%' }}
              onClick={turnsToEditing}
            >
              Add New Condition
            </Button>
          </Col>
        </Row>
      )}
    </Card>
  )
}

export default DependencyAdd
