import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Row, Col, Form, Table } from 'react-bootstrap'
import ViewFitbitSleepLogModalContainer from './ViewFitbitSleepLogModalContainer'
import Loader from './Loader'
import Message from './Message'

import { getFitbitSleepLog } from '../actions/participantActions'
import { GET_FITBIT_SLEEP_LOG_RESET } from '../constants/participantConstants'

import moment from 'moment-timezone'

export const ViewFitbitSleepLog = ({ participantId }) => {
  //It is needed to check if the logged in user is an admin
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const getFitbitSleepLogState = useSelector((state) => state.getFitbitSleepLog)
  const {
    loading: loadingGetSleepLog,
    error: errorGetSleepLog,
    sleepLog,
    success: successGetSleepLog,
  } = getFitbitSleepLogState

  const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const [saveError, setSaveError] = useState('')

  useEffect(() => {}, [])

  const handleOpen = () => {
    dispatch({ type: GET_FITBIT_SLEEP_LOG_RESET })
    setShow(true)
  }

  const checkStartDateEndStateDefined = () => {
    if (!startDate || !endDate) {
      return 'Please select Start Date and End Date'
    } else {
      return ''
    }
  }

  const checkStartDateBeforeEndDate = () => {
    if (moment(startDate, 'YYYY-MM-DD') > moment(endDate, 'YYYY-MM-DD')) {
      return 'Start Date cannot be later than End Date'
    } else {
      return ''
    }
  }

  const checkPeriodNotLongerThan90Days = () => {
    const duration =
      moment(endDate, 'YYYY-MM-DD').toDate() -
      moment(startDate, 'YYYY-MM-DD').toDate()
    const daysBetween = Math.floor(duration / (1000 * 60 * 60 * 24))
    console.log(daysBetween)
    if (daysBetween > 7) {
      return 'You are not allowed to retrieve sleep logs more than 7 days at a time'
    } else {
      return ''
    }
  }

  const handleGetSleepLog = (e) => {
    e.preventDefault()

    setSaveError('')
    let error = ''

    if (error.length === 0) error = checkStartDateEndStateDefined()
    if (error.length === 0) error = checkStartDateBeforeEndDate()
    if (error.length === 0) error = checkPeriodNotLongerThan90Days()

    //if (error.length === 0) error = checkSameAsCurrentGroup() <==== To allow reset ema tasks, comment this check

    if (error.length === 0) {
      let now = new Date()
      if (userInfo && userInfo.expires > now && !logout) {
        dispatch(getFitbitSleepLog(participantId, startDate, endDate))
      }
    } else {
      setSaveError(error)
    }
  }

  const handleClose = () => {
    setShow(false)
  }

  return (
    <>
      <Button variant='primary' className='btn-sm m-1' onClick={handleOpen}>
        View Fitbit Sleep Log
      </Button>

      <ViewFitbitSleepLogModalContainer
        title='View Fitbit Sleep Log'
        show={show}
        handleClose={handleClose}
      >
        {errorGetSleepLog && (
          <Message variant='danger'>{errorGetSleepLog}</Message>
        )}
        {saveError && <Message variant='danger'>{saveError}</Message>}

        {loadingGetSleepLog ? (
          <Loader />
        ) : (
          <>
            {!successGetSleepLog ? (
              <>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId='viewFitbitSleepLog_startDate'>
                      <Form.Control
                        type='date'
                        name='Start Date'
                        placeholder='Start Date'
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Control
                      type='date'
                      name='End Date'
                      placeholder='End Date'
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Col>
                </Row>

                <Button className='my-3' onClick={handleGetSleepLog}>
                  Get Sleep Log
                </Button>
              </>
            ) : (
              <>
                <Row>
                  <Col md={12}>
                    <div>
                      <pre>{sleepLog}</pre>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </ViewFitbitSleepLogModalContainer>
    </>
  )
}

export default ViewFitbitSleepLog
