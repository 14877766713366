import {
  GET_LAB_NAMES_FAIL,
  GET_LAB_NAMES_REQUEST,
  GET_LAB_NAMES_SUCCESS,
  GET_LAB_INFO_FAIL,
  GET_LAB_INFO_REQUEST,
  GET_LAB_INFO_SUCCESS,
  GET_LAB_INFO_RESET,
  SYSTEM_OWNER_CREATE_LABS_FAIL,
  SYSTEM_OWNER_CREATE_LABS_REQUEST,
  SYSTEM_OWNER_CREATE_LABS_RESET,
  SYSTEM_OWNER_CREATE_LABS_SUCCESS,
  SYSTEM_OWNER_GET_LABS_FAIL,
  SYSTEM_OWNER_GET_LABS_REQUEST,
  SYSTEM_OWNER_GET_LABS_SUCCESS,
  SYSTEM_OWNER_GET_LABS_RESET,
  SYSTEM_OWNER_GET_LABS_USERS_FAIL,
  SYSTEM_OWNER_GET_LABS_USERS_REQUEST,
  SYSTEM_OWNER_GET_LABS_USERS_SUCCESS,
  SYSTEM_OWNER_GET_LABS_USERS_RESET,
} from '../constants/labConstants'

/******************* reducers for users **************/

// get lab names for the register page
export const userLabNameListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_LAB_NAMES_REQUEST:
      return { loading: true }

    case GET_LAB_NAMES_SUCCESS:
      return { loading: false, labNames: action.payload }

    case GET_LAB_NAMES_FAIL:
      return { loading: false, error: action.payload }

    default:
      return state
  }
}

// Get info including lab storage
export const userGetLabInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_LAB_INFO_REQUEST:
      return { loading: true, success: false }
    case GET_LAB_INFO_SUCCESS:
      return { loading: false, success: true, labInfo: action.payload }
    case GET_LAB_INFO_FAIL:
      return { loading: false, success: false, error: action.payload }
    case GET_LAB_INFO_RESET:
      return {}
    default:
      return state
  }
}

/***********  reducers for system owner *************/

// list labs (system owner)
export const systemOwnerLabListReducer = (state = { labs: [] }, action) => {
  switch (action.type) {
    case SYSTEM_OWNER_GET_LABS_REQUEST:
      return { loading: true, labs: [] }

    case SYSTEM_OWNER_GET_LABS_SUCCESS:
      return { loading: false, labs: action.payload, success: true }

    case SYSTEM_OWNER_GET_LABS_FAIL:
      return { loading: false, error: action.payload }

    case SYSTEM_OWNER_GET_LABS_RESET:
      return { labs: [] }

    default:
      return state
  }
}

// list users according to labs (system owner)
export const systemOwnerLabUserListReducer = (state = {}, action) => {
  switch (action.type) {
    case SYSTEM_OWNER_GET_LABS_USERS_REQUEST:
      return { loading: true, labUsers: [] }

    case SYSTEM_OWNER_GET_LABS_USERS_SUCCESS:
      return { loading: false, labUsers: action.payload }

    case SYSTEM_OWNER_GET_LABS_USERS_FAIL:
      return { loading: false, error: action.payload }

    case SYSTEM_OWNER_GET_LABS_USERS_RESET:
      return {}

    default:
      return state
  }
}

// create labs (system owner)
export const systemOwnerCreateLabReducer = (state = {}, action) => {
  switch (action.type) {
    case SYSTEM_OWNER_CREATE_LABS_REQUEST:
      return { loading: true }

    case SYSTEM_OWNER_CREATE_LABS_SUCCESS:
      return { loading: false, success: true }

    case SYSTEM_OWNER_CREATE_LABS_FAIL:
      return { loading: false, error: action.payload }

    case SYSTEM_OWNER_CREATE_LABS_RESET:
      return {}

    default:
      return state
  }
}
