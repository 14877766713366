import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button, Table } from 'react-bootstrap'
import Message from '../components/Message'
import ToastMessage from '../components/ToastMessage'
import Loader from '../components/Loader'

import {
  getNextTxStageInfoList,
  deleteNextTxStageInfo,
} from '../actions/txProgressActions'
import {
  NEXT_TX_STAGE_INFO_DELETE_RESET,
  NEXT_TX_STAGE_INFO_LIST_RESET,
} from '../constants/txProgressConstants'

import resetErrorState from '../utils/resetErrorState'

import hasValidLoginCredential from '../../admin_module/utils/hasValidLoginCredential'
import { getAllAccess } from '../../access_module/actions/accessActions'
import { GET_ALL_ACCESS_RESET } from '../../access_module/constants/accessConstants'
import UpsertNextTxStageInfo from '../components/UpsertNextTxStageInfo'

const TxAssignmentsScreen = ({ history, match }) => {
  const studyId = match.params.studyId

  // For toast message
  const [toastMessages, setToastMessages] = useState([])

  const toastOnCloseHandler = async (uid) => {
    setToastMessages((toastMessages) =>
      toastMessages.filter((message) => message.uid !== uid)
    )
  }

  const dispatch = useDispatch()

  //It is needed to check if the logged in user is an admin
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const nextTxStageInfoList = useSelector((state) => state.nextTxStageInfoList)
  const {
    next_tx_stage_info_list,
    error: errorGetList,
    loading: loadingGetList,
    success: successGetList,
  } = nextTxStageInfoList

  const nextTxStageInfoDelete = useSelector(
    (state) => state.nextTxStageInfoDelete
  )
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = nextTxStageInfoDelete

  const allAccess = useSelector((state) => state.allAccess)
  const {
    loading: laodingGetAllAccess,
    error: errorGetAllAccess,
    success: successGetAllAccess,
    allAccessList,
  } = allAccess

  useEffect(() => {
    if (!hasValidLoginCredential(userInfo, logout, window)) {
      dispatch({ type: NEXT_TX_STAGE_INFO_LIST_RESET })
      history.push('/login')
    } else {
      if (!loadingGetList && !successGetList && !errorGetList) {
        resetErrorState(dispatch)
        // Put the reset for getting all access just before loading the main content again
        dispatch({ type: GET_ALL_ACCESS_RESET })
        dispatch(getNextTxStageInfoList(studyId))
      }

      if (!laodingGetAllAccess && !successGetAllAccess && !errorGetAllAccess) {
        dispatch(getAllAccess())
      }

      if (successDelete) {
        dispatch({ type: NEXT_TX_STAGE_INFO_DELETE_RESET })
        dispatch(getNextTxStageInfoList(studyId))
      }
    }
  }, [
    dispatch,
    history,
    userInfo,
    studyId,
    logout,
    loadingGetList,
    laodingGetAllAccess,
    errorGetList,
    errorGetAllAccess,
    successDelete,
    successGetList,
    successGetAllAccess,
  ])

  const deleteNextTxStageInfoHandler = async (nextTxStageInfo) => {
    if (window.confirm('Are you sure?')) {
      resetErrorState(dispatch)
      dispatch(deleteNextTxStageInfo(studyId, nextTxStageInfo._id))
    }
  }

  const headingStyle = {
    fontSize: '2rem',
    fontWeight: 'bold',
  }

  return (
    <>
      {/* Display toast messages */}
      <div
        style={{
          position: 'absolute',
          backgroundColor: 'transparent',
          pointerEvents: 'none',
          height: '80vh',
          width: '80vw',
          zIndex: 1,
        }}
      >
        {toastMessages.map((toastMessage) => (
          <ToastMessage
            key={toastMessage.uid}
            variant={toastMessage.variant}
            onClose={() => toastOnCloseHandler(toastMessage.uid)}
          >
            {toastMessage.message}
          </ToastMessage>
        ))}
      </div>

      <Row className='align-items-center'>
        <Col>
          <h1 style={headingStyle}>Treatment Assignment Plan</h1>
        </Col>
        <UpsertNextTxStageInfo
          studyId={studyId}
          nextTxStageInfoList={next_tx_stage_info_list}
          updatedNextTxStageInfo={undefined}
        ></UpsertNextTxStageInfo>
      </Row>

      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {loadingGetList || loadingDelete ? (
        <Loader />
      ) : errorGetList ? (
        <Message variant='danger'>{errorGetList}</Message>
      ) : (
        <>
          <p>
            Note: Next treatment group will be automatically assigned to
            participants after participating in the current treatment group for
            specified number of days ONLY when all the required tasks have been
            completed the pariticipants. The automatic group assignment will be
            performed by the system at 2:00am HKT everyday.
          </p>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>Current treatment group</th>
                <th>Next treatmnet group</th>
                <th>Perform assignment after number of days</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!logout &&
                next_tx_stage_info_list.map((nextStageInfo) => (
                  <tr key={nextStageInfo._id}>
                    <td className='align-middle'>
                      {nextStageInfo.current_group.name} (Stage:{' '}
                      {nextStageInfo.current_group.stage.chin_name})
                    </td>
                    <td className='align-middle'>
                      {nextStageInfo.next_group.name} (Stage:{' '}
                      {nextStageInfo.next_group.stage.chin_name})
                    </td>
                    <td className='align-middle'>
                      {nextStageInfo.assign_next_after_days}
                    </td>
                    <td className='align-middle'>
                      <UpsertNextTxStageInfo
                        studyId={studyId}
                        nextTxStageInfoList={next_tx_stage_info_list}
                        updatedNextTxStageInfo={nextStageInfo}
                      ></UpsertNextTxStageInfo>

                      <Button
                        variant='danger'
                        className='btn-sm mx-1'
                        onClick={() =>
                          deleteNextTxStageInfoHandler(nextStageInfo)
                        }
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  )
}

export default TxAssignmentsScreen
