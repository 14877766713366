////////////////////////////////////////////////////////
//The onResponseDelete handler on this page demostrates how
//   child component's function can be flexibly pass to its parent
////////////////////////////////////////////////////////
import React, { useState, useEffect, useRef } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import ToastMessage from '../components/ToastMessage'
import Loader from '../components/Loader'
import TxPageComponent from '../components/TxPageComponent'
import ObjectId from '../utils/ObjectId'

import { Row, Col, Button, Form } from 'react-bootstrap'
import {
  CUSTOM_PAGE_COMPONENT_DETAILS_RESET,
  TX_PAGE_DETAILS_RESET,
  TX_PAGE_EDIT_RESET,
} from '../constants/txMaterialsConstants'
import {
  editTxPage,
  getCustomPageComponentList,
  getTxPageList,
  listTxPageComponents,
} from '../actions/txMaterialsActions'

import { getQxList } from '../../qx_module/actions/qxActions'

import hasValidLoginCredential from '../../admin_module/utils/hasValidLoginCredential'
import ModifyCustomPageComponent from '../components/ModifyCustomPageComponent'
import { listStudyDetails } from '../../study_module/actions/studyActions'

const TxPageEditScreen = ({ match, history }) => {
  const studyId = match.params.studyId
  const txPageId = match.params.id

  //////// generate unique id from index /////////

  const generateUidFromIndex = (index) => {
    return ObjectId()
  }

  //////// Handle toast message ///////////

  const [toastMessages, setToastMessages] = useState([])

  const toastOnCloseHandler = async (uid) => {
    setToastMessages((toastMessages) =>
      toastMessages.filter((message) => message.uid !== uid)
    )
  }

  /////////////////////////////////////////

  const componentsRef = useRef()

  const [txPage, setTxPage] = useState({
    _id: '',
    identifier: '',
    page_components: [],
  })
  const [saveError, setSaveError] = useState('')

  const dispatch = useDispatch()

  //It is needed to check if the logged in user is an admin
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const txPageList = useSelector((state) => state.txPageList)
  const {
    txPages,
    error: errorGetTxPageList,
    loading: loadingGetTxPageList,
    success: successGetTxPageList,
  } = txPageList

  const qxList = useSelector((state) => state.qxList)
  const {
    qx_list,
    error: errorGetQxList,
    loading: loadingGetQxList,
    success: successGetQxList,
  } = qxList

  const studyDetails = useSelector((state) => state.studyDetails)
  const {
    loading: loadingStudy,
    error: errorStudy,
    study: loadedStudy,
    success: successStudy,
  } = studyDetails

  const txPageDetails = useSelector((state) => state.txPageDetails)
  const {
    loading: loadingInitialTxPage,
    error: errorInitialTxPage,
    txPage: updatedTxPage,
    success: successInitialTxPage,
  } = txPageDetails

  const customPageComponentList = useSelector(
    (state) => state.customPageComponentList
  )
  const {
    loading: loadingInitialCustomPageComponentList,
    error: errorInitialCustomPageComponentList,
    customPageComponents: updatedCustomPageComponentList,
    success: successInitialCustomPageComponentList,
  } = customPageComponentList

  const txPageEdit = useSelector((state) => state.txPageEdit)
  const {
    loading: loadingEditTxPage,
    error: errorEditTxPage,
    success: successEditTxPage,
  } = txPageEdit

  useEffect(() => {
    if (!hasValidLoginCredential(userInfo, logout, window)) {
      dispatch({ type: TX_PAGE_DETAILS_RESET })
      history.push('/login')
    } else {
      if (!loadingGetTxPageList && !errorGetTxPageList) {
        if (!successGetTxPageList) {
          dispatch(getTxPageList(studyId))
        }
      }

      if (!loadingGetQxList && !errorGetQxList) {
        if (!successGetQxList) {
          dispatch(getQxList())
        }
      }

      if (!loadingStudy && !errorStudy) {
        if (!successStudy) {
          dispatch(listStudyDetails(studyId))
        }
      }

      if (
        !loadingInitialCustomPageComponentList &&
        !errorInitialCustomPageComponentList
      ) {
        if (!successInitialCustomPageComponentList) {
          dispatch(getCustomPageComponentList(studyId))
        }
      }

      if (!loadingInitialTxPage && !errorInitialTxPage) {
        if (!successInitialTxPage) {
          dispatch(listTxPageComponents(studyId, txPageId))
        } else {
          setTxPage(updatedTxPage)
        }
      }

      if (successEditTxPage) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
        setToastMessages((toastMessages) => [
          ...toastMessages,
          {
            uid: new Date().valueOf(),
            variant: 'info',
            message: 'Changes have been saved',
          },
        ])
        dispatch({ type: TX_PAGE_EDIT_RESET })
      }
    }
  }, [
    dispatch,
    history,
    txPage,
    studyId,
    txPageId,
    userInfo,
    logout,
    updatedTxPage,
    loadingInitialTxPage,
    errorInitialTxPage,
    successInitialTxPage,
    successEditTxPage,
    loadingInitialCustomPageComponentList,
    errorInitialCustomPageComponentList,
    successInitialCustomPageComponentList,
    errorGetQxList,
    loadingGetQxList,
    successGetQxList,
    errorGetTxPageList,
    loadingGetTxPageList,
    successGetTxPageList,
    errorStudy,
    loadingStudy,
    successStudy,
  ])

  const headingStyle = {
    fontSize: '2rem',
    fontWeight: 'bold',
  }

  const sanitizePageComponentForSave = (pageComponent) => {
    let sanitizedComponent = {
      type: pageComponent.type,
      position: pageComponent.position,
      can_delete_by_admin: pageComponent.can_delete_by_admin,
    }
    if (pageComponent.type === 'custom_develop') {
      let { custom_page_component, is_task_to_check, custom_task_identifier } =
        pageComponent
      is_task_to_check = is_task_to_check ? true : false
      return {
        custom_page_component,
        is_task_to_check,
        custom_task_identifier,
        ...sanitizedComponent,
      }
    }
    if (pageComponent.type === 'text') {
      if (pageComponent.text_inside_block) {
        const { text_html_content, text_inside_block, text_block_color_hex } =
          pageComponent
        return {
          text_html_content,
          text_inside_block,
          text_block_color_hex,
          ...sanitizedComponent,
        }
      } else {
        const { text_html_content } = pageComponent
        return { text_html_content, ...sanitizedComponent }
      }
    }
    if (pageComponent.type === 'button') {
      if (pageComponent.button_link_type === 'page') {
        const { button_link_type, button_name, button_link_page } =
          pageComponent
        return {
          button_link_type,
          button_name,
          button_link_page,
          ...sanitizedComponent,
        }
      }
      if (pageComponent.button_link_type === 'qx') {
        const {
          button_link_type,
          button_name,
          button_link_qx_type,
          button_link_questionnaire,
          button_link_no_submission_limit,
          button_link_diary,
        } = pageComponent
        return {
          button_link_type,
          button_name,
          button_link_qx_type,
          button_link_questionnaire,
          button_link_no_submission_limit,
          button_link_diary,
          ...sanitizedComponent,
        }
      }
    }
    if (pageComponent.type === 'video') {
      const {
        video_preview_filename_to_be_sent,
        video_preview_local_filename,
        video_preview_s3_path,
        video_filename_to_be_sent,
        video_local_filename,
        video_s3_path,
      } = pageComponent
      return {
        video_preview_filename_to_be_sent,
        video_preview_local_filename,
        video_preview_s3_path,
        video_filename_to_be_sent,
        video_local_filename,
        video_s3_path,
        ...sanitizedComponent,
      }
    }
    if (pageComponent.type === 'image') {
      const {
        img_filename_to_be_sent,
        img_local_filename,
        img_s3_path,
        img_left_and_right_padding,
      } = pageComponent
      return {
        img_filename_to_be_sent,
        img_local_filename,
        img_s3_path,
        img_left_and_right_padding,
        ...sanitizedComponent,
      }
    }
    if (pageComponent.type === 'audio') {
      const { audio_filename_to_be_sent, audio_local_filename, audio_s3_path } =
        pageComponent
      return {
        audio_filename_to_be_sent,
        audio_local_filename,
        audio_s3_path,
        ...sanitizedComponent,
      }
    }
    return pageComponent
  }

  const saveHandler = () => {
    setSaveError('')
    let error = ''
    if (error.length === 0) error = checkCustomDevelopNonEmpty()
    if (error.length === 0) error = checkCustomTaskIdentifier()
    if (error.length === 0) error = checkTextNonEmpty()
    if (error.length === 0) error = checkTextBlockColorNonEmpty()
    if (error.length === 0) error = checkTextBlockColorStartsWithHash()
    if (error.length === 0) error = checkButtonTypeNameNonEmpty()
    if (error.length === 0) error = checkButtonLinkNonEmpty()
    if (error.length === 0) error = checkVideoFilenameNonEmpty()
    if (error.length === 0) error = checkImageFilenameNonEmpty()
    if (error.length === 0) error = checkAudioFilenameNonEmpty()

    if (error.length === 0) {
      // Sanitize page component
      for (let i = 0; i < updatedTxPage.page_components.length; ++i) {
        updatedTxPage.page_components[i] = sanitizePageComponentForSave(
          updatedTxPage.page_components[i]
        )
      }
      // Save page
      dispatch(editTxPage(studyId, txPageId, updatedTxPage))
    } else {
      setSaveError(error)
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  //////////  Validators ///////////

  const checkCustomDevelopNonEmpty = () => {
    let error_msg = ''
    for (let i = 0; i < updatedTxPage.page_components.length; ++i) {
      if (
        updatedTxPage.page_components[i].type === 'custom_develop' &&
        !updatedTxPage.page_components[i].custom_page_component?.length
      ) {
        error_msg = 'Please select custom developed page component'
        return error_msg
      }
    }
    return error_msg
  }

  const checkCustomTaskIdentifier = () => {
    let error_msg = ''
    for (let i = 0; i < updatedTxPage.page_components.length; ++i) {
      if (
        updatedTxPage.page_components[i].type === 'custom_develop' &&
        updatedTxPage.page_components[i].is_task_to_check
      ) {
        //console.log(updatedTxPage.page_components[i])
        if (!updatedTxPage.page_components[i].custom_task_identifier?.length) {
          error_msg =
            'Please specify task identifier for each custom developed page component indicated as a required task'
          return error_msg
        }

        let task_identifier =
          updatedTxPage.page_components[i].custom_task_identifier
        if (
          updatedTxPage.page_components.find(
            (x, index) =>
              index !== i && x.custom_task_identifier === task_identifier
          )
        ) {
          error_msg =
            'Please make sure each specified task identifier in custom developed page component is unique'
          return error_msg
        }
      }
    }
    return error_msg
  }

  const checkTextNonEmpty = () => {
    let error_msg = ''
    for (let i = 0; i < updatedTxPage.page_components.length; ++i) {
      if (
        updatedTxPage.page_components[i].type === 'text' &&
        !updatedTxPage.page_components[i].text_html_content?.trim()
      ) {
        error_msg = 'All the settings of each text component can not be empty'
        return error_msg
      }
    }
    return error_msg
  }

  const checkTextBlockColorNonEmpty = () => {
    let error_msg = ''
    for (let i = 0; i < updatedTxPage.page_components.length; ++i) {
      if (
        updatedTxPage.page_components[i].type === 'text' &&
        updatedTxPage.page_components[i].text_inside_block &&
        (!updatedTxPage.page_components[i].text_block_color_hex?.trim() ||
          updatedTxPage.page_components[i].text_block_color_hex === '#')
      ) {
        error_msg =
          'Block color of each text component wrapped by block can not be empty'
        return error_msg
      }
    }
    return error_msg
  }

  const checkTextBlockColorStartsWithHash = () => {
    let error_msg = ''
    for (let i = 0; i < updatedTxPage.page_components.length; ++i) {
      if (
        updatedTxPage.page_components[i].type === 'text' &&
        updatedTxPage.page_components[i].text_inside_block &&
        updatedTxPage.page_components[i].text_block_color_hex.trim() &&
        updatedTxPage.page_components[i].text_block_color_hex[0] !== '#'
      ) {
        error_msg = `Block color of each text component wrapped by block must start with letter '#'`
        return error_msg
      }
    }
    return error_msg
  }

  const checkButtonTypeNameNonEmpty = () => {
    let error_msg = ''
    for (let i = 0; i < updatedTxPage.page_components.length; ++i) {
      if (
        updatedTxPage.page_components[i].type === 'button' &&
        (!updatedTxPage.page_components[i].button_name?.trim() ||
          !updatedTxPage.page_components[i].button_link_type)
      ) {
        error_msg =
          'Please specify button text and button type of each button component'
        return error_msg
      }
    }
    return error_msg
  }

  const checkButtonLinkNonEmpty = () => {
    let error_msg = ''
    for (let i = 0; i < updatedTxPage.page_components.length; ++i) {
      if (
        updatedTxPage.page_components[i].type === 'button' &&
        updatedTxPage.page_components[i].button_link_type === 'page'
      ) {
        if (!updatedTxPage.page_components[i].button_link_page) {
          error_msg =
            'Please specify page for each button component linking to a page'
          return error_msg
        }
      }
      if (
        updatedTxPage.page_components[i].type === 'button' &&
        updatedTxPage.page_components[i].button_link_type === 'qx'
      ) {
        if (!updatedTxPage.page_components[i].button_link_qx_type) {
          error_msg =
            'Please specify assessnebt type for each button component linking to an assessment'
          return error_msg
        } else {
          if (
            (updatedTxPage.page_components[i].button_link_qx_type ===
              'questionnaire' &&
              !updatedTxPage.page_components[i].button_link_questionnaire) ||
            (updatedTxPage.page_components[i].button_link_qx_type ===
              'no_submission_limit' &&
              !updatedTxPage.page_components[i]
                .button_link_no_submission_limit) ||
            (updatedTxPage.page_components[i].button_link_qx_type === 'diary' &&
              !updatedTxPage.page_components[i].button_link_diary)
          ) {
            error_msg =
              'Please specify questionnaire for each button component linking to an assessment'
            return error_msg
          }
        }

        if (!updatedTxPage.page_components[i].button_link_qx) {
        }
      }
    }
    return error_msg
  }

  const checkVideoFilenameNonEmpty = () => {
    let error_msg = ''
    for (let i = 0; i < updatedTxPage.page_components.length; ++i) {
      if (
        updatedTxPage.page_components[i].type === 'video' &&
        !updatedTxPage.page_components[i].video_filename_to_be_sent?.trim()
      ) {
        error_msg = 'Please specify filename for each video component'
        return error_msg
      }
    }
    return error_msg
  }

  const checkImageFilenameNonEmpty = () => {
    let error_msg = ''
    for (let i = 0; i < updatedTxPage.page_components.length; ++i) {
      if (
        updatedTxPage.page_components[i].type === 'image' &&
        !updatedTxPage.page_components[i].img_filename_to_be_sent?.trim()
      ) {
        error_msg = 'Please specify filename for each image component'
        return error_msg
      }
    }
    return error_msg
  }

  const checkAudioFilenameNonEmpty = () => {
    let error_msg = ''
    for (let i = 0; i < updatedTxPage.page_components.length; ++i) {
      if (
        updatedTxPage.page_components[i].type === 'audio' &&
        !updatedTxPage.page_components[i].audio_filename_to_be_sent?.trim()
      ) {
        error_msg = 'Please specify filename for each audio component'
        return error_msg
      }
    }
    return error_msg
  }

  //const checkVideoSettingsNonEmpty = () => {} <-- Check for system owner edit only

  //const checkImageSettingsNonEmpty = () => {} <-- Check for system owner edit only

  ///////// Handle components ////////

  const deletePageComponentHandler = (index) => {
    let tempTxPage = { ...updatedTxPage }
    let targetEl = undefined

    tempTxPage.page_components.splice(index, 1)
    // To make sure the position is in correct order
    for (let i = 0; i < tempTxPage.page_components.length; ++i) {
      tempTxPage.page_components[i].position = i + 1
    }

    setTxPage(tempTxPage)

    // For scrolling to the element after the deleted one
    if (index <= tempTxPage.page_components.length - 1) {
      targetEl = componentsRef.current.children[index]
      window.scroll({
        top: targetEl.offsetTop,
        behavior: 'smooth',
      })
    }
  }

  const addPageComponentHandler = async (index, next) => {
    let newCompTemplate = {
      _id: generateUidFromIndex(index),
      type: 'text',
      can_delete_by_admin: true,
      text_html_content: '<p>(Please Edit)</p>',
    }

    let tempTxPage = { ...updatedTxPage }
    let insertedIndex = undefined
    let targetEl = undefined

    if (next) {
      insertedIndex = index + 1
    } else {
      //add to prev
      insertedIndex = index
    }

    tempTxPage.page_components.splice(insertedIndex, 0, newCompTemplate)
    // To make sure the position is in correct order
    for (let i = 0; i < tempTxPage.page_components.length; ++i) {
      tempTxPage.page_components[i].position = i + 1
    }
    setTxPage(tempTxPage)

    // For scrolling to the added element (except the last one)
    if (insertedIndex !== tempTxPage.page_components.length - 1) {
      targetEl = componentsRef.current.children[insertedIndex]
      window.scroll({
        top: targetEl.offsetTop,
        behavior: 'smooth',
      })
    }
  }

  const customPageComponentUpdatedHandler = () => {
    dispatch({ type: CUSTOM_PAGE_COMPONENT_DETAILS_RESET })
    dispatch(getCustomPageComponentList(studyId))
    setToastMessages((toastMessages) => [
      ...toastMessages,
      {
        uid: new Date().valueOf(),
        variant: 'info',
        message: 'Description for custom page component has been updated',
      },
    ])
  }

  const customPageComponentCreatedHandler = () => {
    dispatch({ type: CUSTOM_PAGE_COMPONENT_DETAILS_RESET })
    dispatch(getCustomPageComponentList(studyId))
    setToastMessages((toastMessages) => [
      ...toastMessages,
      {
        uid: new Date().valueOf(),
        variant: 'info',
        message: `A new custom page component has been created`,
      },
    ])
  }

  const customPageComponentDeletedHandler = () => {
    dispatch({ type: CUSTOM_PAGE_COMPONENT_DETAILS_RESET })
    dispatch(getCustomPageComponentList(studyId))
    setToastMessages((toastMessages) => [
      ...toastMessages,
      {
        uid: new Date().valueOf(),
        variant: 'info',
        message: `A description for custom page component has been removed`,
      },
    ])
  }

  return (
    <>
      {/* Display toast messages */}
      <div
        style={{
          position: 'absolute',
          backgroundColor: 'transparent',
          pointerEvents: 'none',
          x: '0',
          y: '0',
          height: '80vh',
          width: '80vw',
          zIndex: 1,
        }}
      >
        {toastMessages.map((toastMessage) => (
          <ToastMessage
            key={toastMessage.uid}
            variant={toastMessage.variant}
            onClose={() => toastOnCloseHandler(toastMessage.uid)}
          >
            {toastMessage.message}
          </ToastMessage>
        ))}
      </div>

      {/* Sticky bar for saving changes */}

      <Row
        style={{
          position: 'fixed',
          backgroundColor: 'white',
          bottom: 0,
          height: '50px',
          width: '95%',
          zIndex: 1,
        }}
      >
        <Col>
          {loadingEditTxPage ? (
            <Loader />
          ) : (
            <Button
              onClick={saveHandler}
              className='my-1 btn-success'
              style={{ float: 'left', marginLeft: '10px' }}
            >
              Save Changes
            </Button>
          )}

          {successInitialCustomPageComponentList && !loadedStudy.lock && (
            <ModifyCustomPageComponent
              customPageComponentList={updatedCustomPageComponentList}
              studyId={studyId}
              onUpdate={customPageComponentUpdatedHandler}
              onCreate={customPageComponentCreatedHandler}
              onDelete={customPageComponentDeletedHandler}
            ></ModifyCustomPageComponent>
          )}
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col md={12}>
          <h1 style={headingStyle}>Edit Treatment Page</h1>
        </Col>
        <Col>
          {errorInitialTxPage && (
            <Message variant='danger'>{errorInitialTxPage}</Message>
          )}
          {errorEditTxPage && (
            <Message variant='danger'>{errorEditTxPage}</Message>
          )}
          {errorGetQxList && (
            <Message variant='danger'>{errorGetQxList}</Message>
          )}
          {errorGetTxPageList && (
            <Message variant='danger'>{errorGetTxPageList}</Message>
          )}
          {saveError && <Message variant='danger'>{saveError}</Message>}
        </Col>
      </Row>
      <Row className='mx-2'>
        <Col md={6}>
          <Form.Group controlId={'txPage_identifier'}>
            <Form.Label style={{ whiteSpace: 'normal' }}>
              Page Identifier
            </Form.Label>
            <Form.Control
              disabled={loadedStudy.lock}
              type='text'
              value={updatedTxPage.identifier}
              onChange={(e) => {
                updatedTxPage.identifier = e.target.value
                let temp = { ...updatedTxPage }
                temp.identifier = e.target.value
                setTxPage(temp)
              }}
            ></Form.Control>
          </Form.Group>
        </Col>
      </Row>

      {loadingGetQxList ||
      loadingGetTxPageList ||
      loadingInitialCustomPageComponentList ? (
        <Loader />
      ) : (
        <Row className='mt-2 mx-2' style={{ marginBottom: '50px' }}>
          <Col ref={componentsRef}>
            {txPage.page_components.map((component, index) => (
              <TxPageComponent
                key={'txPageComponent_' + index}
                qxList={qx_list}
                txPageList={txPages}
                customPageComponentList={updatedCustomPageComponentList}
                txPage={txPage}
                index={index}
                onComponentDelete={() => deletePageComponentHandler(index)}
                deleteAvailable={txPage.page_components.length > 1}
                onAddPrev={() => addPageComponentHandler(index, false)}
                onAddNext={() => addPageComponentHandler(index, true)}
                studyLock={loadedStudy.lock}
              ></TxPageComponent>
            ))}
          </Col>
        </Row>
      )}
    </>
  )
}

export default TxPageEditScreen
