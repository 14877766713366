import React from 'react'
import { Toast } from 'react-bootstrap'

export const ToastMessage = ({ variant, children, onClose }) => {
  return (
    <>
      {variant === 'info' ? (
        <Toast
          className='alert-info'
          style={{ color: 'white' }}
          show={true}
          delay={3000}
          onClose={onClose}
          autohide
        >
          <Toast.Body>{children}</Toast.Body>
        </Toast>
      ) : (
        <Toast
          className='alert-danger'
          style={{ color: 'white' }}
          show={true}
          delay={3000}
          onClose={onClose}
          autohide
        >
          <Toast.Body>{children}</Toast.Body>
        </Toast>
      )}
    </>
  )
}

export default ToastMessage
